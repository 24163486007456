import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { UseCheckValidSubscription, UseGetMySellerListing, useCancelRenewalSubscription, useEnableRenewalSubscription, useUpgradeSubscription } from "../../../api/seller";
import ManagePlan from "../../../components/payment/manage-plan";
import ConfirmUpdateRenewalSubscription from "../../../components/payment/manage-plan/confirm-auto-renewal";
import PaymentPopup from "../../../components/payment/payment-popup";
import { queryClient } from "../../../query/query";
import { invalidateQueryProduct, invalidateQueryProductWithTax, refetchPaymentMethod, refetchReceipt } from "../../../query/removeCache";
import { capitalizeFirstLetter } from "../../../utils/outils";
import { LoaderCommon } from "../../../widget/loader";
import CurrentPlan from "../current-plan";

export default function SellerPlanDetails() {
    const navigate = useNavigate();

    const [isPremiumUnpaid, setIsPremiumUnpaid] = useState<boolean>(false);
    const [enableCheckSubscription, setEnableCheckSubscription] = useState<boolean>(true);
    const { data: checkSubscription, refetch: refetchCheck, isLoading } = UseCheckValidSubscription(enableCheckSubscription);

    const [enabledMySellerListing, setEnabledMySellerListing] = useState<boolean>(true);
    const { data: mySellerListing, isFetched: isMySellerListingFetched, isLoading: mySellerListingsLoading, } = UseGetMySellerListing(enabledMySellerListing);

    useEffect(() => {
        if (isMySellerListingFetched) setEnabledMySellerListing(false);
    }, [isMySellerListingFetched])

    useEffect(() => {
        if(checkSubscription && !checkSubscription.data.subscription && mySellerListing?.seller){
            setIsPremiumUnpaid(true);
        } else setIsPremiumUnpaid(false);
    }, [mySellerListing, checkSubscription])


    const [plan, setPlan] = useState<string>('No plan');
    const [isFree, setIsFree] = useState<boolean>(true);
    const [showPaymentModal, setShowPaymentModal] = useState<boolean>(false);

    const goToEnrollAsSeller = () => {
        navigate("/seller/listing/creation");
    };

    const handleGoToMyListing = () => {
        navigate("/seller/listing/details/" + mySellerListing?.seller?.id);
    };

    const [showMyPlanModal, setShowMyPlanModal] = useState<boolean>(false);

    const closeMyPlanModal = () => {
        setShowMyPlanModal(false);
    };

    const [enableUpgrade, setEnableUpgrade] = useState<boolean>(false);

    const subscriptions = [
        {
            type: "free",
            title: "FREE",
            content: (
                <div>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                </div>
            )
        },
        {
            type: "premium",
            title: "PREMIUM",
            content: (
                <div>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                </div>
            )
        }
    ]

    const [showManagePlan, setShowManagePlan] = useState<boolean>(false);

    const [showConfirmAutoRenewal, setShowConfirmAutoRenewal] = useState<boolean>(false);

    const [showLoaderConfirm, setShowLoaderConfirm] = useState<boolean>(false);

    const cancelSubscriptionMut = useMutation({
        mutationFn: useCancelRenewalSubscription,
        onSuccess: (data) => {
            if (data.status === 200) {
                toast('Automatic renewal turned OFF successfully');
                setShowConfirmAutoRenewal(false);
                setShowLoaderConfirm(false);
            } else {
                toast('An error occurred');
            }

            refetchCheck();
        }
    })

    const enableSubscriptionMut = useMutation({
        mutationFn: useEnableRenewalSubscription,
        onSuccess: (data) => {
            if (data.status === 200) {
                toast('Automatic renewal turned ON successfully');
                setShowConfirmAutoRenewal(false);
                setShowLoaderConfirm(false);
            } else {
                toast('An error occurred');
            }

            refetchCheck();
        }
    })


    const cancelSubscriptionRenew = async () => {
        setShowLoaderConfirm(true);
        await cancelSubscriptionMut.mutateAsync();
    };
    
    const enableSubscriptionRenew = async () => {
        setShowLoaderConfirm(true);
        await enableSubscriptionMut.mutateAsync();
    };

    const [planTypeChecked, setPlanTypeChecked] = useState<string>('');

    const mutUpgrade = useMutation({mutationFn: useUpgradeSubscription, retry: 0});

    useEffect(() => {
        if(checkSubscription && checkSubscription.data) {
            let subscription = checkSubscription.data.subscription;
            if(subscription){
                let subscriptionName = capitalizeFirstLetter(subscription.type.name) + ' seller';
                
                setPlan(subscriptionName);

                if(subscription.type.type != "free"){
                    setIsFree(false)
                } else setEnableUpgrade(true);

                setPlanTypeChecked(checkSubscription.data.subscription.type.type);
            } else {
                if(mySellerListing?.seller) {
                    setPlan('Premium seller unpaid');
                }
            }
            setEnableCheckSubscription(false);
        }
    }, [checkSubscription, mySellerListing]);

    const paymentMethodCallBack = async (
        paymentMethodId: string | null,
        setLoading: (l: boolean) => void,
        savePaymentMethod?: boolean,
        setShowPaymentStatus?: (
            show: boolean,
            data: {
                status: 'success' | 'error';
                invoiceNumber?: string;
                invoiceUrl?: string;
            }
        ) => void
    ) => {
        try {
            const data = await upgradeSubscription(paymentMethodId, savePaymentMethod);
            if (data?.status == 200) {
                setShowPaymentStatus && setShowPaymentStatus(true, {
                    status: 'success',
                    invoiceNumber: data.data?.invoice_number,
                    invoiceUrl: data.data?.invoice_url
                });
                refetchReceipt(queryClient);
            }
        } catch (error) {
            setShowPaymentStatus && setShowPaymentStatus(true, {
                status: 'error',
            });
            setPlanTypeChecked(checkSubscription.data.subscription.type.type);
        } finally {
            invalidateQueryProductWithTax(queryClient);
            invalidateQueryProduct(queryClient);

            refetchPaymentMethod(queryClient);
            
            setLoading(false);
        }
    };
    
    const handleStatusOk = () => {
        setShowPaymentModal(false);
        refetchCheck();
    };

    const upgradeSubscription = async (paymentId: string | null, savePaymentMethod?: boolean) => {
        const params = {
            planType: planTypeChecked,
            paymentId,
            savePaymentMethod
        }
        const p = await mutUpgrade.mutateAsync(params);
    
        return p?.data;
    }

    return (
        <>
            <div className="itemBilling">
                <h2 className="title">Seller plan details</h2>
                <div className="contentItem">
                    {(isLoading || mySellerListingsLoading) &&
                        <LoaderCommon />
                    }
                    {(!isLoading  && isMySellerListingFetched) &&
                        <>
                            <div className="itemctt">
                                <>
                                    Subscription plan : {plan} {" "}
                                    <br />
                                </>
                            </div>
                            <div className="footerItem" style={{ fontSize: "14px" }}>
                            {(checkSubscription && !checkSubscription.data.subscription && isMySellerListingFetched && !mySellerListing?.seller) &&
                                (
                                    <a onClick={() => goToEnrollAsSeller()}>
                                        Enroll
                                    </a>
                                )
                            }
                            {
                                isPremiumUnpaid &&
                                <a onClick={() => handleGoToMyListing()}>
                                    View my listing
                                </a>
                            }
                            {checkSubscription && checkSubscription.data.subscription && isFree &&
                                <a onClick={() => setShowMyPlanModal(true)}>
                                    View plan and upgrade
                                </a>
                            }
                            {checkSubscription && checkSubscription.data.subscription && !isFree &&
                                <>
                                    <a onClick={() => setShowMyPlanModal(true)}>
                                        View plan
                                    </a>
                                    {" "}
                                    <span className="separ"></span>{" "}
                                    <a onClick={() => {setShowManagePlan(true)}}>
                                        Manage plan
                                    </a>
                                </>
                            }
                            </div>
                        </>
                    }
                </div>
            </div>
            {checkSubscription && checkSubscription.data && checkSubscription.data.subscription &&
                <CurrentPlan
                    subscriptionsTypes={subscriptions}
                    myPlan={checkSubscription.data.subscription.type.type}
                    closeModal={
                        () => {
                            setPlanTypeChecked(checkSubscription.data.subscription.type.type);
                            closeMyPlanModal();
                        }
                    }
                    show={showMyPlanModal}
                    title={
                        enableUpgrade ?
                        "Seller Plan > Upgrade your subscription plan"
                        : "Seller Plan > View plan"
                    }
                    enableUpgrade={enableUpgrade}
                    handleUpgrade={() => {
                        setShowPaymentModal(true);
                        closeMyPlanModal();
                    }}
                    planTypeChecked={planTypeChecked}
                    setPlanTypeChecked={setPlanTypeChecked}
                />
            }
            {checkSubscription && checkSubscription.data && checkSubscription.data.subscription &&
                <>
                    <ManagePlan
                        show={showManagePlan}
                        checkSubscription={checkSubscription.data}
                        type="seller"
                        handleShow={() =>
                            setShowManagePlan(!showManagePlan)
                        }
                        handleShowConfirm={(value: boolean) => setShowConfirmAutoRenewal(value)}
                    />
                    <ConfirmUpdateRenewalSubscription
                        show={showConfirmAutoRenewal}
                        checkSubscription={checkSubscription.data}
                        handleShowConfirm={() =>
                            setShowConfirmAutoRenewal(false)
                        }
                        cancelSubscriptionRenew={cancelSubscriptionRenew}
                        enableSubscriptionRenew={enableSubscriptionRenew}
                        showLoader={showLoaderConfirm}
                        type="seller"
                    />
                </>
            }
            {enableUpgrade &&
                <PaymentPopup
                    planType={planTypeChecked}
                    listingType="seller"
                    successCallback={paymentMethodCallBack}
                    handleStatusOk={handleStatusOk}
                    setShowPaymentModal={setShowPaymentModal}
                    title={"Seller Plan > Upgrade your subscription plan"}
                    showTryAgain={false}
                    showContinue={true}
                    handleClose={
                        () => {
                            setPlanTypeChecked(checkSubscription.data.subscription.type.type);
                            handleStatusOk();
                        }
                    }
                    handleCancel={
                        () => {
                            setPlanTypeChecked(checkSubscription.data.subscription.type.type);
                            setShowPaymentModal(false);
                        }
                    }
                    show={showPaymentModal}
                    modalSize="large"
                    isUpgradePlan={true}
                />
            }
        </>
    );
}

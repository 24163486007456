// import React from 'react';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.scss';
import { authInstance } from "./keycloak";
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const keycloakInitOptions = { onLoad: 'check-sso' };

const saveToken = (token: any) => {
  localStorage.setItem("accessToken", token.token);
  localStorage.setItem("refreshToken", token.refreshToken);
}

root.render(
  <ReactKeycloakProvider authClient={authInstance} initOptions={keycloakInitOptions} onTokens={(token: any) => saveToken(token) }>
    {/* <React.StrictMode> */}
      <App />
    {/* </React.StrictMode> */}
  </ReactKeycloakProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "../../pages/seller/seller.scss";
import { capitalizeFirstLetter } from "../../utils/outils";
import { LoaderCommon } from "../../widget/loader";
import { PaymentInfoWrapper } from "./payment-info/payment-info-wrapper";
import { PaymentStatus } from "./payment-status/payment-status";
import { ProductInfo } from "./product-info/product-info";
import usePaymentHook from "./use-payment-hook";

export type IProps = {
    planType: string;
    listingType: string;
    successCallback: (
        paymentMethodId: string | null,
        setLoading: (l: boolean) => void,
        savePaymentMethod?: boolean,
        setShowPaymentStatus?: (show: boolean, data: {status: 'success' | 'error'; invoiceNumber?: string; invoiceUrl?: string}) => void
    ) => void;
    title: string;
    handleStatusOk: () => void;
    showTryAgain: boolean;
    showContinue: boolean;
    handleClose?: () => void;
    handleCancel: () => void;
    show: boolean;
    modalSize: string;
    setShowPaymentModal: (value: boolean) => void;
    isUpgradePlan: boolean;
    productTitle?: string;
};

export default function PaymentPopup(props: IProps) {
    const [product, setProduct] = useState<{
        lookupKey: string;
        name: string;
    }>({
        lookupKey: '',
        name: '',
    });

    useEffect(() => {
        const productKey = (props?.planType+ '_' + props?.listingType).toUpperCase();

        setProduct({
            lookupKey: productKey,
            name: props?.productTitle ? props?.productTitle : capitalizeFirstLetter(props?.planType) + ' ' + props?.listingType,
        });
    }, [props?.planType]);

    const [showStatus, setShowStatus] = useState(false);
    const [status, setStatus] = useState<'success' | 'error'>('success');
    const [invoiceNumber, setInvoiceNumber] = useState<string>('');
    const [invoicePdf, setInvoicePdf] = useState<string>('');

    const handleStatus = (
        show: boolean,
        data: {
            status: 'success' | 'error';
            invoiceNumber?: string;
            invoiceUrl?: string;
        }
    ) => {
        setStatus(data.status);
        setInvoiceNumber(data.invoiceNumber || '');
        setInvoicePdf(data.invoiceUrl || '');
        if (show) props?.setShowPaymentModal && props?.setShowPaymentModal(false);
        setShowStatus(show);
    };

    const {
        prices,
        paymentMethod,
        currency,
        setCurrency,
        getProductPriceWithTax,
        getCurrencySymbol,
        productPrice,
        setProductPrice,
        userEmail,
        setModalSize,
        isLoading
    } = usePaymentHook(product);

    return (
        <div>
            <Modal
            show={props?.show}
            className={`createCompanypopup ${props?.modalSize} forStep5custom`}
            style={{ height: "100%" }}
            >
                <Modal.Header>
                    <div className="headerpopupCompany noStep">
                        <h2 className="title">{props?.title}</h2>
                        <a
                            href="javascript:;"
                            className="close"
                            onClick={() => props?.handleClose && props?.handleClose()}
                        >
                            <span className="icon-close-pop"></span>
                        </a>
                    </div>
                </Modal.Header>
                <Modal.Body className="">
                    <div className='contentUser'>
                        {isLoading && <div className='forBillingCenter pt-3'> <LoaderCommon /></div>}
                        
                        {!isLoading && <div className='forBillingCenter pt-3'>
                            {!paymentMethod &&
                                <PaymentInfoWrapper
                                    product={product}
                                    currency={currency}
                                    setCurrency={setCurrency}
                                    getProductPriceWithTax={getProductPriceWithTax}
                                    getCurrencySymbol={getCurrencySymbol}
                                    productPrice={productPrice}
                                    setProductPrice={setProductPrice}
                                    userEmail={userEmail}
                                    successCallback={props?.successCallback}
                                    handleStatus={handleStatus}
                                    handleCancel={props?.handleCancel}
                                    isUpgradePlan={props?.isUpgradePlan}
                                />
                            }
                            {paymentMethod &&
                                <ProductInfo
                                    prices={prices}
                                    paymentMethod={paymentMethod}
                                    setCurrency={setCurrency}
                                    currency={currency}
                                    getCurrencySymbol={getCurrencySymbol}
                                    productPrice={productPrice}
                                    setProductPrice={setProductPrice}
                                    successCallback={props?.successCallback}
                                    handleStatus={handleStatus}
                                    handleCancel={props?.handleCancel}
                                />
                            }
                        </div>}
                    
                    </div>
                </Modal.Body>
            </Modal>
            <PaymentStatus
                title={props?.title}
                invoiceNumber={invoiceNumber}
                invoiceUrl={invoicePdf}
                show={showStatus}
                status={status}
                handleClose={() => setShowStatus(false)}
                handleOk={() => {
                    props?.handleStatusOk();
                    setShowStatus(false);
                }}
                paymentMethod={paymentMethod}
                tryAgain={() => {
                    setModalSize('large');
                    setShowStatus(false);
                }}
                showTryAgain={props?.showTryAgain}
                showContinue={props?.showContinue}
                isUpgradePlan={props?.isUpgradePlan}
                lookupKey={product.lookupKey}
            />
        </div>
    );
}
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { useGetProductPrice, usePostProductPriceWithTax } from '../../api/payment';
import { useKeycloakHooks } from '../../hooks/keycloak';

const currencies: { [key: string]: string } = {
  usd: '$',
  eur: '€',
};

export type PaymentMethod = {
  id: string;
  currency: string;
  card: {
    brand: string;
    last4: string;
  };
};

export default function usePaymentHook(
  product?: {
    lookupKey: string;
    name: string;
  }
) {

  const { keycloak, tokenInfo } = useKeycloakHooks();

  const userEmail = tokenInfo?.email;

  const [step, setStep] = useState<'product-info' | 'payment-info'>('product-info');
  const [modalSize, setModalSize] = useState<'small' | 'large'>('small');
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>();
  const [prices, setPrices] = useState<{
    [key: string]: number;
  }>();
  const [currency, setCurrency] = useState<string | undefined>();
  const [productPrice, setProductPrice] = useState({
    amount: 0,
    tax: 0,
    currency: '',
    totalAmount: 0,
  });
  const [buttonLoader, setButtonLoader] = useState(false);

  const postProductPriceWithTaxMut = useMutation({mutationFn:usePostProductPriceWithTax});
  const {
    data,
    isLoading,
    mutateAsync: getProductPriceMut
  } = useMutation({ mutationFn: useGetProductPrice })

  const fetchData = async () => {
    try {
      if(product && product?.lookupKey) {
        const response = await getProductPriceMut(product?.lookupKey ?? '');

        if (response.data.message === 'success') {
          const productPrices = response.data.data.prices;
          const curr = productPrices.usd === undefined ? 'eur' : 'usd';
          setCurrency(curr);
          setPrices(productPrices);
          setProductPrice({
            ...productPrice,
            currency: curr,
            amount: response.data.data.prices[curr] || 0,
            tax: 0,
            totalAmount: response.data.data.prices[curr] || 0,
          });
          setPaymentMethod(response.data.data.payment_method);
        } else {
          toast('Error getting product price');
        }
      }
    } catch (error) {
      toast('Error getting product price');
    }
  };

  useEffect(() => {
    fetchData();
  }, [keycloak?.token, product]);

  const getProductPriceWithTax = async (
    country: string,
    vat: {
      has_vat: boolean;
      vat_number: string;
      company_name: string;
    }
  ) => {
    return await postProductPriceWithTaxMut.mutateAsync({lookupKey: product ? product.lookupKey  : '', country, data :{ vat: vat }});
  };

  const getCurrencySymbol = (cur?: string) => {
    return cur ? currencies[cur] : currencies[currency || 'usd'];
  };

  return {
    prices,
    setPrices,
    paymentMethod,
    setPaymentMethod,
    currency,
    setCurrency,
    getProductPriceWithTax,
    getCurrencySymbol,
    productPrice,
    setProductPrice,
    userEmail,
    step,
    setStep,
    modalSize,
    setModalSize,
    buttonLoader,
    setButtonLoader,
    isLoading
  };
}

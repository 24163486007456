import { useEffect, useState } from "react";
import { useGetUserInvoices } from "../../../api/payment";
import { LoaderCommon } from "../../../widget/loader";
import ReceiptsListModal from "./receipts-list-modal";

const Receipts = () => {
    const [lastInvoice, setLastInvoice] = useState<{
        paid_at: string
    }>();

    const [enabledGetReceipts, setEnabledGetReceipts] = useState(true);
    const [showModal, setShowModal] = useState(false);

    const { data: receipts, isFetched: isReceiptsFetched, refetch, isLoading } = useGetUserInvoices(enabledGetReceipts);

    useEffect(() => {
        if(receipts){
            if (receipts.message === 'success') {
                if(receipts.data.invoices.length !== 0) {
                    console.log(receipts.data.invoices);
                    setLastInvoice(receipts.data.invoices[0]);
                    setEnabledGetReceipts(false);
                }
            }
        }
    }, [receipts])

    return (
        <>
            <div className="itemBilling">
                <h2 className="title">Receipts</h2>
                <div className="contentItem">
                    {isLoading && !lastInvoice &&
                        <LoaderCommon />
                    }
                    {isReceiptsFetched && lastInvoice &&
                        <>
                            <div className="itemctt">
                                <div className="itemctt">
                                    <b>
                                        { lastInvoice.paid_at && `Last receipt: ${lastInvoice.paid_at.split('-')[0]}`}
                                    </b>
                                </div>
                            </div>
                            {lastInvoice &&
                                <div className="footerItem" style={{ fontSize: "14px" }}>
                                    {/* //dev #47985 V2.1 [OEM/Ecosystem] donner l'accès au "Billing center" pour le rôle "Outreach representative" ET "Editor" */}
                                    <a
                                        onClick={() => setShowModal(true)}
                                    >
                                        View details
                                    </a>
                                </div>
                            }
                        </>
                    }
                        
                </div>
            </div>

            <ReceiptsListModal
                show={showModal}
                setShow={setShowModal}
            />
        </>
    );
};

export default Receipts;

import { useEffect, useState } from "react";
import { Accordion, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import CreatableSelect from "react-select/creatable";
import {
    generateYearList,
    useGetBusinessModel,
    useGetHowManyPeopleEmployed,
    useGetIndustryFiltre,
    useGetMySpecificTehnologyListing,
    useGetSolutionsFiltre,
    useGetTechnologyPartenership
} from "../../../../../api/seller";
import { useGetStaticParams } from "../../../../../api/staticParams";
import ModalIndustryList from "../../../../../components/modalIndustryList";
import ModalSolutionList from "../../../../../components/modalSolutionList";
import SellerSteps from "../../../../../components/seller/seller-steps";
import { ICompanyBasics } from "../../../../../types/company-basics";
import { Countries } from "../../../../../widget/countries";
// import { Geographic, ICompanyBasics } from "../../../../../types/company-basics";
import Select from "react-select";

type IProps = {
    handleNextStep: (number: number) => void;
    showCancelModal: () => void;
    setData: (data: ICompanyBasics) => void;
    data: ICompanyBasics;
    validateSteps: Array<number>;
    currentStep: number;
    setCurrentStep: (step: number) => void;
    removeStepInValidated: (step: number) => void;
    addStepToValidated: (step: number) => void;
};
type EcosystemItem = {
    value: number | string;
    label: string;
    __isNew__?: boolean
};
interface UserSpecificTechnology {
    id: number;
    idUser: string;
    specificTechnology: any[];
}
export default function CompanyBasics(props: IProps) {
    const _ = require('lodash');

    const [formState, setFormState] = useState(props?.data);
    const [enabled, setEnabled] = useState(false);
    const [enableSpecificTechnologie, setEnableSpecificTechnologie] = useState(true);
    const { data: dataHowManyPeople } = useGetHowManyPeopleEmployed(enabled);
    const years = generateYearList();
    const { data: dataBusinessModel } = useGetBusinessModel(enabled);
    const { data: dataTechnologyPartenership } = useGetTechnologyPartenership(enabled);
    const { data: dataSolutions } = useGetSolutionsFiltre(enabled);
    const { data: dataIndustries } = useGetIndustryFiltre(enabled);
    const { data: userSpecificTechnologie, isFetched: isSpecificTechnologieFetched } = useGetMySpecificTehnologyListing(enableSpecificTechnologie);

    const [enableGetCountries, setEnableGetCountries] = useState(true);
    const { data: dataCountry, isFetched: isCountriesFetched } = useGetStaticParams('COUNTRIES_SORT_NAME', enableGetCountries);

    const [errorHeadquarter, setErrorHeadquarter] = useState(false);
    const [errorManyPeople, setErrorManyPeople] = useState(false);
    const [errorFounded, setErrorFounded] = useState(false);
    const [errorBusiness, setErrorBusiness] = useState(false);
    const [errorTechnology, setErrorTechnology] = useState(false);
    const [errorHaveTechnology, setErrorHaveTechnology] = useState(false);
    const [errorSolution, setErrorSolution] = useState(false);
    const [errorIndustry, setErrorIndustry] = useState(false);
    const [errorGeographic, setErrorGeographic] = useState(false);

    const [showPIndustry, setShowPIndustry] = useState(false);
    const [showPSolution, setShowPSolution] = useState(false);
    const [selectedIndustry, setSelectedIndustry] = useState<string[]>(formState?.targeted_industry);
    const [selectedSolution, setSelectedSolution] = useState<string[]>(formState?.software_solution);
    const [selectedFeaturedSolution, setSelectedFeaturedSolution] = useState<string[]>(formState?.featured_solution);

    const [currentSelected, setCurrentSelected] = useState<{
        value: number;
        label: string;
    }>();
    const [userSpecificTechnologies, setUserSpecificTechnologies] = useState<Array<UserSpecificTechnology>>([]);
    const ecosystemArray = Array.isArray(formState?.technology_ecosystem) && formState.technology_ecosystem.length > 0 ? formState.technology_ecosystem : [];

    const [currentSelectedList, setCurrentSelectedList] = useState<Array<{ value: number; label: string }>>(
        ecosystemArray?.map((item: EcosystemItem) => {
            return { value: item.value as number, label: item.label };
        }) || []
    );

    useEffect(() => {
        if (isCountriesFetched) setEnableGetCountries(false)
    }, [isCountriesFetched]);

    useEffect(() => {
        if (dataTechnologyPartenership && dataTechnologyPartenership.data) {
            let combinedData = [];

            const mappedData = dataTechnologyPartenership.data.map((item: any) => ({
                value: item.id,
                label: item.attributes.Label
            }));

            combinedData = [...mappedData];

            if (userSpecificTechnologies.length > 0) {
                const lastEcosystemDataId = dataTechnologyPartenership.data[dataTechnologyPartenership.data.length - 1].id;
                const transformedUserSpecificTechnologies = userSpecificTechnologies.map((item, index) => ({
                    value: lastEcosystemDataId + index + 1,
                    label: item
                }));
                combinedData = [...combinedData, ...transformedUserSpecificTechnologies];
            }

            setTechnologiesPartnership(combinedData);
        }
    }, [dataTechnologyPartenership, userSpecificTechnologies]);

    useEffect(() => {
        if (isSpecificTechnologieFetched) setEnableSpecificTechnologie(false);
    }, [isSpecificTechnologieFetched]);
    useEffect(() => {
        if (userSpecificTechnologie) {
            let specificTech: Array<UserSpecificTechnology> = [];
            userSpecificTechnologie.forEach((item: UserSpecificTechnology) => {
                specificTech = specificTech.concat(item.specificTechnology);
            });
            setUserSpecificTechnologies(specificTech);
        }
    }, [userSpecificTechnologie]);


    const [technologiesPartnership, setTechnologiesPartnership] = useState<Array<{ value: number; label: string }>>([]);

    const [activeId, setActiveId] = useState<any>("null");
    function toggleActive(id: any) {
        if (activeId === id) {
            setActiveId("null");
        } else {
            setActiveId(id);
        }
    }

    const geographicData = dataCountry && dataCountry.data ? dataCountry?.data?.reduce((acc: any, current: any) => {
        if (!current || !current.attributes) {
            return acc;
        }
        const continent = current?.attributes?.Continent;
        const countryInfo = {
            ISO2: current?.attributes?.ISO2,
            Name: current?.attributes?.Name,
        };

        if (!acc[continent]) {
            acc[continent] = {
                Continent: continent,
                Countries: [countryInfo],
            };
        } else {
            acc[continent].Countries.push(countryInfo);
        }

        return acc;

    }, {}) : {};

    const txtBtnCat =
        [...formState?.software_solution, ...formState?.featured_solution]?.length > 0
            ? (Math.ceil([...formState?.software_solution, ...formState?.featured_solution]?.length / 2) + ' solution' + (Math.ceil([...formState?.software_solution, ...formState?.featured_solution]?.length / 2) > 1 ? 's' : '') + ' selected')
            : 'Select horizontal solution(s)';


    const txtBtnIndustry =
        formState?.targeted_industry?.length > 0
            ? (formState?.targeted_industry?.length + (formState?.targeted_industry?.length > 1 ? ' industries' : ' industry') + ' selected')
            : 'Select vertical industry(ies)';

    const addTargetedIndustry = (element: any) => {
        setSelectedIndustry([...selectedIndustry, element?.attributes?.Label]);
    }

    const removeTargetedIndustry = (element: any) => {
        setSelectedIndustry(selectedIndustry?.filter(
            (el: any) => el !== element?.attributes?.Label
        ));
    }


    const addSoftwareSolution = (element: any) => {
        setSelectedSolution([...selectedSolution, element?.attributes?.Label]);
    }

    const addFeaturedSolution = (element: any) => {
        setSelectedFeaturedSolution([...selectedFeaturedSolution, element?.attributes?.Label]);
    }

    const removeSolution = (element: any) => {
        setSelectedSolution(selectedSolution?.filter(
            (el: any) => el !== element?.attributes?.Label
        ));
        
        setSelectedFeaturedSolution(selectedFeaturedSolution?.filter(
            (el: any) => el !== element?.attributes?.Label
        ));
    }

    const removeItemSelected = (element: any) => {
        setSelectedSolution(selectedSolution?.filter(
            (el: any) => el !== element
        ));
        
        setSelectedFeaturedSolution(selectedFeaturedSolution?.filter(
            (el: any) => el !== element
        ));
    }

    const addListOption = (data: any) => {
        if (currentSelected) {
            let currentOptionList = technologiesPartnership.filter(
                (el: { value: number; label: string }) => el.value !== data.value
            );

            setCurrentSelectedList([...currentSelectedList, currentSelected]);
            setTechnologiesPartnership(currentOptionList);
            setCurrentSelected({ label: '', value: 0 });
            updateState('technology_ecosystem', [...currentSelectedList, currentSelected]);
        }
    };

    const removeSelected = (id: number) => {
        let data = currentSelectedList?.find(
            (el: { value: number; label: string }) => el.value === id
        );
        let tempValue = currentSelectedList?.filter(
            (el: { value: number; label: string }) => el.value !== id
        );
        setCurrentSelectedList(tempValue);
        if (data) {
            setTechnologiesPartnership([data, ...technologiesPartnership]);
        }
        updateState('technology_ecosystem', tempValue);
    };

    const validTargetedIndustry = () => {
        updateState('targeted_industry', selectedIndustry);
        setShowPIndustry(false);
    }

    const validSoftwareSolution = () => {
        updateState('software_solution', selectedSolution);
        updateState('featured_solution', selectedFeaturedSolution);
        setShowPSolution(false);
    }

    const backTarget = () => {
        setSelectedIndustry(formState?.targeted_industry);
        setShowPIndustry(false);
    }

    const backSolution = () => {
        setSelectedSolution(formState?.software_solution);
        setSelectedFeaturedSolution(formState?.featured_solution);
        setShowPSolution(false);
    }

    const transformDataCountry = (data: any) => {
        let custom = data.countryesData.map((el: any) => {
            let temp = {
                continent: data?.continent,
                country: el && el?.Name,
                iso2: el && el?.ISO2,
            };
            return temp;
        });
        return custom;
    }

    const updateState = (property: string, value: any) => {
        setFormState((prevState: any) => ({
            ...prevState,
            [property]: value,
        }));
    }

    const goToStep = (step: number, isGoingNextStep: boolean) => {

        if (formState?.location !== "" && formState?.company_size !== "" && formState?.founded !== "" && formState?.business_model.length  > 0 && formState?.have_technology !== null && [...formState?.software_solution, ...formState?.featured_solution].length > 0 && formState?.targeted_industry.length > 0 && formState?.geographic_sales_footprint.length > 0) {

            if (formState?.have_technology == true && formState?.technology_ecosystem.length > 0) {

                props?.setData(formState);
                props?.handleNextStep(step);
                props?.addStepToValidated(props?.currentStep);
            } else if (formState?.have_technology == false) {

                props?.setData(formState);
                props?.handleNextStep(step);
                props?.addStepToValidated(props?.currentStep);
            }
            else if (formState?.have_technology == true ) {
                if(currentSelectedList.length === 0){
                    setErrorTechnology(true);
                    props?.removeStepInValidated(props?.currentStep);
                    return;
                }
            }

        } else {

            if (isGoingNextStep) {
                if (formState?.location == "") {
                    setErrorHeadquarter(true);
                }
                if (formState?.company_size == "") {
                    setErrorManyPeople(true);
                }
                if (formState?.founded == "") {
                    setErrorFounded(true);
                }
                if (formState?.business_model.length < 1) {
                    setErrorBusiness(true);
                }
                if (formState?.have_technology == null) {
                    setErrorHaveTechnology(true);
                }
                if ([...formState?.software_solution, ...formState?.featured_solution].length < 1) {
                    setErrorSolution(true);
                }
                if (formState?.targeted_industry.length < 1) {
                    setErrorIndustry(true);
                }
                if (formState?.geographic_sales_footprint.length < 1) {
                    setErrorGeographic(true);
                }
                if (currentSelectedList.length === 0 || currentSelectedList === undefined) {
                    setErrorTechnology(true);
                }

            }

            props?.removeStepInValidated(props?.currentStep);

            return;
        }

    }

    useEffect(() => {
        if (formState?.have_technology) {
            setErrorHaveTechnology(false);
        }

        if (currentSelectedList && currentSelectedList.length > 0) {
            setErrorTechnology(false);
        }

        if (formState?.geographic_sales_footprint.length > 1) {
            setErrorGeographic(false);
        }

    }, [formState]);



    useEffect(() => {
        if (props?.currentStep === 4) setEnabled(true);
        if (dataHowManyPeople && dataBusinessModel && dataTechnologyPartenership && dataSolutions && dataIndustries && dataCountry) setEnabled(false);
    }, [props?.currentStep, dataHowManyPeople, dataBusinessModel, dataTechnologyPartenership, dataSolutions, dataIndustries, dataCountry]);

    useEffect(() => {
        setErrorHeadquarter(false);
    }, [formState?.location]);

    useEffect(() => {
        setErrorManyPeople(false);
    }, [formState?.company_size]);

    useEffect(() => {
        setErrorFounded(false);
    }, [formState?.founded]);

    useEffect(() => {
        setErrorBusiness(false);
    }, [formState?.business_model]);

    useEffect(() => {
        setErrorTechnology(false);
    }, [formState?.technology_ecosystem]);

    useEffect(() => {
        setErrorHaveTechnology(false);
    }, [formState?.have_technology]);

    useEffect(() => {
        setErrorSolution(false);
    }, [formState?.software_solution, formState?.featured_solution]);

    useEffect(() => {
        setErrorIndustry(false);
    }, [formState?.targeted_industry]);

    useEffect(() => {
        props?.setData(formState);
    }, [formState]);

    const isHaveSelectedLabel =
        currentSelected && currentSelected.label;

    return (
        <>
            <SellerSteps
                validatedSteps={props?.validateSteps}
                currentStep={props?.currentStep}
                handleContinue={goToStep}
                setCurrentStep={props?.setCurrentStep}
            />
            <div className='container mx-auto minHeightwin pt-4'>
                <div id="mainterest" className="scrollContainer">
                    <div className='contentUser acquisition'>
                        <div className='contentBorder row'>
                            <div className='form-group required mb-4 p-0'>
                                <label htmlFor=''>Where is your business headquartered?</label>
                                {
                                    errorHeadquarter && (
                                        <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                            <span className='error-red'> ERROR </span>{" "}
                                            <span className='txt-error'>This field is required</span>
                                        </div>
                                    )
                                }
                                <select className='form-control mt-2'
                                    name='location'
                                    onChange={(evt) => updateState(evt.target.name, evt.target.value)}
                                >
                                    <option value="">Select an option ...</option>
                                    {
                                        dataCountry?.data?.map((el: any, index: any) => (
                                            <option selected={el?.attributes?.ISO2 == formState?.location} value={el?.attributes?.ISO2} key={index}>{el?.attributes?.Name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className='form-group required mb-4 p-0'>
                                <label htmlFor=''>How many people does your business employ?</label>
                                {
                                    errorManyPeople && (
                                        <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                            <span className='error-red'> ERROR </span>{" "}
                                            <span className='txt-error'>This field is required</span>
                                        </div>
                                    )
                                }
                                <select className='form-control mt-2'
                                    name='company_size'
                                    onChange={(evt) => updateState(evt.target.name, evt.target.value)}
                                >
                                    <option value="">Select an option ...</option>
                                    {
                                        dataHowManyPeople?.data?.map((el: any, index: any) => (
                                            <option selected={el?.attributes?.Label == formState?.company_size} value={el?.attributes?.Label} key={index}>{el?.attributes?.Label}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className='form-group required mb-4 p-0'>
                                <label htmlFor=''>In what year was your business founded? </label>
                                {
                                    errorFounded && (
                                        <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                            <span className='error-red'> ERROR </span>{" "}
                                            <span className='txt-error'>This field is required</span>
                                        </div>
                                    )
                                }
                                <select className='form-control mt-2'
                                    name='founded'
                                    onChange={(evt) => updateState(evt.target.name, evt.target.value)}
                                >
                                    <option value="">Select an option ...</option>
                                    {
                                        years?.map((el: any, index: any) => (
                                            <option selected={el == formState?.founded} value={el} key={index}>{el}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="form-group required selectSol mt-2 p-0 mb-4">
                                <label className='mb-2' htmlFor=''>What vertical industry(ies) do you serve? </label>
                                {
                                    errorIndustry && (
                                        <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                            <span className='error-red'> ERROR </span>{" "}
                                            <span className='txt-error'>This field is required</span>
                                        </div>
                                    )
                                }
                                <div className="d-flex mb-1 w-100 align-items-center">
                                    <div className="btn btn-devinsider m-0" onClick={() => setShowPIndustry(true)}>{txtBtnIndustry}</div>
                                    <div className="listOf more m-0">
                                        {' '}
                                        <div className='topicAds'>
                                            {formState?.targeted_industry?.map((el: any, key: number) => (
                                                <span className='topic' key={key}>{el}</span>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group required selectSol mt-2 p-0 mb-4">
                                <label className='mb-2' htmlFor=''>What horizontal solution(s) do you provide?</label>
                                {
                                    errorSolution && (
                                        <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                            <span className='error-red'> ERROR </span>{" "}
                                            <span className='txt-error'>This field is required</span>
                                        </div>
                                    )
                                }
                                <div className="d-flex mb-1 w-100 mb-3 align-items-center">
                                    <div className="btn btn-devinsider m-0" onClick={() => setShowPSolution(true)}>{txtBtnCat}</div>
                                    <div className="listOf more m-0">
                                        {' '}
                                        <div className='topicAds'>
                                            {[...formState?.software_solution, ...formState?.featured_solution]?.filter((value, index, self) => self.indexOf(value) === index)
                                                ?.map((el: any, key: number) => (
                                                    <span className='topic' key={key}>{el}</span>
                                                ))}
                                        </div>
                                        {/* <div className='topicAds'>
                                            {allSolutions?.map((el: any, key: number) => (
                                                <span className='topic' key={key}>{el}</span>
                                            ))}
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className='form-group required mb-4 p-0'>
                                <label htmlFor=''>What is your business model? </label>
                                {
                                    errorBusiness && (
                                        <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                            <span className='error-red'> ERROR </span>{" "}
                                            <span className='txt-error'>This field is required</span>
                                        </div>
                                    )
                                }
                                {/* <select className='form-control mt-2'
                                    name='business_model'
                                    onChange={(evt) => updateState(evt.target.name, evt.target.value)}
                                >
                                    <option value="">Select an option ...</option>
                                    {
                                        dataBusinessModel?.data?.map((el: any, index: any) => (
                                            <option selected={el?.attributes?.Label == formState?.business_model} value={el?.attributes?.Label} key={index}>{el?.attributes?.Label}</option>
                                        ))
                                    }
                                </select> */}
                                <Select
                                    options={dataBusinessModel?.data?.map((el: any) => {
                                        return {
                                        label: el?.attributes?.Label,
                                        value: el?.attributes?.Label
                                        }; 
                                    })}
                                    filterOption={() => true}
                                    className='form-control mt-2 p-0'
                                    classNamePrefix='react-select'
                                    placeholder="Select all that apply ..."
                                    name='technology_ecosystem'
                                    id="selectable"
                                    isMulti={true}
                                    value={formState?.business_model}
                                    onChange={(selectedOptions: any) => {

                                        updateState('business_model', selectedOptions);
                                    }}
                                />
                            </div>
                            <div className='form-group required mb-4 p-0'>
                                <label htmlFor=''>What is your geographic sales footprint? </label>{' '}
                                <span className="selectalloverstyle">(Select all that apply)</span>
                                {
                                    errorGeographic && (
                                        <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                            <span className='error-red'> ERROR </span>{" "}
                                            <span className='txt-error'>This field is required</span>
                                        </div>
                                    )
                                }

                                <div className="geographicList">
                                    <Accordion defaultActiveKey={activeId}>
                                        <Countries
                                            geographicData={geographicData}
                                            activeId={activeId}
                                            toggleActive={toggleActive}
                                            data={formState.geographic_sales_footprint}
                                            stateName={"geographic_sales_footprint"}
                                            updateState={updateState}
                                            isEdit={false}
                                        />
                                    </Accordion>

                                </div>

                            </div>
                            <div className='form-group required mb-2 p-0 eco'>
                                <label className="m-0" htmlFor="">
                                    Do you currently have any technology partnerships with
                                    <OverlayTrigger
                                        overlay={
                                            <Tooltip id="trade">
                                                Software vendors (ISVs) that offer an embedded or white labeled solution that
                                                becomes an integral part of another vendors’s software,
                                                resulting in a strategic partnership.
                                            </Tooltip>
                                        }
                                    >
                                        <span className="oemTooltip">OEMs</span>
                                    </OverlayTrigger>
                                    or
                                    <OverlayTrigger
                                        overlay={
                                            <Tooltip id="trade">
                                                Software ecosystems that provide software vendors (ISVs) with tools, platforms,
                                                technologies they can build solutions on top of.
                                            </Tooltip>
                                        }
                                    >
                                        <span className="oemTooltip">Ecosystems?</span>
                                    </OverlayTrigger>
                                </label>
                                {
                                    errorHaveTechnology && (
                                        <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                            <span className='error-red'> ERROR </span>{" "}
                                            <span className='txt-error'>This field is required</span>
                                        </div>
                                    )
                                }
                            </div>
                            <div className='form-group mb-4 p-0 eco'>
                                <div
                                    className="checkcontainer"
                                >
                                    <Form.Check
                                        name="have_technology"
                                        type="radio"
                                        label="Yes"
                                        id="Yes"
                                        onClick={() => updateState("have_technology", true)}
                                        checked={formState?.have_technology === true ? true : false}
                                        onChange={() => null}
                                    />
                                </div>
                                <div
                                    className="checkcontainer"
                                >
                                    <Form.Check
                                        name="have_technology"
                                        type="radio"
                                        label="No"
                                        id="No"
                                        onClick={() => updateState("have_technology", false)}
                                        checked={formState?.have_technology === false ? true : false}
                                        onChange={() => null}
                                    />
                                </div>
                            </div>

                            {
                                formState?.have_technology !== null && formState?.have_technology && (
                                    <>
                                        <div className='form-group required mb-2 p-0 eco paterneer position-relative'>
                                            <label htmlFor=''>What technology ecosystem are you part of? </label>
                                            {
                                                errorTechnology && (
                                                    <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                                        <span className='error-red'> ERROR </span>{" "}
                                                        <span className='txt-error'>This field is required</span>
                                                    </div>
                                                )
                                            }
                                            {/* <select className='form-control mt-2'
                                            name='technology_ecosystem'
                                            onChange={(evt) => updateState(evt.target.name, evt.target.value)}
                                        >
                                            <option value="">Select an option ...</option>
                                            {
                                                technologyEcosystems?.map((el : any, index : any) => (
                                                    <option selected={el == formState?.technology_ecosystem} value={el} key={index}>{el}</option>
                                                ))
                                            }
                                        </select> */}
                                            {/* <Select
                                        options={dataTechnologyPartenership?.data?.map((el: any) => {
                                                return {
                                                label: el?.attributes?.Label,
                                                value: el?.attributes?.Label
                                                }; 
                                            })}
                                            filterOption={() => true}
                                            className='form-control mt-2 p-0'
                                            classNamePrefix='react-select'
                                            placeholder="Select all that apply ..."
                                            name='technology_ecosystem'
                                            id="selectable"
                                            isMulti={true}
                                            value={formState?.technology_ecosystem}
                                            onChange={(selectedOptions: any) => {

                                                updateState('technology_ecosystem', selectedOptions);
                                            }}
                                        /> */}
                                            <CreatableSelect
                                                options={technologiesPartnership}
                                                className="input-text form-control mt-2 p-0"
                                                classNamePrefix="partneer"
                                                onChange={(el: any) => {
                                                    setCurrentSelected(el);
                                                }}
                                                placeholder="Select all that apply"
                                                value={currentSelected}
                                            />
                                            <button id="add-partneer" className='border-0 d-flex align-items-center' onClick={() =>
                                                isHaveSelectedLabel &&
                                                addListOption(currentSelected)
                                            }>
                                                <svg width="30" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="#fff" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                                </svg>

                                            </button>
                                        </div>
                                        {currentSelectedList &&
                                            currentSelectedList.map(
                                                (el: { value: number; label: string }, index: number) => (
                                                    <div className="paterneer d-flex p-0 mb-2" key={index}>
                                                        <input
                                                            type="text"
                                                            className="input-text form-control"
                                                            placeholder=""
                                                            value={el.label}
                                                            disabled={true}
                                                        />
                                                        <button style={{ background: "#d9534f" }} className='border-0 red-button' onClick={() => removeSelected(el.value)}>
                                                            <svg width="30" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="#fff" className="w-6 h-6">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                )
                                            )
                                        }
                                    </>
                                )
                            }
                        </div>
                        <div className='text-align-right stepsButtons'>
                            <button className='btn btn-devinsider cancel' onClick={() => props?.showCancelModal()}>
                                Cancel
                            </button>
                            <button className='btn btn-devinsider save' onClick={() => goToStep(props?.currentStep + 1, true)}>
                                Continue
                            </button>
                        </div>
                    </div>
                    <ModalIndustryList
                        show={showPIndustry}
                        cancel={backTarget}
                        addIndustry={addTargetedIndustry}
                        removeIndustry={removeTargetedIndustry}
                        industrySelected={selectedIndustry}
                        valid={validTargetedIndustry}
                    />
                    <ModalSolutionList
                        show={showPSolution}
                        cancel={backSolution}
                        dataSolutions={dataSolutions?.data}
                        addSoftwareSolution={addSoftwareSolution}
                        solutionSelected={selectedSolution}
                        addFeaturedSolution={addFeaturedSolution}
                        featuredSolutionSelected={selectedFeaturedSolution}
                        valid={validSoftwareSolution}
                        removeItem={removeItemSelected}
                        removeSolution={removeSolution}
                    />
                </div>
            </div>
        </>

    );
}

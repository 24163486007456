import { useEffect, useState } from "react";
import { Accordion, Modal } from "react-bootstrap";
import { useMutation } from "react-query";
import { UseUpdateSeller, useGetListingById } from "../../../../api/seller";
import { ReactComponent as Loader } from '../../../../resources/images/Rolling.svg';

export default function OpportunityEdit (props: any) {
    const [enabled, setEnabled] = useState(false);

    const { data, refetch } = useGetListingById(props?.idSeller, enabled);

    const mutUpdate = useMutation({mutationFn:UseUpdateSeller, 
        retry: 0,
        onSuccess: (data) => {
            props.setIsLoading(false);
            props.handleOk();
            props?.setData(formState);
        },
        onSettled: (data, error, variables, context) => {
            refetch();
        }
    });

    const getInitialState = () => {
        return {
            ...props?.data,
            summarize: props?.value?.opportunity === "manda_deleted_manda" ? "" : props?.value?.opportunity
        };
    }

    const [formState, setFormState] = useState(getInitialState());

    useEffect(() => {
        if(props?.show) setFormState(getInitialState());
    }, [props?.show]);

    const updateState = (property: string, value: string) => {
        setFormState((prevState: any) => ({
            ...prevState,
            [property]: value,
        }));
    }

    useEffect(() => {
        if (props?.idSeller) setEnabled(true);
        if (data) setEnabled(false);
    }, [data, props?.idSeller]);


    const save = () => {
        if (formState?.summarize === "") {
            props?.setErrorSummarize(true);
            return;
        }

        props.setIsLoading(true);
        let param = {
            idSeller: props?.idSeller,
            opportunity: formState?.summarize
        }

        mutUpdate.mutateAsync(param);
    }

    // Enlever les erreurs lors d'un modif
    useEffect(() => {
        if(formState?.summarize !== "")  props?.setErrorSummarize(false);
    }, [formState?.summarize, props]);

    const handleExit = () => {
        return  props.handleOk && props.handleOk();
    }

    return (
        <Modal
            show={props.show}
            className='createCompanypopup large forStep5custom buyer-modal'
        >
            <Modal.Header>
                <div className='headerpopupCompany noStep'>
                    <h2 className='title'>Opportunity in short</h2>
                    <a
                        className='close'
                        onClick={() => !props.isLoading && handleExit()}
                    >
                        <span className='icon-close-pop'></span>
                    </a>
                </div>
            </Modal.Header>
            <Modal.Body className=''>

                <Accordion className="accordion-popup">
                    <Accordion.Item eventKey="0">
                        <div className='form-group required mb-3 p-0'>
                            <label htmlFor='' style={{fontWeight: 600}}>Describe in 450 characters or less why an acquirer should be interested in your business:</label>{" "}
                            {
                                props?.errorSummarize && (
                                    <div className="container-error pl-2  d-inline" style={{color: 'red'}}>
                                        <span className='error-red'> ERROR </span>{" "}
                                        <span className='txt-error'>This field is required</span>
                                    </div>
                                )
                            }
                        </div>
                        <textarea className='form-control mt-2'
                            name="summarize"
                            value={formState?.summarize}
                            onChange={(evt) => updateState(evt.target.name, evt.target.value)}
                        />
                    </Accordion.Item>
                </Accordion>

            </Modal.Body>
            <Modal.Footer>
                <div className='text-align-right'>

                    <button
                        style={{ marginLeft: "1rem" }}
                        className='btn btn-devinsider cancel'
                        onClick={() => !props.isLoading && handleExit()}>
                        Cancel
                    </button>
                    <button
                        className='btn btn-devinsider save'
                        onClick={() => !props.isLoading && save()}>
                    
                        {props.isLoading ? <Loader /> : "Save"}
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}
import { UUID } from "crypto";
import { JwtPayload, jwtDecode } from "jwt-decode";

export interface IToken {
    exp: number;
    iat: number;
    auth_time: number;
    jti: UUID;
    iss: string;
    aud: string;
    sub: UUID;
    typ: string;
    azp: string;
    nonce: UUID;
    session_state: UUID;
    acr: string;
    realm_access: {
        roles: [];
    };
    resource_access: {
        account: object;
    };
    scope: string;
    sid: UUID;
    email_verified: boolean;
    name: string;
    preferred_username: string;
    given_name: string;
    family_name: string;
    email: string;
    user_uuid: string;
    roles: Array<string>;
    firstname: string;
    lastname: string;
}

export const paginate = (array: Array<any>, page_size = 10, page_number = 1) => {
    if (array?.length <= page_size) {
      return array;
    } else {
      return array?.slice((page_number - 1) * page_size, page_number * page_size);
    }
};

export const addToArray = (array:Array<any>, element: any) => {
  return !array?.find((el) => el === element)
    ? [...array, element]
    : array?.filter((el) => el !== element);
};

export const decodeToken = (token: string) : IToken|null => {
  try {
      // Décoder le token
      const decodedToken = jwtDecode(token) as IToken;

      // Les informations du token sont maintenant dans decodedToken
      if (decodedToken) {
          return decodedToken;
      } else {
          console.error('Impossible de décoder le token.');
          return null;
      }
  } catch (error) {
      console.error('Erreur lors du décodage du token :', error);
      return null;
  }
};

export const capitalizeFirstLetter = (str: string)  => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export const uncapitalizeFirstLetter = (str: string)  => {
  return str.charAt(0).toLowerCase() + str.slice(1);
}

export const userId = (token: string) => {
  try {
    // Décoder le token
    const decodedToken = jwtDecode(token);

    if (typeof decodedToken === 'string') {
      console.error('Le token n\'a pas été correctement décodé.');
      return null;
    }

    // Les informations du token sont maintenant dans decodedToken
    if (decodedToken && 'user_uuid' in decodedToken) {
      const { sub } = decodedToken as JwtPayload;
      return sub;
    } else {
      console.error('Impossible de récupérer user_uuid du token.');
      return null;
    }
  } catch (error) {
    console.error('Erreur lors du décodage du token :', error);
    return null;
  }
};

export const redirectToUrl = (url: string) => {
  if (!/^https?:\/\//i.test(url)) {
    url = 'http://' + url;
  }
  
  window.open(url, '_blank');
}

export const stripePrivateKey = process.env.REACT_APP_STRIPE_PRIVATE_KEY

const currencies: { [key: string]: string } = {
  usd: '$',
  eur: '€',
};

export const getCurrencySymbol = (currency: string) => {
  return currencies[currency || 'usd'];
};
import Modal from 'react-bootstrap/Modal';
import { ReactComponent as Loader } from '../../../resources/images/Rolling.svg';
import Img from "../../../resources/images/locky.svg";
import "./info-creation.scss";

export type IProps = {
    isOpen: boolean;
    title: string;
    closeModal: () => void;
    confirmUnlock: () => void;
    isLoading: boolean;
};

export default function Unlock(props: IProps) {
    
    return (
        <Modal id="unlock-messagerie" show={props?.isOpen} className='createCompanypopup forStep5custom modalDevinsider'>
            <Modal.Header>
                <div className='headerpopupCompany noStep'>
                    <h2 style={{ fontSize: "1rem", color: "#656565" }} className='title'>{props?.title}</h2>
                    <button onClick={() => props?.closeModal()} style={{
                        padding: "1rem",
                        margin: "-1rem -1rem -1rem auto"
                    }} className="close border-0"><span aria-hidden="true">x</span>
                    </button>
                </div>
            </Modal.Header>
            <Modal.Body>
                <img
                    src={Img}
                    alt=""
                    className="mx-auto d-block"
                    width={120}

                />
                <p style={{ color: "#222", fontSize: "1.2rem", fontWeight: 600 }} className="text-center mt-4 mb-2"> This listing is locked!</p>
                <p style={{color: "#676767"}} className="title text-center">Unlock this listing to contact the buyer. <br /></p>
            </Modal.Body>
            <Modal.Footer>
                <div className='contentBorder formForEmail'>
                    <button
                        className='btn btn-devinsider save text-white'
                        onClick={() => props?.confirmUnlock()}
                    >
                        {props.isLoading ? <Loader/> : "Continue"}
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}


import { initialAcquisitionOverview } from "../../../../../defaults/acquisition-overview";
import { initialCompanyBasics } from "../../../../../defaults/company-basics";
import { initialFinancialDetails } from "../../../../../defaults/financial-details";
import { initialNecessaryInformations } from "../../../../../defaults/necessary-informations";
import { initialSubscriptionPlan } from "../../../../../defaults/subscription";
import { IAcquisitionOverview } from "../../../../../types/acquisition-overview";
import { ICompanyBasics } from "../../../../../types/company-basics";
import { IFinancialDetails } from "../../../../../types/financial-details";
import { INecessaryInformations } from "../../../../../types/necessary-informations";
import { ISubscription } from "../../../../../types/subscription";
import {
    SET_ACQUISITION_OVERVIEW,
    SET_COMPANY_BASICS,
    SET_FINANCIAL_DETAILS,
    SET_NECESSARY_INFORMATIONS,
    SET_SUBSCRIPTION
} from "./actions";

interface ICreationSellerState {
    subscription : ISubscription
    acquisition_overview : IAcquisitionOverview,
    necessary_informations : INecessaryInformations,
    company_basics : ICompanyBasics,
    financial_details : IFinancialDetails
}

export const initialState: ICreationSellerState = {
    subscription : initialSubscriptionPlan,
    acquisition_overview : initialAcquisitionOverview,
    necessary_informations : initialNecessaryInformations,
    company_basics : initialCompanyBasics,
    financial_details : initialFinancialDetails
};

export const creationResellerReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SET_SUBSCRIPTION:
            return {
                ...state,
                subscription: action.payload
            };
        case SET_ACQUISITION_OVERVIEW:
            return {
                ...state,
                acquisition_overview: action.payload,
            };
        case SET_NECESSARY_INFORMATIONS:
            return {
                ...state,
                necessary_informations: action.payload,
            };
        case SET_COMPANY_BASICS:
            return {
                ...state,
                company_basics: action.payload,
            };
        case SET_FINANCIAL_DETAILS:
            return {
                ...state,
                financial_details: action.payload,
            };
        default:
            return state;
    }
};



import { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { PaymentBrands } from '../../../components/payment/payment-card/payment-brands';
import { CardRegistrationInputWrapper } from "./card-registration-input-wrapper";
import { ConfirmPay } from "./confirm-pay";
import RegisteredCard from './registered-card';
import RemoveCard from './remove-card';
import usePaymentMethodHook from "./use-payment-method";

type IProps = {
  showModalPaymentInfo: boolean;
  setShowModalPaymentInfo: (value: boolean) => void;
}

const PaymentMethodInfo = (props: IProps) => {
  const {
    txtMessage,
    currentUserHasRegisteredCard,
    removeCard,
    showPopRemoveCard,
    setRemoveCard,
    loadingCardInformation,
    cardInformation,
    successUpdate,
    closePopRemoveCard,
    showRemoveCard,
    unpaidInvoice,
    setUnpaidInvoice,
    setPaymentMethodId,
    showConfirmPayment,
    setShowConfirmPayment,
    confirmPay,
    postNewCard,
    showButtonLoader,
    setShowButtonLoader
  } = usePaymentMethodHook(props.setShowModalPaymentInfo)
  
  useEffect(() => {
    if(!props?.showModalPaymentInfo) setRemoveCard(false);
  }, [props?.showModalPaymentInfo]);

  return (
    <>
      <div className='itemBilling'>
        <h2 className='title'>Payment information</h2>
        <div className='contentItem'>
          <div className='itemctt'>
            <b>{txtMessage.carteInfo}</b>
          </div>
          <div className='footerItem' style={{ fontSize: '14px' }}>
            <a
              onClick={
                () => props.setShowModalPaymentInfo(true)
              }
            >
              {txtMessage.addOrUpdate}
            </a>
          </div>
        </div>
      </div>
      {/* MODAL */}
      <Modal
        show={props.showModalPaymentInfo}
        className='createCompanypopup large forStep5custom'
        id="payment-modal"
        style={{display: showConfirmPayment ? 'none' : 'block'}}
      >
        <Modal.Header>
          <div className='headerpopupCompany noStep'>
            <h2 className='title'>Payment information</h2>
            <a className='close' onClick={() => props.setShowModalPaymentInfo(false)}>
              <span className='icon-close-pop'></span>
            </a>
          </div>
        </Modal.Header>
        <Modal.Body className=''>
          {currentUserHasRegisteredCard && (
            <RegisteredCard
              removeCard={removeCard}
              showPopRemoveCard={showPopRemoveCard}
              setRemoveCard={setRemoveCard}
              loadingCardInformation={loadingCardInformation}
              cardInformation={cardInformation}
            />
          )}

          {!currentUserHasRegisteredCard && (
            <div className='contentBorder forBillingCenter radius pt-3'>
              <div className='checkCard pl-3'>
                <b>Payment</b>
                <PaymentBrands />
              </div>

              <div className='name col-12'>
                <CardRegistrationInputWrapper
                    setShowModalPaymentInfo={props.setShowModalPaymentInfo}
                    setPaymentMethodId={setPaymentMethodId}
                    setShowConfirmPayment={setShowConfirmPayment}
                    setUnpaidInvoice={setUnpaidInvoice}
                    postNewCard={postNewCard}
                    setShowButtonLoader={setShowButtonLoader}
                    showButtonLoader={showButtonLoader}/>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
      <RemoveCard
        closePopRemoveCard={closePopRemoveCard}
        showRemoveCard={showRemoveCard}
        cardInformation={cardInformation}
        closePaymentInformationModal={() => props.setShowModalPaymentInfo(false)}
        successUpdate={successUpdate}
      />
      {unpaidInvoice &&
          <ConfirmPay
              show={showConfirmPayment}
              closeModal={() => setShowConfirmPayment(false)}
              confirm={() => {
                setShowConfirmPayment(false)
                confirmPay()
              }}
              invoice={unpaidInvoice}
          />
      }
    </>
  );
};

export default PaymentMethodInfo;

import ContactSupport from "../contact-support";
import "./billing.scss";
import BuyerPlanDetails from "./buyer/buyer-plan-details";
import PaymentInfo from "./payment-info";
import Receipts from "./receipts";
import SellerPlanDetails from "./seller/seller-plan-details";

interface PlansAndBillingProps {
    isUpgrade?: string
}

export default function PlansAndBilling(props: PlansAndBillingProps) {
    return (
        <div className="contentRightsettings">
            <h1 className="title">Plans & billing</h1>
            <div className="contentBilling">
                <BuyerPlanDetails isUpgrade={props?.isUpgrade} />
                <SellerPlanDetails />
                <PaymentInfo />
                <Receipts />
                <ContactSupport/>
            </div>
        </div>
    );
}

import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { urlsApi } from '../../api/urls/apiUrl';
import { useKeycloakHooks } from '../../hooks/keycloak';
import { FormContactSupport } from './type';

export default function UseContactSupportCtr() {
  const [showModalSuccess, setShowModalSuccess] = useState<boolean>(false);

  const [isShowContactSupport, setIsShowContactSupport] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    getValues,
  } = useForm<FormContactSupport>({
    mode: 'onSubmit',
  });
  const fileName: any = watch('fileAttachement');
  const [loaderContact, setloaderContact] = useState<boolean>(false);

  const { keycloak, tokenInfo } = useKeycloakHooks();

  /**
   * @param {T.FormContactSupport} p value of form
   * @returns promise of post form nd close modal
   */
  const submitFormContactSupport = async (p: FormContactSupport) => {
    setloaderContact(true);
    const formData = new FormData();
    formData.append('file', p?.fileAttachement ? p?.fileAttachement?.[0] : '');
    formData.append('lastName', String(p?.lastName));
    formData.append('firstName', String(p?.firstName));
    formData.append('email', String(p?.email));
    formData.append('topic', String(p?.topics));
    formData.append('subject', String(p?.subject));
    console.log(formData);
    let params = {
      firstName: p?.firstName,
      lastName: p?.lastName,
      email: p?.email,
      topic: p?.topics,
      subject: p?.subject,
      file: p?.fileAttachement ? p?.fileAttachement?.[0] : '',
    };

    fetch(process.env.REACT_APP_API_URL + urlsApi.billing_center.send_contact_support, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${keycloak.token}`, // Add token to headers
      },
    }).then((data) => {
        setloaderContact(false);
        setIsShowContactSupport(false);
        setShowModalSuccess(true);
        setValue('topics', '');
        setValue('firstName', tokenInfo?.firstname);
        setValue('lastName', tokenInfo?.lastname);
        setValue('subject', '');
        setValue('fileAttachement', undefined);
      })
      .catch((error) => {
        
      });
  };

  const removeFileUploaded = () => {
    setValue('fileAttachement', undefined);
  };

  return {
    isShowContactSupport,
    setIsShowContactSupport,
    register,
    handleSubmit,
    watch,
    setValue,
    errors,
    submit: submitFormContactSupport,
    getValues,
    fileName,
    loader: loaderContact,
    setloaderContact,
    removeFileUploaded,
    showModalSuccess,
    setShowModalSuccess
  };
}

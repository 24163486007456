/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import "./payment-status.scss"

type IProps = {
  title: string;
  invoiceNumber?: string;
  show: boolean;
  handleOk?: () => void;
  invoiceUrl?: string
};

const PaymentSuccess = (props: IProps) => {
  return (
    <Modal
      show={props.show}
      className='createCompanypopup medium forStep5custom'
    >
      <Modal.Header>
        <div className='headerpopupCompany noStep'>
          <h2 className='title'>{props.title}</h2>
          <a
            className='close'
            onClick={() => {
              props.handleOk && props.handleOk();
            }}
          >
            <span className='icon-close-pop'></span>
          </a>
        </div>
      </Modal.Header>
      <Modal.Body className=''>
        <div className='contentBorder forBillingCenter pt-3 pb-0 text-center'>
          <div className='payement_success'>
            <div className='ico'>
              <span className='icon-check-article green'></span>
            </div>
            <span className='txt mb-2 title'>Payment successful</span>
            <p className='pt-3'>
              Your invoice number is{' '}
              <span className='font-weight-bold invoke'>
                <a rel="noreferrer" href={props.invoiceUrl} target='_blank'>{props.invoiceNumber}</a>
              </span>
            </p>
            <p>Thank you for your purchase. A confirmation email has been sent.</p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className='contentBorder text-center'>
          <button
            className='btn btn-devinsider containerBtnBtn pl-5 pr-5'
            onClick={() => props.handleOk && props.handleOk()}
          >
            Continue
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PaymentSuccess;

import 'bootstrap/dist/css/bootstrap.min.css';
import ISO3166 from 'iso-3166-1-alpha-2';
import { useEffect, useRef, useState } from 'react';
import { Accordion, OverlayTrigger, Tooltip } from "react-bootstrap";
import Skeleton from 'react-loading-skeleton';
import { setLogger, useMutation, useQueryClient } from 'react-query';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import {
  UseDeleteBuyerListing,
  UseUnlockBuyer,
  useGetDetailBuyerMatching,
  useGetListingByIdBuyer,
  useGetMyBuyerListing,
  useGetUserSubscription,
  usePostBuyerListingImage,
  usePublishWithPaymentListing,
  useUnpublishListing,
  useUpdateBuyerListing,
  UseAddToFavorite
} from '../../../../api/buyer';

import { UseCheckValidSubscription } from '../../../../api/seller';

import { useGetProductPrice } from '../../../../api/payment';
import { UseGetMySellerListing } from "../../../../api/seller";
import { useGetStaticParams } from "../../../../api/staticParams";
import { urlsApi } from "../../../../api/urls/apiUrl";
import DeleteSellerListing from '../../../../components/modals/delete-seller-listing/delete-seller-listing';
import Unlock from '../../../../components/modals/unlock/unlock';
import Unpublish from '../../../../components/modals/unpublish-buyer/unpublish';
import PaymentPopup from '../../../../components/payment/payment-popup';
import FreePublishFailed from '../../../../components/publish/free-publish-failed';
import FreePublishSuccess from '../../../../components/publish/free-publish-success';
import { useKeycloakHooks } from '../../../../hooks/keycloak';
import BuyerSecondMenu from '../../../../layouts/second-headers/buyer';
import SellerSecondMenu from '../../../../layouts/second-headers/seller';
import { removeCachePaymentMethod, removeCacheProduct, removeCacheReceipt } from '../../../../query/removeCache';
import { ReactComponent as Loader } from '../../../../resources/images/Rolling.svg';
import DollarImg from '../../../../resources/images/dollar.svg';
import acquisitionImg from '../../../../resources/images/handshake.svg';
import Img from '../../../../resources/images/img-preview.svg';
import GoalImg from '../../../../resources/images/target.svg';
import { scrollToTop } from '../../../../utils/dom';
import { getCurrencySymbol, redirectToUrl } from '../../../../utils/outils';
import { getRoleDisplayName } from '../../../../utils/role-mapping';
import { LoadingAllPage } from '../../../../widget/loader';
import NotFound from '../../../not-found/not-found';
import '../../buyer.scss';
import AcquisitionTarget from '../../edit/AcquisitionTarget';
import CompanyOverview from '../../edit/CompanyOverview';
import MAContact from '../../edit/MAContact';
import MAIntention from '../../edit/MAIntention';
import CompanyEdit from '../../edit/companyEdit';

interface FormattedData {
  geographiquebuyer: { country: any; };
  ecosystem: any;
  have_technology: boolean;
}

type DataParamType = {
  idUser?: string | undefined;
  [key: string]: string | number | boolean | string[] | undefined;
};

const BuyerListingDetails = ({ setIsEdit }: { setIsEdit: (el: boolean) => void }) => {
  const queryClient = useQueryClient();

  let { id } = useParams();
  const navigate = useNavigate();

  const { keycloak, tokenInfo, initialized } = useKeycloakHooks();
  const goToHomePage = () => {
    navigate("/");
  };
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isReEditing, setIsReEditing] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState(false);

  const toggleEditingMode = (value: boolean) => {
    scrollToTop();
    setIsEditing(value);
    setIsEdit(value);
  };

  const [currency, setCurrency] = useState<string | undefined>();
  const [amount, setAmount] = useState<string | undefined>();

  const {
    data: price,
    mutateAsync: getProductPriceMut
  } = useMutation({ mutationFn: useGetProductPrice })

  const fetchPrice = async (lookupkey: string) => {
    try {
      if (lookupkey) {
        const response = await getProductPriceMut(lookupkey ?? '');

        if (response.data.message === 'success') {
          const productPrices = response.data.data.prices;
          const curr = productPrices.usd === undefined ? 'eur' : 'usd';
          setCurrency(curr);
          setAmount(response.data.data.prices[curr] || 0);
        }
      }
    } catch (error) {

    }
  };


  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  useEffect(() => {
    if (initialized && !keycloak.authenticated) goToHomePage();

  }, [keycloak.authenticated, keycloak.token, initialized, goToHomePage]);

  const [isMyDetailListing, setIsMyDetailListing] = useState<boolean | undefined>();

  const [enabledGetBuyerListing, setEnabledGetBuyerListing] = useState(true);
  const [enabledDetailMatching, setEnabledDetailMatching] = useState(false);
  const { data, isLoading, refetch } = useGetListingByIdBuyer(id, enabledGetBuyerListing);

  const [enableCheckSubscription, setEnableCheckSubscription] = useState<boolean>(true);
  const { data: checkSubscription, refetch: refetchCheck } = useGetUserSubscription(enableCheckSubscription);

  const [enableCheckSubscriptionSeller, setEnableCheckSubscriptionSeller] = useState<boolean>(true);
  const { data: checkSubscriptionSeller } = UseCheckValidSubscription(enableCheckSubscriptionSeller);

  const { data: detailMatching, refetch: refetchDetailMatching, isLoading: detailMatchingLoading, isFetched: dataMatchingFetched } = useGetDetailBuyerMatching(data?.idUser, enabledDetailMatching);
  const [showPaymentModalForUnlock, setShowPaymentModalForUnlock] = useState<boolean>(false);

  const [enabledMySellerListing, setEnabledMySellerListing] = useState(false);
  const { data: sellerListing, isLoading: mySellerListingsLoading, isFetched: isMySellerListingFetched } = UseGetMySellerListing(enabledMySellerListing);

  const [isPaid, setIsPaid] = useState<boolean>(false);

  useEffect(() => {
    if (checkSubscription && checkSubscription.data) {
      setEnableCheckSubscription(false);
    }
  }, [checkSubscription]);


  useEffect(() => {
    setIsPaid(checkSubscription?.data?.valid_subscription);
  }, [isPaid]);
  const [activeContinent, setActiveContinent] = useState<number | null>(null);


  const [enableGetHorizontals, setEnableGetHorizontals] = useState(true);
  const { data: horizontals, isFetched: isHorizontalsFetched } = useGetStaticParams('HORIZONTALS', enableGetHorizontals);
  const [enableEcosystems, setEnableEcosystems] = useState(true);
  const { data: ecosystemsData, isFetched: isEcosystemsFetched } = useGetStaticParams('TECHNO_ECOSYSTEM', enableEcosystems);

  const [show, setShow] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showUnpublishModal, setShowUnpublishModal] = useState(false);
  const [showUnlockModal, setShowUnlockModal] = useState(false);
  const [showCompanyeditModal, setShowCompanyModal] = useState(false);
  const [showMAcontactedit, setMAcontactModal] = useState(false);
  const [showMAIntentionedit, setMAIntentionModal] = useState(false);
  const [showCompanyOverviewedit, setCompanyOverviewModal] = useState(false);
  const [showAcquisitionModal, setAcquisitioModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState<boolean>(false);
  const [savedCompanyImage, setSavedCompanyImage] = useState<string>('');
  const [savedCompanyName, setSavedCompanyName] = useState<string>('');
  const [savedCompanyUrl, setSavedCompanyUrl] = useState<string>('');

  const [savedIntentions, setSavedIntentions] = useState<string>('');
  const [savedChallenge, setSavedChallenge] = useState<string>('');

  const [savedBrief, setSavedBrief] = useState<string>('');
  const [savedIsvBased, setSavedIsvBased] = useState<boolean>(false);
  const [savedYear, setSavedYear] = useState<number>(0);
  const [savedEmploye, setSavedEmploye] = useState<number>(0);
  const [savedAcquisition, setSavedAcquisition] = useState<string>('');
  const [lookUpKey, setLookUpKey] = useState<string>('');
  const [savedMainAcquisition, setSavedMainAcquisition] = useState<string>('');
  const [savedAcquisitionBudget, setSavedAcquisitionBudget] = useState<number>(0);
  const [savedTargetedIndustry, setSavedTargetedIndustry] = useState<string[]>([]);
  const [savedSoftwareSolution, setSavedSoftwareSolution] = useState<string[]>([]);
  const [savedFeaturedSolution, setSavedFeaturedSolution] = useState<string[]>([]);
  const [savedGeographicSalesFootprint, setSavedGeographicSalesFootprint] = useState<string[]>([]);

  const [savedFirstname, setSavedFirstname] = useState<string>('');
  const [savedLastname, setSavedLastname] = useState<string>('');
  const [savedLinkedinurl, setSavedLinkedinurl] = useState<string>('');
  const [savedEmail, setSavedEmail] = useState<string>('');
  const [savedPhonenumber, setSavedPhonenumber] = useState<string>('');

  const [savedPreferedISV, setSavedPreferedISV] = useState<string[]>([]);
  const [savedPreferedHeadCarter, setSavedPreferedHeadCarter] = useState<string[]>([]);
  const [savedPreferedTargetedIndustry, setSavedPreferedTargetedIndustry] = useState<string[]>([]);
  const [savedPreferedSoftwareSolution, setSavedPreferedSoftwareSolution] = useState<string[]>([]);
  const [savedPreferedFeaturedSolution, setSavedPreferedFeaturedSolution] = useState<string[]>([]);

  const mutUpdate = useMutation({ mutationFn: useUpdateBuyerListing, retry: 0 });
  const mutUploadCompanyImage = useMutation({ mutationFn: usePostBuyerListingImage, retry: 0 });

  const [showPublishSuccess, setShowPublishSuccess] = useState(false);
  const [showPublishFailed, setShowPublishFailed] = useState(false);

  const [enabledGetMyBuyerListing, setEnabledGetMyBuyerListing] = useState(true);
  const { data: myBuyerListing, isFetched: isMyBuyerListingFetched, refetch: refetchMyBuyerListing } = useGetMyBuyerListing(enabledGetMyBuyerListing);


  const [activeSection, setActiveSection] = useState('M&A intentions');

  const maIntentionRef = useRef(null);
  const companyOverviewRef = useRef(null);
  const acquisitionRef = useRef(null);
  const maContactRef = useRef(null);

  const handleClickMenu = (ref: any, section: string) => {
    const elementPosition = ref.current.getBoundingClientRect().top + window.pageYOffset;
    const adjustment = 130;
    window.scrollTo({
      top: elementPosition - adjustment,
      behavior: 'smooth'
    });
    setActiveSection(section);
  };

  const formatData = (data: { geographiquebuyer: { country: any; }; ecosystem: any; }): FormattedData | null => {
    if (data && data.geographiquebuyer && data.geographiquebuyer.country) {
      const newData = { ...data } as FormattedData;
      newData.geographiquebuyer = newData.geographiquebuyer.country;
      return newData;
    } else {
      return null;
    }
  };
  const mutAddToFavorite = useMutation({
    mutationFn: UseAddToFavorite,
    onSuccess: (data) => {
      if (data.status === 200) {
        refetch();
        refetchDetailMatching();
      } else {
        refetch();
        refetchDetailMatching();
      }
    }
  })

  const toggleFavorite = async (itemId: any): Promise<any> => {
    const dataParam = {
      idBuyer: itemId,
    }
    const response = await mutAddToFavorite.mutateAsync(dataParam);
    return response;
  }

  const [updatedData, setUpdatedData] = useState(() => formatData(data));


  useEffect(() => {
    if (data && tokenInfo?.user_uuid) {
      setEnabledGetBuyerListing(false);
      if (data?.idUser === tokenInfo?.user_uuid) {
        setIsMyDetailListing(true);
        setUpdatedData(formatData(data));
      } else {
        setEnabledDetailMatching(true);
        setIsMyDetailListing(false);
      }
    }

    console.log("object", sellerListing);
    console.log("object2", isMyDetailListing);

    if (isMyDetailListing === false && ((sellerListing?.seller && (sellerListing?.seller?.isremoved === true || sellerListing?.seller?.ispublished === false) || sellerListing?.seller === null))) {
      goToHomePage();
    }
  }, [tokenInfo?.user_uuid, data, id, isMyDetailListing, sellerListing, goToHomePage])

  useEffect(() => {
    if (isMySellerListingFetched) {
      setEnabledMySellerListing(false);
    }
  }, [isMySellerListingFetched])

  useEffect(() => {
    if (checkSubscriptionSeller && checkSubscriptionSeller.data && checkSubscriptionSeller.data.subscription && data) {
      const key = (checkSubscriptionSeller.data.subscription.type.type).toUpperCase() + "_SELLER_UNLOCK_" + (data?.userAccount?.isPremium ? 'MOTIVATED' : 'OPPORTUNISTIC');
      setLookUpKey(key);
      fetchPrice(key + '_BUYER');
      setEnableCheckSubscriptionSeller(false);
    }
  }, [checkSubscription, data]);


  useEffect(() => {
    if (isHorizontalsFetched) setEnableGetHorizontals(false);
  }, [isHorizontalsFetched]);
  useEffect(() => {
    if (isEcosystemsFetched) setEnableEcosystems(false);
  }, [isEcosystemsFetched]);

  useEffect(() => {
    if (detailMatching) {
      setEnabledDetailMatching(false);
    }
  }, [detailMatching]);

  const [formState, setFormState] = useState({
    ...myBuyerListing
  });

  const mutpublishWithpaiement = useMutation({
    mutationFn: usePublishWithPaymentListing,
    onSuccess: (data) => {
      refetchData();
      setShowUnpublishModal(false);
      removeCacheReceipt(queryClient);
    }
  })

  const mutUnlock = useMutation({
    mutationFn: UseUnlockBuyer,
    onSuccess: (data) => {
      refetchData();
      refetchDetailMatching();
      setShowUnpublishModal(false);
      removeCacheReceipt(queryClient);
    }
  })

  const [activeId, setActiveId] = useState<any>("null");
  function toggleActive(id: any) {
    if (activeId === id) {
      setActiveId("null");
    } else {
      setActiveId(id);
    }
  }

  const mutDelete = useMutation({
    mutationFn: UseDeleteBuyerListing,
    onSuccess: (data) => {
      refetchData();
      setShowDeleteModal(!showDeleteModal);
    }
  })


  const mutUnpublish = useMutation({
    mutationFn: useUnpublishListing,
    onSuccess: (data) => {
      refetchData();
      setShowUnpublishModal(false);
    }
  })

  const doneEditing = () => {
    scrollToTop();
    toggleEditingMode(false);
    setShow(false);
    refetch();
    refetchMyBuyerListing();
  }

  const updatebuyer = async (dataParams: any) => {
    const response = await mutUpdate.mutateAsync({ id: data.id, ...dataParams });
    refetch();
    return response;
  }




  const refetchData = () => {
    refetch();
    refetchMyBuyerListing();
    setShow(false);
  }

  const [isPublishLoading, setIsPublishLoading] = useState<boolean>(false);

  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);



  const goToCompanyUrl = () => {
    data && data.company_url && redirectToUrl(data?.company_url);
  };

  const handleStatusOk = () => {
    setShowPaymentModal(false);
  };

  const deletebuyer = async () => {
    setIsDeleteLoading(true);
    await mutDelete.mutateAsync({ id: data?.id });
    refetchData();
    setIsDeleteLoading(false);
  }

  const goToPreview = () => {
    if (data?.isremoved) return;
    // navigate('/buyer/listing/preview');
    openNewTab('/buyer/listing/preview');
  };

  const publishbuyerwithpaiement = () => {
    setShowPaymentModal(true);
  }

  const publishbuyerwithoutpaiement = async () => {
    setIsPublishLoading(true);
    const response = await mutUnpublish.mutateAsync({ id: data?.id });

    if (data?.ispublished == false) {
      setShowPublishSuccess(true);
    }
    else {
      setShowUnpublishModal(false);
    }

    setIsPublishLoading(false);
  }



  const goToDelete = () => {
    if (data?.isremoved) {
      return;
    } else {
      setShowDeleteModal(!showDeleteModal);
    }
  }

  const goToUnpublish = () => {
    if (data?.isremoved) return;

    setShow(false);
    setShowUnpublishModal(!showUnpublishModal);
  }

  const goToAcquisition = () => {
    setAcquisitioModal(true);
  }

  const goToCompanyEdit = () => {
    setShowCompanyModal(true);
  }

  const goToMacontact = () => {
    setMAcontactModal(true);
  }

  const goToMAIntention = () => {
    setMAIntentionModal(true);
  }

  const goToCompanyOverView = () => {
    setCompanyOverviewModal(true);
  }

  const openCancelModal = () => {
    setShowCancelModal(true);
  };

  function checkForDeletedData(): boolean {

    if (
      data?.prefered_hq_location?.find((el: any) => el === "manda_deleted_manda") ||
      data?.lifecycle_stage?.find((el: any) => el === "manda_deleted_manda") ||
      data?.preferred_industries?.find((el: any) => el === "manda_deleted_manda") ||
      data?.preferred_software?.find((el: any) => el === "manda_deleted_manda") ||
      data?.preferred_featured_software?.find((el: any) => el === "manda_deleted_manda") ||
      data?.targeted_industry?.find((el: any) => el === "manda_deleted_manda") ||
      data?.software_solution?.find((el: any) => el === "manda_deleted_manda") ||
      data?.featured_solution?.find((el: any) => el === "manda_deleted_manda") ||
      data?.geographiquebuyer?.country.find((el: any) => el === "manda_deleted_manda") ||
      data?.ecosystem?.find((el: any) => el === "manda_deleted_manda")
    ) {
      return false;
    }


    if (
      data?.ma_intention === 'manda_deleted_manda' ||
      data?.challenge === 'manda_deleted_manda' ||
      data?.company_reason === 'manda_deleted_manda' ||
      data?.location === 'manda_deleted_manda' ||
      data?.founded === 'manda_deleted_manda' ||
      data?.company_size === 'manda_deleted_manda' ||
      data?.recent_acquisition === 'manda_deleted_manda' ||
      data?.main_acquisition_goal === 'manda_deleted_manda' ||
      data?.ma_budget_indication === 'manda_deleted_manda' ||
      data?.firstname === 'manda_deleted_manda' ||
      data?.lastname === 'manda_deleted_manda' ||
      data?.linkedin_url === 'manda_deleted_manda' ||
      data?.email === 'manda_deleted_manda' ||
      data?.phone_number === 'manda_deleted_manda'
    ) {
      return false;
    }

    return true;
  }

  const publish = () => {

    if (checkForDeletedData() == false) {
      setShowPublishFailed(true);
    } else {
      if (data?.isremoved) return;
      setShow(false);
      if (checkSubscription?.data?.valid_subscription == true) {
        return publishbuyerwithoutpaiement();
      }
      else if (checkSubscription?.data?.valid_subscription == false) {
        return publishbuyerwithpaiement();
      }
    }
  }

  const unlock = () => {
    setShowPaymentModalForUnlock(true);
  }


  const isPremium = data?.isPremium;

  const handlePublishListing = async (paymentId: string | null, savePaymentMethod?: boolean): Promise<any> => {

    const dataParam = {
      paymentId,
      savePaymentMethod,
      id: id,
      planType: isPremium ? "motivated" : "opportunistic"
    }

    const response = await mutpublishWithpaiement.mutateAsync(dataParam);

    return response;
  }

  const handleUnlockListing = async (paymentId: string | null, savePaymentMethod?: boolean): Promise<any> => {

    const dataParam = {
      paymentId,
      savePaymentMethod,
      idBuyer: detailMatching?.data?.matching?.id,
      planType: lookUpKey + '_BUYER'
    }

    const response = await mutUnlock.mutateAsync(dataParam);

    return response;
  }

  const goToUnlock = () => {
    if (detailMatching?.data?.matching?.isUnlock) {
      return;
    }
    else {
      setShowUnlockModal(true);
    }
  }

  const paymentMethodCallBack = async (
    paymentMethodId: string | null,
    setLoading: (l: boolean) => void,
    savePaymentMethod?: boolean,
    setShowPaymentStatus?: (
      show: boolean,
      data: {
        status: 'success' | 'error';
        invoiceNumber?: string;
        invoiceUrl?: string;
      }
    ) => void
  ) => {
    setLoading(true);

    try {
      const data = await handlePublishListing(paymentMethodId, savePaymentMethod);

      if (data?.status == 200) {
        setShowPaymentStatus && setShowPaymentStatus(true, {
          status: 'success',
          invoiceNumber: data?.data?.data?.invoice_number,
          invoiceUrl: data.invoice_url
        });
      }
    } catch (error) {
      setShowPaymentStatus && setShowPaymentStatus(true, {
        status: 'error',
      })
    } finally {
      removeCacheProduct(queryClient);
      removeCacheReceipt(queryClient);
      removeCachePaymentMethod(queryClient);

      setLoading(false);
    }
  };


  const paymentMethodCallBackForUnlock = async (
    paymentMethodId: string | null,
    setLoading: (l: boolean) => void,
    savePaymentMethod?: boolean,
    setShowPaymentStatus?: (
      show: boolean,
      data: {
        status: 'success' | 'error';
        invoiceNumber?: string;
        invoiceUrl?: string;
      }
    ) => void
  ) => {
    setLoading(true);

    try {
      const data = await handleUnlockListing(paymentMethodId, savePaymentMethod);

      if (data?.status == 200) {
        setShowPaymentStatus && setShowPaymentStatus(true, {
          status: 'success',
          invoiceNumber: data?.data?.details?.data?.invoice_number,
          invoiceUrl: data?.data?.details?.data?.invoice_url
        });
        setShowUnlockModal(false);
      }
    } catch (error) {
      setShowPaymentStatus && setShowPaymentStatus(true, {
        status: 'error',
      })
      setShowUnlockModal(false);
    } finally {
      removeCacheProduct(queryClient);
      setLoading(false);
    }
  }

  const prefferedSoft = data?.preferred_software || [];
  const prefferedFeatured = data?.preferred_featured_software || [];
  const allPreffered = [...prefferedFeatured, ...prefferedSoft];

  const geographicData = data && data?.geographiquebuyer?.country ? data?.geographiquebuyer?.country?.reduce((acc: any, current: any) => {
    if (!current) {
      return acc;
    }
    const continent = current?.continent;
    const countryInfo = {
      ISO2: current?.iso2,
      Name: current?.country,
    };

    if (!acc[continent]) {
      acc[continent] = {
        Continent: continent,
        Countries: [countryInfo],
      };
    } else {
      acc[continent].Countries.push(countryInfo);
    }

    return acc;

  }, {}) : {};


  if (isNaN(Number(id))) {
    return <NotFound />;
  }

  const handleGoToFavorite = () => {
    if (keycloak?.authenticated) { }
    else keycloak?.login();
  }



  const dataUpdateParam: DataParamType = {
    idUser: tokenInfo?.sub,
  };

  const addNonNullValues = (dataUpdateParam: DataParamType, key: string, value: string | number | boolean | string[] | null | undefined) => {
    if (value !== null && value !== undefined && value !== '' && !(typeof value === 'number' && value === 0) && !(typeof value === 'boolean' && value === false || typeof value === 'boolean' && value === true)) {
      dataUpdateParam[key] = value;
    }
  };


  const transformedDataUpdateParam: any = {};
  for (const key in dataUpdateParam) {
    if (dataUpdateParam[key] !== undefined) {
      transformedDataUpdateParam[key] = dataUpdateParam[key];
    }
  }



  let featuredData: object[] = [];
  let solutionData: object[] = [];

  if (horizontals && horizontals.data && horizontals.data.length > 0) {
    horizontals.data.forEach((item: any) => {
      if (item.attributes && item.attributes.Featured === false) {
        solutionData.push(item.attributes.Label);
      }
      else {
        featuredData.push(item.attributes.Label);
      }
    });
  }


  //Acquisition Target Edit
  const saveDataAcquisition = async (formData: any) => {
    const { lifecycle_stage, prefered_hq_location, preferred_industries, preferred_software, preferred_featured_software, ecosystem, opportunity } = formData;
    let lifecycleStages: string[];

    if (Array.isArray(lifecycle_stage) && lifecycle_stage.length > 0 && typeof lifecycle_stage[0] === 'object' && 'value' in lifecycle_stage[0] && 'label' in lifecycle_stage[0]) {
      const labelsArray = lifecycle_stage.map((stage: { label: any; }) => stage.label);
      lifecycleStages = labelsArray;
    } else {
      lifecycleStages = lifecycle_stage;
    }


    let specific_tecnhology: object[] = [];
    if (ecosystemsData && ecosystemsData.data && ecosystemsData.data.length > 0) {
      ecosystemsData.data.forEach((item: any) => {
        if (item.attributes) {
          specific_tecnhology.push(item.attributes.Label);
        }
      });
    }

    let technology_ecosystem = [];


    if (Array.isArray(ecosystem) && ecosystem.every(item => typeof item === 'object' && 'label' in item)) {
      technology_ecosystem = ecosystem.map((item: { label: any }) => item.label) || [];
    } else {
      technology_ecosystem = ecosystem || [];
    }

    let specific_technology_ecosystem = technology_ecosystem.filter((value: object) => !specific_tecnhology.includes(value));
    const filteredSoftwareSolutions = preferred_software.filter((solution: string) =>
      solutionData.map(obj => JSON.stringify(obj)).indexOf(JSON.stringify(solution)) !== -1
    );

    const filteredFeaturedSolutions = preferred_featured_software.filter((solution: string) =>
      featuredData.map(obj => JSON.stringify(obj)).indexOf(JSON.stringify(solution)) !== -1
    );


    setSavedPreferedISV(lifecycleStages);
    setSavedPreferedHeadCarter(prefered_hq_location);
    setSavedPreferedTargetedIndustry(preferred_industries);
    setSavedPreferedSoftwareSolution(filteredSoftwareSolutions);
    setSavedPreferedFeaturedSolution(filteredFeaturedSolutions);

    const dataUpdateParam: DataParamType = {
      ...transformedDataUpdateParam,
      idUser: tokenInfo?.sub,
    };

    addNonNullValues(dataUpdateParam, 'ecosystem', technology_ecosystem);


    if (specific_technology_ecosystem.length > 0) {
      addNonNullValues(dataUpdateParam, 'userSpecificEcosystem', specific_technology_ecosystem);
    }

    addNonNullValues(dataUpdateParam, 'lifecycle_stage', lifecycleStages);
    addNonNullValues(dataUpdateParam, 'prefered_hq_location', prefered_hq_location);
    addNonNullValues(dataUpdateParam, 'preferred_industries', preferred_industries);
    addNonNullValues(dataUpdateParam, 'preferred_software', filteredSoftwareSolutions);
    addNonNullValues(dataUpdateParam, 'preferred_featured_software', filteredFeaturedSolutions);
    addNonNullValues(dataUpdateParam, 'opportunity', opportunity);


    try {
      await updatebuyer(dataUpdateParam);
      setIsUpdating(false);
      setIsReEditing(true);
    } catch (error) {
    }
    refetch();
    refetchMyBuyerListing();
    setAcquisitioModal(!showAcquisitionModal);
  };





  //Company Edit
  const saveDataCompany = async (formData: any) => {
    const { companyimage, company_name, company_url } = formData;

    setSavedCompanyImage(companyimage);
    setSavedCompanyName(company_name);
    setSavedCompanyUrl(company_url);

    const dataUpdateParam: DataParamType = {
      ...transformedDataUpdateParam,
      idUser: tokenInfo?.sub,
      companyImage: companyimage
    };

    const companyNameToSend = (company_name !== '' && company_name !== undefined && company_name !== null) ? company_name : "user_anonyma";
    const companyWebsiteToSend = (company_url !== '' && company_url !== undefined && company_url !== undefined) ? company_url : "web_url_anonyma";



    addNonNullValues(dataUpdateParam, 'company_name', companyNameToSend);
    addNonNullValues(dataUpdateParam, 'company_url', companyWebsiteToSend);

    try {
      await updatebuyer(dataUpdateParam);
      setIsUpdating(false);
      setIsReEditing(true);
    } catch (error) {
    }
    refetch();
    refetchMyBuyerListing();
    setShowCompanyModal(!showCompanyeditModal);
  };




  //Company Overview Edit
  const saveDataOverview = async (formData: any) => {
    const {
      company_reason,
      location,
      founded,
      company_size,
      recent_acquisition,
      main_acquisition_goal,
      ma_budget_indication,
      targeted_industry,
      software_solution,
      featured_solution,
      geographiquebuyer
    } = formData;


    // const buyerListingData = myBuyerListing && myBuyerListing.geographiquebuyer?.country ? myBuyerListing.geographiquebuyer?.country : [];
    let buyerListingData = [];

    if (Array.isArray(geographiquebuyer) && geographiquebuyer.length > 0) {
      for (let i = 0; i < geographiquebuyer.length; i++) {
        const item = geographiquebuyer[i];
        if (item.hasOwnProperty('region') && item.hasOwnProperty('countries')) {
          buyerListingData.push(item.countries);
        } else {
          buyerListingData.push(item);
        }
      }
    }

    const filteredSoftwareSolutions = software_solution.filter((solution: string) =>
      solutionData.map(obj => JSON.stringify(obj)).indexOf(JSON.stringify(solution)) !== -1
    );

    const filteredFeaturedSolutions = featured_solution.filter((solution: string) =>
      featuredData.map(obj => JSON.stringify(obj)).indexOf(JSON.stringify(solution)) !== -1
    );

    setSavedBrief(company_reason);
    setSavedIsvBased(location);
    setSavedYear(founded);
    setSavedEmploye(company_size);
    setSavedAcquisition(recent_acquisition);
    setSavedMainAcquisition(main_acquisition_goal);
    setSavedAcquisitionBudget(ma_budget_indication);
    setSavedTargetedIndustry(targeted_industry);
    setSavedSoftwareSolution(filteredSoftwareSolutions);
    setSavedFeaturedSolution(filteredFeaturedSolutions);
    setSavedGeographicSalesFootprint(geographiquebuyer);

    const dataUpdateParam: DataParamType = {
      ...transformedDataUpdateParam,
      idUser: tokenInfo?.sub,
      ma_budget_indication: ma_budget_indication
    };

    addNonNullValues(dataUpdateParam, 'company_reason', company_reason);
    addNonNullValues(dataUpdateParam, 'location', location);
    addNonNullValues(dataUpdateParam, 'founded', founded);
    addNonNullValues(dataUpdateParam, 'company_size', company_size);
    addNonNullValues(dataUpdateParam, 'recent_acquisition', recent_acquisition);
    addNonNullValues(dataUpdateParam, 'main_acquisition_goal', main_acquisition_goal);
    addNonNullValues(dataUpdateParam, 'targeted_industry', targeted_industry);
    addNonNullValues(dataUpdateParam, 'software_solution', filteredSoftwareSolutions);
    addNonNullValues(dataUpdateParam, 'featured_solution', filteredFeaturedSolutions);
    addNonNullValues(dataUpdateParam, 'country', buyerListingData);

    try {
      await updatebuyer(dataUpdateParam);
      setIsUpdating(false);
      setIsReEditing(true);
    } catch (error) {
    }
    refetch();
    refetchMyBuyerListing();
    setCompanyOverviewModal(!showCompanyOverviewedit);
  };


  //MA Contact Edit
  const saveDataMAContact = async (formData: any) => {

    const {
      email,
      firstname,
      lastname,
      linkedin_url,
      PhoneNumber,
      phone_number,
      DialCode,
    } = formData;

    const phoneNumber = (phone_number !== undefined && DialCode !== undefined) ? DialCode + phone_number : phone_number;

    setSavedPhonenumber(phoneNumber);
    setSavedFirstname(firstname);
    setSavedLastname(lastname);
    setSavedLinkedinurl(linkedin_url);
    setSavedEmail(email);

    const dataUpdateParam: DataParamType = {
      ...transformedDataUpdateParam,
      idUser: tokenInfo?.sub
    };

    addNonNullValues(dataUpdateParam, 'email', email);
    addNonNullValues(dataUpdateParam, 'firstname', firstname);
    addNonNullValues(dataUpdateParam, 'lastname', lastname);
    addNonNullValues(dataUpdateParam, 'linkedin_url', linkedin_url);
    addNonNullValues(dataUpdateParam, 'phone_number', phoneNumber);

    try {
      await updatebuyer(dataUpdateParam);
      setIsUpdating(false);
      setIsReEditing(true);
    } catch (error) {
    }
    refetch();
    refetchMyBuyerListing();
    setMAcontactModal(!showMAcontactedit);
  };


  //MA Interest Edit
  const saveDataMAInterest = async (formData: any) => {
    const { ma_intention, challenge } = formData;

    setSavedIntentions(ma_intention);
    setSavedChallenge(challenge);

    const dataUpdateParam: DataParamType = {
      ...transformedDataUpdateParam,
      idUser: tokenInfo?.sub
    };

    addNonNullValues(dataUpdateParam, 'ma_intention', ma_intention);
    addNonNullValues(dataUpdateParam, 'challenge', challenge);

    try {
      await updatebuyer(dataUpdateParam);
      setIsUpdating(false);
      setIsReEditing(true);
    } catch (error) {
    }
    refetch();
    refetchMyBuyerListing();
    setMAIntentionModal(!showMAIntentionedit);
  };

  const openNewTab = (url: string) => {
    const fullUrl = window.location.origin + url;
    const newTab = window.open(fullUrl, '_blank');
    if (newTab) {
      newTab.focus();
    } else {
      navigate(url);
    }
  };

  const goToMessaging = () => {
    const messagingUrl = `${process.env.REACT_APP_MESSAGING_URL}/${data.idUser}?type=s2b`;
    window.open(messagingUrl, '_blank')
  }

  return (
    <>
      {(isLoading || !initialized || !data || detailMatchingLoading) && <LoadingAllPage />}
      {(!isLoading && initialized && (data || dataMatchingFetched)) && (
        <div id="sellerDetails" className={`findIsvcontainer programContainer sellerContainer my-listing ${isEditing ? "overlay" : ""}`}>
          {
            isMyDetailListing ? (
              <BuyerSecondMenu
                showSearch={false}
                handleGoToFavorite={handleGoToFavorite}
              />
            ) : (
              <SellerSecondMenu
                showSearch={false}
                handleGoToFavorite={handleGoToFavorite}
              />
            )
          }

          <div className='filterFindIsv leftTop hScroll'>
            <div className='contentFilter'>
              <div className='filter-container'>
                <div className='matchFilter'>
                  <ul className='align-items-start'>
                    <li className="edit-icon" key={"match"}>
                      {
                        data?.isremoved == false && data?.companyimage != "manda_deleted_manda" ? (
                          <>
                            {
                              data?.companyimage !== null && data?.companyimage !== undefined && data?.companyimage !== "" ? (
                                <img src={process.env.REACT_APP_API_URL + urlsApi.upload.get_upload_image + '/' + data.companyimage}
                                  width={60}
                                  height={60}
                                  className='unpublished-logo'
                                  alt="Company Logo" />
                              ) : (
                                <div style={{ marginRight: "6px" }}>
                                  <div className="logo">
                                    <span className='icon-building1 ico'></span>
                                  </div>
                                  {
                                    isMyDetailListing == false && detailMatching && detailMatching?.data?.matching && (
                                      <div className='match'>
                                        <span>match</span>
                                        <span>{detailMatching?.data?.matching?.matching < 60 ? '<60' : detailMatching?.data?.matching?.matching}%</span>
                                      </div>
                                    )
                                  }
                                </div>
                              )
                            }
                          </>
                        ) : (
                          <>
                            <span className="txt"><Skeleton count={1.5} /></span>
                            <br />
                          </>
                        )
                      }

                      <>
                        {isEditing && (
                          <>
                            <a
                              className="editItem"
                              onClick={() => goToCompanyEdit()}
                            >
                              Edit
                              <span className="icon-edit-full"></span>
                            </a>
                          </>
                        )}
                      </>

                    </li>

                    <ul className="flex-column mt-0">
                      {
                        data?.isremoved == false ? (
                          <>
                            <li style={{ top: "-4px" }} className="title d-flex lh-sm position-relative" key={'isPremium'}>
                              {data?.userAccount.isPremium === true ? `Motivated buyer` : `Opportunistic buyer`}
                              {
                                isMyDetailListing == false && (
                                  <li>
                                    <span style={{ marginLeft: "0.5rem", color: "#ccc" }} className={`icon-star-${detailMatching?.data?.matching?.isFavorite ? 'on fs-4' : 'off fs-4'}`} onClick={() => toggleFavorite(detailMatching?.data?.matching?.id)}>
                                    </span></li>
                                )
                              }
                            </li>
                            <li className="company-name d-flex" key={'company_name'}>
                              {data?.company_name !== 'user_anonyma' && data?.company_name && data?.company_name != "manda_deleted_manda" && (
                                <li className="" key={'company_url'}>
                                  {data?.company_name}
                                </li>
                              )}

                              {!isMyDetailListing && detailMatching && (
                                <span style={{ color: "#ff6600", marginLeft: "0.3rem", fontSize: "13px" }}>
                                  <svg style={{ marginRight: "0.1rem" }} width="7" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                    <path fill="#ff6600" d="M296 160H180.6l42.6-129.8C227.2 15 215.7 0 200 0H56C44 0 33.8 8.9 32.2 20.8l-32 240C-1.7 275.2 9.5 288 24 288h118.7L96.6 482.5c-3.6 15.2 8 29.5 23.3 29.5 8.4 0 16.4-4.4 20.8-12l176-304c9.3-15.9-2.2-36-20.7-36z" />
                                  </svg>
                                  {tokenInfo && getRoleDisplayName(detailMatching?.data?.matching?.buyerRoles)}
                                </span>
                              )}
                              {isMyDetailListing && detailMatching && (

                                <span style={{ color: "#ff6600", marginLeft: "0.3rem", fontSize: "13px" }}>
                                  <svg style={{ marginRight: "0.1rem" }} width="7" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                    <path fill="#ff6600" d="M296 160H180.6l42.6-129.8C227.2 15 215.7 0 200 0H56C44 0 33.8 8.9 32.2 20.8l-32 240C-1.7 275.2 9.5 288 24 288h118.7L96.6 482.5c-3.6 15.2 8 29.5 23.3 29.5 8.4 0 16.4-4.4 20.8-12l176-304c9.3-15.9-2.2-36-20.7-36z" />
                                  </svg>
                                  {tokenInfo && getRoleDisplayName(tokenInfo.roles)}
                                </span>
                              )}

                            </li>
                            {data?.company_url !== 'web_url_anonyma' && data?.company_url != "manda_deleted_manda" && (
                              <li className="link" key={'company_url'}>
                                <a href='javascript:;' onClick={() => goToCompanyUrl()}>{data?.company_url}</a>
                              </li>
                            )}
                          </>
                        ) : (
                          <>
                            <span className="txt"><Skeleton count={1.5} /></span>
                            <br />
                          </>
                        )
                      }

                      {isMyDetailListing && data?.ispublished && (

                        <li key={'is_published'}>
                          <li className='mt-2' key={'is_published'}>
                            <span style={{ display: "inline-block", marginRight: "4px" }} className="status">Status:</span>
                            <span className="published">
                              <span className='icon-check-article'></span> Published
                            </span>
                          </li>

                        </li>

                      )}

                      {isMyDetailListing && !data?.ispublished && (

                        <li className="status-unpublished d-flex mt-2 text-nowrap" key={'is_published'}>
                          <span style={{ display: "inline-block" }} className="status text-nowrap">Status:</span>
                          <span className="unpublished">
                            <svg style={{ margin: "0 3px" }} width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="#fa394d" className="w-6 h-6 ml-2">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M18.364 18.364A9 9 0 0 0 5.636 5.636m12.728 12.728A9 9 0 0 1 5.636 5.636m12.728 12.728L5.636 5.636" />
                            </svg> Unpublished (Draft)
                          </span>
                        </li>

                      )}

                      {!isMyDetailListing && detailMatching && !detailMatching?.data?.matching?.isUnlock && (

                        <li key={'is_published'}>
                          <li className='mt-5' key={'is_published'}>
                            <span style={{ display: "inline-block", marginRight: "4px" }} className="status">Status:</span>
                            <span className="locked">
                              <span className='icon-locked'></span> Locked
                            </span>
                          </li>

                        </li>

                      )}

                      {!isMyDetailListing && detailMatching && detailMatching?.data?.matching?.isUnlock && (

                        <li key={'is_published'}>
                          <li className='mt-2' key={'is_published'}>
                            <span style={{ display: "inline-block", marginRight: "4px" }} className="status">Status:</span>
                            <span className="published">
                              <span className='icon-check-article'></span> Unlocked
                            </span>
                          </li>

                        </li>

                      )}

                    </ul>

                  </ul>
                </div>
                {isEditing && (
                  <button
                    style={{ zIndex: 4 }}
                    className='btn editDoneIsv my-3'
                    onClick={() => doneEditing()}
                  >
                    Done Editing
                  </button>
                )}
              </div>
              {
                isMyDetailListing && data?.ispublished === false && (
                  <button
                    className={`btn btn-devinsider ${data?.isremoved ? ' disabled-ma-btn' : 'containerBtnBtn'} pl-5 pr-5`}
                    onClick={() => {
                      if (!isPublishLoading) {
                        publish();
                      }
                    }}
                  >
                    {isPublishLoading ? <Loader /> : "Publish"}
                  </button>
                )
              }
              {isMyDetailListing && !isEditing && (
                <ul className={`btn msgListing`}>
                  <div className='messag mt-3 mb-3'>
                    View 3 messages
                  </div>
                  <li className='pl-1 pr-1 threeP mt-3'>
                    {
                      isMyDetailListing && (
                        <NavLink className="det" to='#' onClick={() => setShow(!show)}>
                          <span className='icon-inprogress'></span>
                        </NavLink>
                      )
                    }
                    {show && (
                      <ul className='editListing'>
                        <>
                          <li key={'edit'} onClick={() => toggleEditingMode(true)}>Edit listing</li>
                          <>
                            <li
                              key={'preview_listing'}
                              onClick={() => {
                                if (data?.isremoved || checkForDeletedData() === false) {
                                  return;
                                }
                                goToPreview();
                              }}
                              className={`${data?.isremoved || checkForDeletedData() == false ? 'disable-listing-option' : 'enable-listing-option'}`}
                            >
                              Preview listing
                            </li>
                            <li
                              key={'unpublish_listing'}
                              onClick={() => {
                                if (data?.isremoved || checkForDeletedData() === false) {
                                  return;
                                }
                                data?.ispublished === true ? goToUnpublish() : publish()
                              }}

                              className={`${data?.isremoved ? 'disable-listing-option' : 'enable-listing-option'}`}
                            >
                              {data?.ispublished === true ? "Unpublish listing" : "Publish listing"}
                            </li>
                            <li
                              key={'delete_listing'}
                              onClick={() => {
                                if (data?.isremoved || checkForDeletedData() === false) {
                                  return;
                                }
                                goToDelete();
                              }}
                              className={`${data?.isremoved || checkForDeletedData() == false ? 'disable-listing-option' : 'enable-listing-option'}`}
                            >
                              Delete listing
                            </li>
                          </>

                        </>
                      </ul>
                    )}
                  </li>
                </ul>
              )
              }

              {
                !isMyDetailListing && !isEditing && (
                  <>
                    {/* {
                      detailMatching && detailMatching?.data?.matching && detailMatching?.data?.matching?.isUnlock === false && (
                        <button
                          className='btn btn-devinsider px-5 mt-0'
                          onClick={() => goToUnlock()}
                        >
                          {amount && amount != 'undefined' ? 'Unlock (' + (currency ? (getCurrencySymbol(currency) + amount) : 0.00) + ' ' + currency?.toUpperCase() : 'Loading...'}
                        </button>
                      )
                    } */}
                    {
                      detailMatching && detailMatching?.data?.matching && detailMatching?.data?.matching?.isUnlock !== false ? (
                        <button
                          className='btn btn-devinsider px-5 cancel'
                          onClick={goToMessaging}
                        >
                          Send a message
                        </button>
                      ) : (
                        <>
                          {
                            <>
                              <button
                                className='btn btn-devinsider px-5 mt-3'
                                onClick={() => goToUnlock()}
                              >
                                {amount && amount != 'undefined' && amount != '0' ? 'Unlock (' + (currency ? (getCurrencySymbol(currency) + amount) : 0.00) + ' ' + currency?.toUpperCase() + ')' : 'Loading...'}
                              </button>
                              <button
                                className='btn btn-devinsider px-5 cancel btn-disabled mt-2'
                              >
                                <svg style={{ marginRight: "0.3rem" }} width="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="mr-2">
                                  <path fillRule="evenodd" d="M12 1.5a5.25 5.25 0 0 0-5.25 5.25v3a3 3 0 0 0-3 3v6.75a3 3 0 0 0 3 3h10.5a3 3 0 0 0 3-3v-6.75a3 3 0 0 0-3-3v-3c0-2.9-2.35-5.25-5.25-5.25Zm3.75 8.25v-3a3.75 3.75 0 1 0-7.5 0v3h7.5Z" clipRule="evenodd" />
                                </svg>

                                Send a message
                              </button>
                            </>
                          }
                        </>

                      )
                    }

                  </>
                )
              }

              <div className={`block titleLeft border-0 mt-3 ${activeSection === 'M&A intentions' ? 'active' : ''}`} onClick={() => handleClickMenu(maIntentionRef, 'M&A intentions')}>
                <span className="txtL">1</span> <span className='txt'>M&A intentions</span>
              </div>
              <div className={`block titleLeft border-0 ${activeSection === 'Company overview' ? 'active' : ''}`} onClick={() => handleClickMenu(companyOverviewRef, 'Company overview')}>
                <span className="txtL">2</span> <span className='txt'>Company overview</span>
              </div>
              <div className={`block titleLeft border-0 ${activeSection === 'Acquisition target characteristics' ? 'active' : ''}`} onClick={() => handleClickMenu(acquisitionRef, 'Acquisition target characteristics')}>
                <span className="txtL">3</span> <span className='txt'>Acquisition target characteristics</span>
              </div>
              <div className={`block titleLeft border-0 ${activeSection === 'M&A contact details' ? 'active' : ''}`} onClick={() => handleClickMenu(maContactRef, 'M&A contact details')}>
                <span className="txtL">4</span> <span className='txt'>M&A contact details </span>
              </div>
            </div>
          </div>
          <div className='container mx-auto minHeightwin sellerRight'>
            <div className='listOfIsv completePage mt-5'>
              <div className='scrollContainer'>
                <div className={`contentUser `} ref={maIntentionRef}>
                  <div>
                    {isEditing && (
                      <>
                        <a
                          className="editItem"
                          onClick={() => goToMAIntention()}
                        >
                          Edit
                          <span className="icon-edit-full"></span>
                        </a>
                      </>
                    )}
                    <h2 className='title'>M&A intentions</h2>

                  </div>
                  <span className="txt">
                    {
                      data?.isremoved == false && data?.ma_intention != "manda_deleted_manda" ? (
                        <ul className='listTopic'>
                          <span> {data?.ma_intention}</span>
                        </ul>
                      ) : (
                        <span className="txt"><Skeleton count={1.5} /></span>
                      )
                    }
                  </span>
                  <h2 className='title small'>Intended acquisitions in the next 12 mounths</h2>
                  <span className="txt">
                    {
                      data?.isremoved == false && data?.challenge != "manda_deleted_manda" ? (
                        <ul className='listTopic'>
                          <span className="topic"> {data?.challenge}</span>
                        </ul>
                      ) : (
                        <span className="txt"><Skeleton count={1.5} /></span>
                      )
                    }
                  </span>
                </div>
                <div className={`contentUser `} ref={companyOverviewRef}>
                  <div>
                    {isEditing && (
                      <>
                        <a
                          className="editItem"
                          onClick={() => goToCompanyOverView()}
                        >
                          Edit
                          <span className="icon-edit-full"></span>
                        </a>
                      </>
                    )}
                    <h2 className='title'>Company overview</h2>
                  </div>
                  <span className="txt">
                    {
                      data?.isremoved == false && data?.company_reason != "manda_deleted_manda" ? (
                        <ul className='listTopic'>
                          <span> {data?.company_reason}</span>
                        </ul>
                      ) : (
                        <span className="txt"><Skeleton count={1.5} /></span>
                      )
                    }
                  </span>
                  <div className='details'>
                    <ul className='listDetails'>
                      <li className="item" key={"location"}>
                        {
                          data?.isremoved == false && data?.location != "manda_deleted_manda" ? (
                            <>
                              <span className="ico icon-location"></span>
                              <div>Location</div>
                              <span className="txt">  {ISO3166.getCountry(data?.location)}</span>
                            </>
                          ) : (
                            <img
                              src={Img}
                              width={50}
                              height={60}
                            />
                          )
                        }

                      </li>
                      <li className="item" key={"founded"}>
                        {
                          data?.isremoved == false && data?.founded != "manda_deleted_manda" ? (
                            <>
                              <span className="ico icon-founded"></span>
                              <div>Founded</div>
                              <span className="txt">  {data?.founded}</span>
                            </>
                          ) : (
                            <img
                              src={Img}
                              width={50}
                              height={60}
                            />
                          )
                        }
                      </li>
                      <li className="item" key={"companySize"}>
                        {
                          data?.isremoved == false && data?.company_size != "manda_deleted_manda" ? (
                            <>
                              <span className="ico icon-compSize"></span>
                              <div>Company size</div>
                              <span className="txt">  {data?.company_size}</span>
                            </>
                          ) : (
                            <img
                              src={Img}
                              width={50}
                              height={60}
                            />
                          )
                        }
                      </li>
                      <li className="item" key={"recent_acquisition"}>
                        {
                          data?.isremoved == false && data?.recent_acquisition != "manda_deleted_manda" ? (
                            <>
                              <span className="ico">
                                <img src={acquisitionImg} width={40} height={60} />
                              </span>
                              <div>Recent acquisition</div>
                              <span className="txt">  {data?.recent_acquisition}</span>
                            </>
                          ) : (
                            <img
                              src={Img}
                              width={50}
                              height={60}
                            />
                          )
                        }
                      </li>
                      <li className="item" key={"acquisition_goal"}>
                        {
                          data?.isremoved == false && data?.main_acquisition_goal != "manda_deleted_manda" ? (
                            <>
                              <span className="ico">
                                <img src={GoalImg} width={35} height={60} />
                              </span>
                              <div>Main acquisition goal </div>
                              <span className="txt">  {data?.main_acquisition_goal}</span>
                            </>
                          ) : (
                            <img
                              src={Img}
                              width={50}
                              height={60}
                            />
                          )
                        }
                      </li>
                      <li className="item" key={"budget_indication"}>
                        {
                          data?.isremoved == false && data?.location != "manda_deleted_manda" ? (
                            <>
                              <span className="ico">
                                <img src={DollarImg} width={40} height={60} />
                              </span>
                              <div>M&A budget indication</div>
                              <span className="txt">
                                {data?.ma_budget_indication !== "" ? data?.ma_budget_indication : "-"}
                              </span>
                            </>
                          ) : (
                            <img
                              src={Img}
                              width={50}
                              height={60}
                            />
                          )
                        }
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h2 className='title small'>Software solution (Horizontal)</h2>
                    {
                      data?.isremoved == false && !data?.software_solution?.find((el: any) => el === "manda_deleted_manda") ? (
                        <ul className='listTopic'>
                          {([...data?.software_solution, ...data?.featured_solution] || []).filter((el, index, self) => self.indexOf(el) === index).map((el: any, index: number) => (
                            <span key={index} className="topic">{el}</span>
                          ))}
                        </ul>
                      ) : (
                        <span className="txt"><Skeleton count={1.5} /></span>
                      )
                    }

                  </div>
                  <div>
                    <h2 className='title small'>Industries served (Vertical)</h2>
                    {
                      data?.isremoved == false && !data?.targeted_industry?.find((el: any) => el === "manda_deleted_manda") ? (
                        <ul className='listTopic'>
                          {
                            data?.targeted_industry?.map((el: any) => (
                              <span className="topic">  {el}</span>
                            ))
                          }
                        </ul>
                      ) : (
                        <span className="txt"><Skeleton count={1.5} /></span>
                      )
                    }

                  </div>
                  <div>
                    <h2 className='title small'>Geographic sales footprint</h2>
                    {data?.isremoved == false && !data?.geographiquebuyer?.country.find((el: any) => el === "manda_deleted_manda") ? (
                      <div className="geographicList border-0">
                        <Accordion defaultActiveKey={activeId}>
                          <div className="geographicList">
                            <Accordion defaultActiveKey={activeId}>
                              {
                                geographicData && Object.values(geographicData)?.map((item: any, index: number) => {
                                  return (
                                    <div
                                      className={
                                        activeId == index.toString()
                                          ? "panel-wrap active-panel"
                                          : "panel-wrap"
                                      }
                                      key={index}
                                    >
                                      <Accordion.Item eventKey={index.toString()}>
                                        <div className="panel-header">
                                          <Accordion.Header
                                            onClick={() => toggleActive(index.toString())}
                                            className="panel-toggle selectedItem"
                                          >
                                            <span className="topic">{item && item?.Continent}</span>
                                          </Accordion.Header>
                                        </div>

                                        <Accordion.Body>
                                          {
                                            item &&
                                            item?.Countries &&
                                            item?.Countries?.map((pays: any, key: number) => {
                                              return (
                                                <div className="panel-body" key={key}>
                                                  <span className="topic">
                                                    {pays?.Name}
                                                  </span>
                                                </div>
                                              )
                                            })
                                          }
                                        </Accordion.Body>
                                      </Accordion.Item>

                                    </div>
                                  )
                                })
                              }
                            </Accordion>

                          </div>
                        </Accordion>
                      </div>
                    ) : (
                      <span className="txt"><Skeleton count={1.5} /></span>
                    )}
                  </div>
                  {/* {data?.ecosystem?.length > 0 && (
                    <div>
                      <h2 className='title small'>Technology partnerships</h2>
                      {data?.isremoved == false && !data?.ecosystem?.find((el: any) => el === "manda_deleted_manda") ? (
                        <ul className='listTopic'>
                          {data.ecosystem.map((el: any) => (
                            <span className="topic"> {el}</span>
                          ))}
                        </ul>
                      ) : (
                        <span className="txt"><Skeleton count={1.5} /></span>
                      )}
                    </div>
                  )} */}
                </div>
                <div className={`contentUser`} ref={acquisitionRef}>
                  <div>
                    {isEditing && (
                      <>
                        <a
                          className="editItem"
                          onClick={() => goToAcquisition()}
                        >
                          Edit
                          <span className="icon-edit-full"></span>
                        </a>
                      </>
                    )}
                    <h2 className='title'>Acquisition target characteristics</h2>
                  </div>
                  <div>
                    <h2 className='title small'>Preferred ISV life cycle stage</h2>
                    {
                      data?.isremoved == false && !data?.lifecycle_stage?.find((el: any) => el === "manda_deleted_manda") ? (
                        <ul className='listTopic'>
                          {
                            data?.lifecycle_stage?.map((el: any) => (
                              <>
                                <span className="topic">{el}</span>
                              </>
                            ))
                          }
                        </ul>
                      ) : (
                        <span className="txt"><Skeleton count={1.5} /></span>
                      )
                    }

                    <div>
                      <h2 className='title small'>Preferred software solutions to acquire (Horizontal)</h2>
                      {
                        data?.isremoved == false &&
                          !data?.preferred_industries?.find((el: any) => el === "manda_deleted_manda") &&
                          !data?.preferred_software?.find((el: any) => el === "manda_deleted_manda") ? (
                          <ul className='listTopic'>
                            {([...data?.preferred_software, ...data?.preferred_featured_software] || []).filter((el, index, self) => self.indexOf(el) === index).map((el: any, index: number) => (
                              <span key={index} className="topic">{el}</span>
                            ))}
                            {/* {
                              allPreffered?.map((el: any) => (
                                <>
                                  <span className="topic">{el}</span>
                                </>
                              ))
                            } */}
                          </ul>
                        ) : (
                          <span className="txt"><Skeleton count={1.5} /></span>
                        )
                      }
                    </div>
                    <div>
                      <h2 className='title small'>Preferred industries (Vertical)</h2>
                      {
                        data?.isremoved == false && !data?.preferred_industries?.find((el: any) => el === "manda_deleted_manda") ? (
                          <ul className='listTopic'>
                            {
                              data?.preferred_industries?.map((el: any) => (
                                <>
                                  <span className="topic">{el}</span>
                                </>
                              ))
                            }
                          </ul>
                        ) : (
                          <span className="txt"><Skeleton count={1.5} /></span>
                        )
                      }
                      <h2 className='title small'>Preferred ISV HQ location</h2>
                      {
                        data?.isremoved == false && !data?.prefered_hq_location?.find((el: any) => el === "manda_deleted_manda") ? (
                          <ul className="listTopic">
                            {
                              data?.prefered_hq_location.map((hqLocation: any, i: number) => (
                                <span className="topic" key={i}>
                                  {ISO3166.getCountry(hqLocation.iso2)}
                                </span>
                              ))
                            }
                          </ul>
                        ) : (
                          <span className="txt"><Skeleton count={1.5} /></span>
                        )
                      }
                      {

                        isMyDetailListing == true && (
                          <>
                            <h2 className='title small'>Preferred opportunity timeframe
                              <OverlayTrigger
                                overlay={
                                  <Tooltip className="custom-tooltip" id="trade">
                                    This information is used for matchmaking but will not be displayed on your published listing.
                                  </Tooltip>
                                }
                              >
                                <span className="Tooltip">i</span>
                              </OverlayTrigger>
                            </h2>
                            {
                              data?.isremoved == false && data.opportunity !== 'manda_deleted_manda' ? (
                                <ul className='listTopic'>
                                  <span className="topic">{data?.opportunity}</span>
                                </ul>
                              ) : (
                                <span className="txt"><Skeleton count={1.5} /></span>
                              )
                            }
                            <h2 className='title small'>Preferred ecosystem/technology partnerships
                              <OverlayTrigger
                                overlay={
                                  <Tooltip className="custom-tooltip" id="trade">
                                    This information is used for matchmaking but will not be displayed on your published listing.
                                  </Tooltip>
                                }
                              >
                                <span className="Tooltip">i</span>
                              </OverlayTrigger>
                            </h2>
                            {data?.ecosystem?.length > 0 && (
                              <div>
                                {data?.isremoved == false && !data?.ecosystem?.find((el: any) => el === "manda_deleted_manda") ? (
                                  <ul className='listTopic'>
                                    {data.ecosystem.map((el: any) => (
                                      <span className="topic"> {el}</span>
                                    ))}
                                  </ul>
                                ) : (
                                  <span className="txt"><Skeleton count={1.5} /></span>
                                )}
                              </div>
                            )}
                          </>
                        )
                      }
                    </div>
                  </div>
                </div>
                <div className={`contentUser ${!isMyDetailListing && detailMatching && !detailMatching?.data?.matching?.isUnlock ? ' contactDetails' : ''}`} ref={maContactRef}>
                  <div>
                    {isEditing && (
                      <>
                        <a
                          className="editItem"
                          onClick={() => goToMacontact()}
                        >
                          Edit
                          <span className="icon-edit-full"></span>
                        </a>
                      </>
                    )}

                    <h2 className='title'><span className={`${!isMyDetailListing && detailMatching && !detailMatching?.data?.matching?.isUnlock ? 'icon-locked' : ''}`}></span> M&A contact details</h2>
                  </div>
                  <div>
                    <h2 className='title small'>Contact person</h2>
                    {
                      isMyDetailListing && data?.isremoved == false && data.firstname !== 'manda_deleted_manda' && data.lastname !== 'manda_deleted_manda' ? (
                        <ul className='listTopic'>
                          <span className="topic">{data?.firstname} {data?.lastname}</span>
                        </ul>
                      ) : isMyDetailListing && (data?.isremoved == true || data.firstname === 'manda_deleted_manda' || data.lastname === 'manda_deleted_manda') ? (
                        <span className="txt"><Skeleton count={1.5} /></span>
                      ) : !isMyDetailListing && detailMatching && !detailMatching?.data?.matching?.isUnlock ? (
                        <ul className='listTopic'>
                          <span style={{ color: "#64b9da" }} className="topic">[Hidden Information]</span>
                        </ul>
                      ) : !isMyDetailListing && detailMatching && detailMatching?.data?.matching?.isUnlock ? (
                        <ul className='listTopic'>
                          <span className="topic">{data?.firstname} {data?.lastname}</span>
                        </ul>
                      )
                        : (<></>)
                    }
                    <div>
                      <h2 className='title small'>Email</h2>
                      {
                        isMyDetailListing && data?.isremoved == false && data?.email !== 'manda_deleted_manda' ? (
                          <ul className='listTopic'>
                            <span className="topic">{data?.email}</span>
                          </ul>
                        ) : isMyDetailListing && (data?.isremoved == true || data?.email === 'manda_deleted_manda') ? (
                          <span className="txt"><Skeleton count={1.5} /></span>
                        ) : !isMyDetailListing && detailMatching && !detailMatching?.data?.matching?.isUnlock ? (
                          <ul className='listTopic'>
                            <span style={{ color: "#64b9da" }} className="topic">[Hidden Information]</span>
                          </ul>
                        ) : !isMyDetailListing && detailMatching && detailMatching?.data?.matching?.isUnlock ? (
                          <ul className='listTopic'>
                            <span className="topic">{data?.email}</span>
                          </ul>
                        )
                          : (<></>)
                      }

                    </div>
                    <div>
                      <h2 className='title small'>Linkedin url</h2>
                      {
                        isMyDetailListing && data?.isremoved == false && data?.linkedin_url !== 'manda_deleted_manda' ? (
                          <ul className='listTopic'>
                            <span className="topic">{data?.linkedin_url}</span>
                          </ul>
                        ) : isMyDetailListing && (data?.isremoved == true || data?.linkedin_url === 'manda_deleted_manda') ? (
                          <span className="txt"><Skeleton count={1.5} /></span>
                        ) : !isMyDetailListing && detailMatching && !detailMatching?.data?.matching?.isUnlock ? (
                          <ul className='listTopic'>
                            <span style={{ color: "#64b9da" }} className="topic">[Hidden Information]</span>
                          </ul>
                        ) : !isMyDetailListing && detailMatching && detailMatching?.data?.matching?.isUnlock ? (
                          <ul className='listTopic'>
                            <span className="topic">{data?.linkedin_url}</span>
                          </ul>
                        )
                          : (<></>)
                      }

                    </div>
                    <div>
                      <h2 className='title small'>Phone number</h2>
                      {
                        isMyDetailListing && data?.isremoved == false && data?.phone_number !== 'manda_deleted_manda' ? (
                          <ul className='listTopic'>
                            <span className="topic">{data?.phone_number}</span>
                          </ul>
                        ) : isMyDetailListing && (data?.isremoved == true || data?.phone_number === 'manda_deleted_manda') ? (
                          <span className="txt"><Skeleton count={1.5} /></span>
                        ) : !isMyDetailListing && detailMatching && !detailMatching?.data?.matching?.isUnlock ? (
                          <ul className='listTopic'>
                            <span style={{ color: "#64b9da" }} className="topic">[Hidden Information]</span>
                          </ul>
                        ) : !isMyDetailListing && detailMatching && detailMatching?.data?.matching?.isUnlock ? (
                          <ul className='listTopic'>
                            <span className="topic">{data?.phone_number}</span>
                          </ul>
                        )
                          : (<></>)
                      }

                    </div>
                  </div>
                </div>

                <div className='pagginationCategory'>
                  <div className='pagination'>

                  </div>
                </div>
                <DeleteSellerListing
                  isOpen={showDeleteModal}
                  title='Delete listing'
                  closeModal={() => setShowDeleteModal(!showDeleteModal)}
                  confirmDelete={deletebuyer}
                  loading={isDeleteLoading}
                />
                <Unpublish
                  isOpen={showUnpublishModal}
                  title='Unpublish listing'
                  closeModal={() => setShowUnpublishModal(false)}
                  confirmUnpublish={publish} isLoading={isPublishLoading}

                />
                <Unlock
                  isOpen={showUnlockModal}
                  title='Unlock listing'
                  closeModal={() => setShowUnlockModal(false)}
                  confirmUnlock={unlock} isLoading={isPublishLoading}
                />
                <AcquisitionTarget
                  isOpen={showAcquisitionModal}
                  title='Acquisition target characteristics'
                  closeModal={() => setAcquisitioModal(!showAcquisitionModal)}
                  confirmSave={saveDataAcquisition}
                  data={data}
                  isUpdating={isUpdating}
                />

                <CompanyEdit
                  isOpen={showCompanyeditModal}
                  title='Company details'
                  closeModal={() => setShowCompanyModal(!showCompanyeditModal)}
                  confirmSave={saveDataCompany}
                  data={data}
                  isUpdating={isUpdating}
                />
                <MAContact
                  isOpen={showMAcontactedit}
                  title='M&A contact details'
                  closeModal={() => setMAcontactModal(!showMAcontactedit)}
                  confirmSave={saveDataMAContact}
                  data={data}
                  isUpdating={isUpdating}
                />
                <MAIntention
                  isOpen={showMAIntentionedit}
                  title='M&A intentions'
                  closeModal={() => setMAIntentionModal(!showMAIntentionedit)}
                  confirmSave={saveDataMAInterest}
                  data={data}
                  isUpdating={isUpdating}
                />
                <CompanyOverview
                  //isOpen={showCompanyOverviewedit && dataLoaded}
                  isOpen={showCompanyOverviewedit}
                  title='Company Overview'
                  closeModal={() => setCompanyOverviewModal(!showCompanyOverviewedit)}
                  confirmSave={saveDataOverview}
                  data={updatedData}
                  isUpdating={isUpdating}
                />
                <PaymentPopup
                  planType={isPremium ? "motivated" : "opportunistic"}
                  listingType="buyer"
                  successCallback={paymentMethodCallBack}
                  handleStatusOk={handleStatusOk}
                  setShowPaymentModal={setShowPaymentModal}
                  title={"Publish listing"}
                  showTryAgain={false}
                  showContinue={true}
                  handleClose={() => setShowPaymentModal(false)}
                  handleCancel={() => setShowPaymentModal(false)}
                  show={showPaymentModal}
                  modalSize="large"
                  isUpgradePlan={false}
                />
                {lookUpKey &&
                  <PaymentPopup
                    planType={lookUpKey}
                    listingType="buyer"
                    productTitle='Unlock buyer listing'
                    successCallback={paymentMethodCallBackForUnlock}
                    handleStatusOk={handleStatusOk}
                    setShowPaymentModal={setShowPaymentModalForUnlock}
                    title={"Unlock listing"}
                    showTryAgain={false}
                    showContinue={true}
                    handleClose={() => setShowPaymentModalForUnlock(false)}
                    handleCancel={() => setShowPaymentModalForUnlock(false)}
                    show={showPaymentModalForUnlock}
                    modalSize="large"
                    isUpgradePlan={false}
                  />
                }
                <FreePublishSuccess
                  show={showPublishSuccess}
                  handleOk={() => {
                    refetchData();
                    setShowPublishSuccess(!showPublishSuccess)
                  }}
                />

                <FreePublishFailed
                  show={showPublishFailed}
                  handleOk={() => {
                    refetchData();
                    setShowPublishFailed(!showPublishFailed)
                  }}
                />
              </div>
            </div>
          </div>
        </div >
      )}
    </>
  );
};

export default BuyerListingDetails;

/* eslint-disable jsx-a11y/anchor-is-valid */
import Modal from 'react-bootstrap/Modal';
import icoFailed from '../../../resources/images/ban-solid.svg';
import { PaymentMethod } from "../use-payment-hook";
import "./payment-status.scss";

type IProps = {
  title: string;
  show: boolean;
  handleClose?: () => void;
  paymentMethod: PaymentMethod | undefined
  tryAgain?: () => void;
  handleContinue?: () => void;
  showContinue: boolean;
  showTryAgain: boolean;
  isUpgradePlan: boolean;
  lookupKey: string;
};

const PaymentErrorModal = (props: IProps) => {
  return (
      <Modal
        show={props.show}
        className='createCompanypopup medium forStep5custom'
      >
        <Modal.Header>
          <div className='headerpopupCompany noStep'>
            <h2 className='title'>{props.title}</h2>
            <a
              className='close'
              onClick={() => {
                props.handleClose && props.handleClose();
              }}
            >
              <span className='icon-close-pop'></span>
            </a>
          </div>
        </Modal.Header>
        <Modal.Body className=''>
          <div className='contentBorder forBillingCenter pt-3 pb-0 text-center'>
            <div className='payement_failed'>
              <div className="error-img">
                <img
                    src={icoFailed}
                    alt=""
                    width={150}
                />
              </div>
              <span className='txt mb-3 warning'>Payment failed</span>
                <>
                  <p className='mb-0 mt-3'>
                    Your payment was not successfully processed.
                  </p>
                  {!props?.isUpgradePlan && !props?.lookupKey.toUpperCase().includes('UNLOCK') && <p className='mb-0'>Your listing has been saved as an unpublished draft.</p>}
                  <br/>
                  <p>
                    If this issue persists please contact the bank that issued your 
                    <br/>credit card for more information on why the payment failed.
                  </p>
                </>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='contentBorder text-center'>
            {
              props?.showTryAgain &&
                <button
                  className='btn btn-devinsider containerBtnBtn pl-5 pr-5'
                  onClick={() => {
                    props.tryAgain && props.tryAgain();
                  }}
                >
                  Try again
                </button>
            }
            {
              props?.showContinue &&
              <div className='contentBorder text-center'>
                <button
                  className='btn btn-devinsider containerBtnBtn pl-5 pr-5'
                  onClick={() => props.handleContinue && props.handleContinue()}
                >
                  Continue
                </button>
              </div>
            }
          </div>
        </Modal.Footer>
      </Modal>
  );
};

export default PaymentErrorModal;

import { useState } from "react";
import { Modal } from "react-bootstrap";
import Loader from "../../../resources/images/Rolling.svg";
import '../../seller/seller.scss';


type Subscription = {
    type: string;
    title: string;
    content:  JSX.Element;
}

type IProps = {
    subscriptionsTypes: Array<Subscription>;
    myPlan: string;
    closeModal: () => void;
    show: boolean;
    title: string;
    enableUpgrade: boolean;
    handleUpgrade: () => void;
    setPlanTypeChecked: (value: string) => void;
    planTypeChecked: string;
}

export default function CurrentPlan(props: IProps) {

    const [isUpgradeLoading, setIsUpgradeLoading] = useState<boolean>(false);

    return (
        <Modal
            show={props.show}
            id="seller-upgrade-plan"
            className='createCompanypopup large forStep5custom'
        >
            <Modal.Header>
                <div className='headerpopupCompany noStep'>
                    <h2 className='title'>{props?.title}</h2>
                    <a className='close' onClick={() => props?.closeModal()}><span className='icon-close-pop'></span></a>
                </div>
            </Modal.Header>
            <Modal.Body className='withMinHeight'>
                <div className='getstartedContainer largeContainer'>
                    <div style={{minHeight:"100%", padding:"0 5rem"}} className='sellerContainer'>
                        <div className="userType chooseSubscription">
                            {
                                props?.subscriptionsTypes?.map((el : any, index : any) => (
                                    <div className={`item${props?.planTypeChecked === el?.type ? " active" : ""}`} key={index}>
                                        <div className='title'>{el?.title}</div>
                                        <span className='txt'>
                                            {el?.content}
                                        </span>
                                        {(props?.enableUpgrade || (!props?.enableUpgrade && props?.planTypeChecked === el?.type)) && <div className='select'>
                                            <input
                                                id={`select-${el?.type}`}
                                                type="radio"
                                                value={el?.type}
                                                name="planType"
                                                checked={props?.planTypeChecked === el?.type}
                                                onChange={(evt) => props?.enableUpgrade && props?.setPlanTypeChecked(evt.target.value)}
                                            />
                                            <label htmlFor={`select-${el?.type}`}>
                                                {props?.myPlan === el?.type ? 'Current plan' : 'Select'}
                                            </label>
                                        </div>}
                                    </div>
                                ))
                            }
                        </div>
                        {props?.enableUpgrade &&
                            <div className='text-align-right'>
                                <button className='btn btn-devinsider cancel' onClick={() => {
                                    props?.closeModal();
                                    props?.setPlanTypeChecked(props?.myPlan);
                                }}>
                                    Cancel
                                </button>
                                {props?.myPlan !== "" && props?.myPlan != props?.planTypeChecked &&
                                    <button
                                        className={`btn btn-devinsider save ${props?.myPlan === "" || props?.myPlan == props?.planTypeChecked ? "disabled-ma-btn" : ""}`}
                                        disabled={props?.myPlan === "" || props?.myPlan == props?.planTypeChecked} onClick={() => props?.handleUpgrade()}
                                    >
                                        {isUpgradeLoading ? <Loader /> : 'Upgrade plan'}
                                    </button>
                                }
                            </div>
                        }
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

import moment from "moment";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { ReactComponent as Loader } from "../../../../resources/images/Rolling.svg";
import toggleoff from "../../../../resources/images/toggleoff.svg";
import toggleon from "../../../../resources/images/toggleon.svg";
import { capitalizeFirstLetter } from "../../../../utils/outils";

export type IpropsConfirmCancel = {
    show?: boolean;
    checkSubscription: any;
    handleShowConfirm: (p: boolean) => void;
    cancelSubscriptionRenew: () => void;
    enableSubscriptionRenew: () => void;
    showLoader: boolean;
    type: string;
};

const ConfirmUpdateRenewalSubscription = (props: IpropsConfirmCancel) => {
    const updateRenewal = () => {
        props?.checkSubscription?.subscription?.renewal_enabled ?  props?.cancelSubscriptionRenew() : props?.enableSubscriptionRenew()
    }

    const [subscriptionName, setSubscriptionName] = useState<string>('');

    useEffect(() => {
        if (props?.checkSubscription) {
            let name = capitalizeFirstLetter(props?.checkSubscription?.subscription?.type?.name);
            if(props?.checkSubscription?.subscription?.type?.name.indexOf(props?.type.toUpperCase()) == -1){
                name += ' ' + props?.type;
            }
            setSubscriptionName(name);
        }
    }, [props?.type, props?.checkSubscription])

    return (
        <Modal
            show={props.show}
            className='createCompanypopup medium forStep5custom'
        >
            <Modal.Header>
                <div className='headerpopupCompany noStep'>
                    <h2 className='title'>{`Turn ${props?.checkSubscription?.subscription?.renewal_enabled ? "off": "on"} automatic renewal`}</h2>
                    <a className='close' onClick={() => props.handleShowConfirm(false)}><span className='icon-close-pop'></span></a>
                </div>
            </Modal.Header>
            <Modal.Body>
            <div className="mx-auto">
                <img
                    className="d-block mx-auto"
                    src={props?.checkSubscription?.subscription?.renewal_enabled ? toggleoff : toggleon}
                    style={{width:"70px", marginTop:"1rem"}} alt="toggle-disabled"
                />
            </div>
            <h3 className="title-cancel title mb-5">
                Are you sure you want to continue?
            </h3>

            <p className="mb-5 pb-5 text-center">
                You are about to turn {props?.checkSubscription?.subscription?.renewal_enabled ? "off": "on"} the renewal of your '{subscriptionName}' {" "} subscription.
                <br /><br />Your current subscription will end on <b>{moment(props?.checkSubscription?.end_at).format("LL")}</b> and {props?.checkSubscription?.subscription?.renewal_enabled ? "will not be renewed": "will be renewed automatically"}.
            </p>
            </Modal.Body>
            <Modal.Footer className="mt-5">
            <button
                className='btn btn-devinsider cancel'
                onClick={() =>props.handleShowConfirm(false)}
            >
                Cancel
            </button>
            <button
                className="btn btn-devinsider save text-white"
                onClick={() => updateRenewal()}
                type="button"
            >
                {props?.showLoader ? <Loader /> : "Confirm"}
            </button>
            </Modal.Footer>
        </Modal>
    );
}
export default ConfirmUpdateRenewalSubscription;

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from 'react';
import { stripePrivateKey } from "../../../utils/outils";
import { PaymentInfo } from "./payment-info";


export type PaymentInfoProps = {
    product?: {
        lookupKey: string
        name: string
    }
    currency?: string
    setCurrency: (currency: string) => void
    getProductPriceWithTax: (country: string, vat: {
        has_vat: boolean,
        vat_number: string,
        company_name: string
    }) => Promise<any>
    getCurrencySymbol: (currency?: string) => string,
    productPrice: {
        amount: number,
        tax: number,
        currency: string,
        totalAmount: number,
    }
    setProductPrice: (price: {
        amount: number,
        tax: number,
        currency: string,
        totalAmount: number,
    }) => void
    userEmail: string | undefined,
    successCallback?: (
        paymentMethodId: string | null,
        setLoading: (l: boolean) => void,
        savePaymentMethod?: boolean,
        setShowPaymentStatus?: (show: boolean, data: {status: 'success' | 'error'; invoiceNumber?: string; invoiceUrl?: string}) => void
    ) => void,
    handleStatus: (show: boolean, data: {status: 'success' | 'error'; invoiceNumber?: string; invoiceUrl?: string}) => void
    handleCancel:() => void;
    isUpgradePlan: boolean;
}

export const PaymentInfoWrapper: React.FC<PaymentInfoProps> = (props) => {
    const [stripePromise, setStripePromise] = useState<any | null>(null);

    useEffect(() => {
        if (stripePrivateKey) {
            const promise = loadStripe(
                stripePrivateKey,
                { locale: 'en' }
            );
            setStripePromise(promise);
        }
    }, [stripePrivateKey]);

    if (!stripePromise) {
        return null;
    }

    return(
        <Elements stripe={stripePromise}>
            <PaymentInfo
                {...props}
            />
        </Elements>
    )
}
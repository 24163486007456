

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetTypeUser } from "../../api/user";
import CancelCreationModal from "../../components/modals/cancel-creation/cancel-creation";
import { useKeycloakHooks } from "../../hooks/keycloak";
import "../seller/seller.scss";

export default function Enroll() {
    const navigate = useNavigate();

    const [typeSelected, setTypeSelected] = useState<string>();
    const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

    const goToHomePage = () => {
        navigate("/");
    };

    const { keycloak, tokenInfo } = useKeycloakHooks();

    const { data: userRoles, isLoading: userRolesLoading } = useGetTypeUser(tokenInfo, true);

    useEffect(() => {
        if (typeof document !== 'undefined') {
            if(!keycloak?.authenticated){
                goToHomePage();
            }
        }
        
    }, [keycloak?.authenticated]);

    useEffect(() => {
        if(keycloak?.authenticated && (userRoles?.isBuyer || userRoles?.isSeller)){
            goToHomePage();
        }
    }, [userRoles]);


    const openCancelModal = () => {
        setShowCancelModal(true);
    };

    const closeCancelModal = () => {
        setShowCancelModal(false);
    };

    const leaveCreationListing = () => {
        closeCancelModal();
        navigate('/seller/listing');
    }

    const types = [
        { name: "Seller", icon: "icon-sellers"},
        { name: "Buyer", icon: "icon-buyerList"}
    ]

    const handleSelectType = (type: string) => {
        setTypeSelected(type.toLowerCase());
    }

    const handleContinue = () => {
        navigate('/' + typeSelected + '/listing/creation');
    }

    return (
        <div className="findIsvcontainer programContainer sellerContainer">
        <div className="filterFindIsv notSecondMenu">
            <div className="contentFilter">
                <div className="block titleLeft border-0 active">
                <span className="icon-prevbtn txtL"></span> <span className='txt'>
                    What type of user you are?
                </span>
                </div>
            </div>
        </div>
        <div className="container mx-auto minHeightwin pt-4">
            {userRolesLoading && <div>Loading...</div>}
            {userRoles &&
                <div className="contentUser enrollType">
                <div className="userType">
                    {
                        types?.map((el: any, index: number) => (
                            <div className={`link-topic ${el?.name.toLowerCase() == typeSelected ? ' active' : ''}`} onClick={() => handleSelectType(el?.name)} key={index}>
                                <span className={el?.icon}></span>{el?.name}
                            </div>
                        ))
                    }
                </div>
                <div className="text-align-right stepsButtons">
                    <div className="btn btn-devinsider cancel" onClick={() => openCancelModal()}>Cancel</div>
                    <div className={`btn btn-devinsider contin ${!typeSelected ? ' disabled-ma-btn' : ''}`} onClick={() => handleContinue()}>Continue</div>
                </div>
                </div>
            }
        </div>
        <CancelCreationModal
            isOpen={showCancelModal}
            closeModal={closeCancelModal}
            confirmCancel={leaveCreationListing}
            title={'Publish listing'}
        />
        </div>
    );
}

import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import "./forms.scss";
import SolutionsList from './solutionsList';

export default function ModalSolutionList(props: any) {
  const [activeId, setActiveId] = useState('0');

  function toggleActive(id: any) {
    if (activeId == id) {
      setActiveId('null');
    } else {
      setActiveId(id);
    }
  }

  return (
    <Modal
      show={props.show}
      className='createCompanypopup large forStep5custom'
    >
      <Modal.Header>
        <div className='headerpopupCompany noStep'>
          <h2 className='title'>Select up to 3 solution categories.</h2>
          <a className='close' onClick={() => props?.cancel()}><span className='icon-close-pop'></span></a>
        </div>
      </Modal.Header>
      <Modal.Body className='withMinHeight'>
        <SolutionsList
            featuredSolutionSelected={props?.featuredSolutionSelected}
            solutionsSelected={props?.solutionSelected}
            addFeaturedSolution={props?.addFeaturedSolution}
            addSoftwareSolution={props?.addSoftwareSolution}
            removeSolution={props?.removeSolution}
            removeItemSelected={props?.removeItem}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className='contentBorder formForEmail text-right'>
          <button
            className='btn btn-devinsider cancel'
            onClick={() => props?.cancel()}
          >
            Cancel
          </button>
          <button
            className='btn btn-devinsider px-5 save'
            onClick={() => props?.valid()}
          >
            Save
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
import { useState } from "react";
import PaymentMethodInfo from "./payment-method-info/payment-method-info";

const PaymentInfo = () => {
    const [showModalPaymentInfo, setShowModalPaymentInfo] =  useState<boolean>(false);
    
    return (
        <PaymentMethodInfo
            showModalPaymentInfo={showModalPaymentInfo}
            setShowModalPaymentInfo={setShowModalPaymentInfo}
        />
    );
};

export default PaymentInfo;

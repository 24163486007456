"use client";

import "../pages/seller/seller.scss";

type IProps = {
    seeMoreOrLess: (p: string) => void;
    limit: number;
    dataSource: Array<any>;
  };
  export default function SeeBtn(props: IProps) {
    const seeLess =
      props?.dataSource?.length > 6 && props.dataSource.length === props.limit;
  
    const title = seeLess
      ? 'See less'
      : props?.dataSource?.length - props.limit > 0
      ? `See more ${props.dataSource.length - props.limit}`
      : '';
    return (
        <a href="javascript:;" className="seemore" onClick={() => props.seeMoreOrLess(seeLess ? '1' : '0')}>
            {title}
        </a>
    );
  }
import { Modal } from "react-bootstrap";
import Img from "../../../resources/images/warning.svg";
import { ReactComponent as Loader } from '../../../resources/images/Rolling.svg';

export type IProps = {
    isOpen: boolean;
    closeModal: () => void;
    title: string;
    confirmUnpublish: () => void;
    loading: boolean;
}

export default function UnpublishSellerListing(props: IProps) {
    return (

        <Modal show={props?.isOpen} className="createCompanypopup forStep5custom modalDevinsider">
            <Modal.Header>
                <div className='headerpopupCompany noStep'>
                    <h2 style={{fontSize:"1rem", color:"#656565"}} className='title'>{props?.title}</h2>
                    <button onClick={() => props?.closeModal()} style={{  padding: "1rem",
                    margin:"-1rem -1rem -1rem auto"}} className="close border-0"><span aria-hidden="true">x</span>
                    </button>
                </div>
            </Modal.Header>
            <Modal.Body>
                <img
                src={Img}
                alt=""
                className="mx-auto d-block"
                width={120}

                />
                <p style={{color: "#444", fontSize:"1.05rem", fontWeight: 700}} className="text-center mt-4"> Are you sure you want to unpublish your listing?</p>



                <p className="title text-center">If you continue, your listing will be removed from the matchmaking 
                results. You will not be able to access the matchmaking results while your listing is unpublished.</p>
                <p className="title text-center">Press OK to continue, or Cancel to go back to your listing.</p>

            </Modal.Body>
            <Modal.Footer>
                <div className='contentBorder formForEmail'>
                    <button
                    className='btn btn-devinsider save text-white'
                    onClick={() => props?.confirmUnpublish()}
                    >
                        {props.loading ? <Loader/> : "OK"}
                    </button>
                    <button
                    style={{marginLeft:"1rem"}}
                    className='btn btn-devinsider px-5 cancel'
                    onClick={() => props?.closeModal()}
                    >
                        Cancel
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}
import { Modal } from "react-bootstrap";
import receiptImg from "../../../resources/images/receipts.png";
import { IReceipt } from "../../../types/receipts";
import { LoaderCommon } from "../../../widget/loader";
import Pagination from "../../../widget/use-pagination/pagination";
import './receipts.scss';
import useReceipts from "./use-receipt";

type IProps = {
    show: boolean;
    setShow: (value: boolean) => void;
}

const ReceiptsListModal = (props: IProps) => {
    const providerReceipt = useReceipts(props?.show);

    return (
        <Modal
            show={props?.show}
            id="payment-modal"
            className={
                "createCompanypopup large forBillingCenter"
            }
        >
            {/* smallPopup */}
            <Modal.Header>
            <div className="headerpopupCompany noStep">
                <h2 className="title">
                    Receipts
                </h2>
                <a className="close" onClick={() => props?.setShow(false)}>
                    <span className="icon-close-pop"></span>
                </a>
            </div>
            </Modal.Header>
            <Modal.Body>
                <div className="contentBorder forBillingCenter">
                <div className="contentBorder radius p-0 receipts">
                    <div className="border-bottom">
                    <div className="row">
                        <div className="col-xl-1 col-lg-1 col-md-12 col-12 p-4 info">
                            <div className="title"></div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-12 col-12 p-4 info">
                            <div className="title">Date</div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-12 col-12 p-4 info">
                            <div className="title">Descriptions</div>
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-12 col-12 p-4 info">
                            <div className="title">Status</div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-12 col-12 p-4 info">
                            <div className="title">Amount</div>
                        </div>
                    </div>
                    </div>
                    {providerReceipt?.loaderReceipt && <LoaderCommon/>}
                    {!providerReceipt?.loaderReceipt &&
                        <>
                            { providerReceipt?.receipt && providerReceipt?.receipt?.items?.map((el: IReceipt) => (
                                <div className="row">
                                    <div className="col-xl-1 col-lg-1 col-md-12 col-12 p-4 info">
                                    <div className="img_receipts">
                                        <a
                                            target='_blank'
                                            href={el.invoice_url}
                                        >
                                            <img
                                            src={receiptImg}
                                            alt=""
                                            style={{ cursor: "pointer" }}
                                            />
                                        </a>
                                    </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-md-12 col-12 p-4 info">
                                    <div className="">
                                        {el.paid_at.split('-')[0]}
                                    </div>
                                    <div className="transactionHours">
                                        <span className="icon-clock"></span>{el.paid_at.split('-')[1]}
                                    </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-4 col-md-12 col-12 p-4 info">
                                    <div className="">{el.product}</div>
                                    </div>
                                    <div className="col-xl-2 col-lg-2 col-md-12 col-12 p-4 info">
                                    <div className="">{el.invoice_status}</div>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-md-12 col-12 p-4 info">
                                    <div className="">{`${el.currency === 'usd' ? '$' : '€'} ${el.amount_due}${el.currency?.toUpperCase()}`}</div>
                                    </div>
                                </div>
                                ))}
        
                            <div className="pagginationCategory">
                            {providerReceipt?.receipt  &&
                                <Pagination
                                    className="pagination"
                                    currentPage={providerReceipt?.receipt.meta.pages}
                                    totalCount={providerReceipt?.receipt.meta.total_count}
                                    pageSize={5}
                                    onPageChange={(page: any) =>
                                        providerReceipt?.handleClickReceipt(page)
                                    }
                                />
                            }
                            </div>
                        </>
                    }
                </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="contentBorder formForEmail text-right" />
            </Modal.Footer>
        </Modal>
    );
};

export default ReceiptsListModal;

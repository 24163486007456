

import { ErrorBoundary } from 'react-error-boundary';
import { useGetAppointment } from '../../api/getData';
import { useKeycloakHooks } from '../../hooks/keycloak';
import Error from './error';

export default function AsideListe() {
  const { keycloak } = useKeycloakHooks();
  const { data, isSuccess } = useGetAppointment();

  return (
    <ErrorBoundary
      fallback={
        <Error
          error={'error page'}
          reset={() => {
            
          }}
        />
      }
    >
    </ErrorBoundary>
  );
}

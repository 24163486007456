import { ICompanyBasics } from "../types/company-basics";

export const initialCompanyBasics: ICompanyBasics = {
    location: "",
    company_size: "",
    founded: "",
    software_solution: [],
    targeted_industry:  [],
    featured_solution: [],
    business_model: [],
    geographic_sales_footprint:  [],
    technology_ecosystem:  [],
    have_technology: null
};
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetMyBuyerListing } from "../../api/buyer";
import { useGetTypeUser } from "../../api/user";
import { useKeycloakHooks } from "../../hooks/keycloak";

interface IProps {
    showSearch: boolean;
    handleGoToFavorite?: () => void;
    searchInput?: string;
    setSearchInput?: (value: string) => void;
    handleSearch?: () => void;
}

const urlType = {
    favorites: "/my-favorite",
    listing: "/my-listing",
    detail: "/details",
    matchmaking: "/my-matching",
    detail_matchmaking: "/detail/matching"
};

function BuyerSecondMenu(props: IProps) {
    const navigate = useNavigate();
    let { id } = useParams();

    const [showFavoriteBtn, setShowFavoriteBtn] = useState(false);
    const [showMyListingBtn, setShowMyListingBtn] = useState(false);
    const [showMatchmakingBtn, setShowMatchmakingBtn] = useState(false);
    const [enabled, setEnabled] = useState(false);

    const { keycloak, tokenInfo, initialized } = useKeycloakHooks();
    const { data: userRoles } = useGetTypeUser(tokenInfo, enabled);

    const { data: myBuyerListing } = useGetMyBuyerListing(enabled);

    useEffect(() => {
        if (tokenInfo?.user_uuid) setEnabled(true);
        if (userRoles && myBuyerListing) setEnabled(false);
    }, [tokenInfo?.user_uuid, userRoles, myBuyerListing])

    useEffect(() => {
        if (initialized && keycloak.authenticated && userRoles) {
            if (!userRoles?.isBuyer) {
                setShowFavoriteBtn(false);
                setShowMyListingBtn(false);
            } else {
                setShowFavoriteBtn(true);
                setShowMyListingBtn(true);
            }
        } else {
            setShowFavoriteBtn(false);
            setShowMyListingBtn(false);
        }
    }, [userRoles]);

    useEffect(() => {
        if (initialized && keycloak.authenticated && userRoles) {
            if (userRoles?.isBuyer && myBuyerListing) {
                if (myBuyerListing.ispublished && !myBuyerListing.isremoved) {
                    setShowMatchmakingBtn(true);
                } else setShowMatchmakingBtn(false);
            }

        }
    }, [initialized, keycloak.authenticated, userRoles, myBuyerListing]);

    const path = window.location.href;

    const renderHeaderActive = (type: string) => {
        if (type == "detail") return path?.includes(type) && id == myBuyerListing?.id ? "active" : "";
        return path?.includes(type) ? "active" : "";
    }

    const handleGoToMyListings = () => {
        if (keycloak?.authenticated) {
            if (userRoles && userRoles.isBuyer && myBuyerListing?.ispublished) {
                navigate("/buyer/listing/my-listing")
            } else return;
        }
        else keycloak?.login();
    }

    const handleGoToMyMatching = () => {
        if (keycloak?.authenticated) {
            if (userRoles && userRoles.isBuyer && myBuyerListing?.ispublished) {
                navigate("/buyer/listing/my-matching")
            } else return;
        }
        else keycloak?.login();
    }

    const handleGoToMyFavoriteMatching = () => {
        if (keycloak?.authenticated) {
            if (userRoles && userRoles.isBuyer && myBuyerListing?.ispublished) {
                navigate("/buyer/listing/my-favorite")
            } else return;
        }
        else keycloak?.login();
    }

    return (
        <div className="dynamicMenu secondFix">
            <div className="secondMenu">
                <div className="container mx-auto">
                    <div className="leftItemHeader justify-content-start">
                        <h2 className="title">
                            <a className="colorInherit" href="javascript:;">
                                M&A matchmaking
                            </a>
                        </h2>

                        <div className="menuDynamic bl">
                            {showFavoriteBtn &&
                                <div className="item" onClick={() => handleGoToMyFavoriteMatching()}>
                                    <a
                                        href="javascript:;"
                                        className={renderHeaderActive(urlType?.favorites)}
                                    >
                                        Favorites
                                    </a>
                                </div>
                            }
                            {showMyListingBtn &&
                                <div className="item active" onClick={() => handleGoToMyListings()}>
                                    <a
                                        href="javascript:;"
                                        className={renderHeaderActive(urlType?.listing) || (renderHeaderActive(urlType?.detail))}
                                    >
                                        My listing
                                    </a>
                                </div>
                            }
                            {showMatchmakingBtn &&
                                <div className="item" onClick={() => handleGoToMyMatching()}>
                                    <a
                                        href="javascript:;"
                                        className={renderHeaderActive(urlType?.matchmaking) || renderHeaderActive(urlType?.detail_matchmaking)}
                                    >
                                        Matchmaking
                                    </a>
                                </div>
                            }
                        </div>
                        {props?.showSearch &&
                            <div className="searchWord classified">
                                <input
                                    type="text"
                                    className="keyword"
                                    value={props?.searchInput}
                                    onChange={(e) => props?.setSearchInput && props?.setSearchInput(e.target.value)}
                                />
                                <button onClick={props?.handleSearch}>
                                    <span className="icon-search"></span>
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BuyerSecondMenu
// Rename your file to useKeycloakHooks.tsx
import { useKeycloak } from '@react-keycloak/web';
import { useEffect, useState } from 'react';
import { IToken, decodeToken } from '../utils/outils';

export const useKeycloakHooks = () => {
    const { keycloak, initialized } = useKeycloak();
    const [tokenInfo, setTokenInfo] = useState<IToken | null>(null);

    useEffect(() => {
        if (initialized) {
            if (keycloak.authenticated && keycloak.token) {
                setTokenInfo(decodeToken(keycloak.token));
            }
        }
    }, [initialized]);

    return {
        tokenInfo,
        keycloak,
        initialized
    };
};

import Modal from 'react-bootstrap/Modal';
import UserRemoveCard from '../../../api/controller/settings-ctr/billing-center/use-remove-card';
import { BtnNext } from '../../../widget/buttons/btn-next';

const RemoveCard = (props: any) => {
  const removeCardController = UserRemoveCard(props);
  return (
    <>
      <Modal
        show={props?.showRemoveCard}
        className='createCompanypopup small forRemoveCard'
      >
        <Modal.Header>
          <div className='headerpopupCompany noStep'>
            <h2 className='title'>Payment information</h2>
            <a className='close' onClick={() => props?.closePopRemoveCard()}>
              <span className='icon-close-pop'></span>
            </a>
          </div>
        </Modal.Header>
        <Modal.Body className=''>
          <div className='contentBorder forBillingCenter forPaiement registeredCard'>
            <div className='continue mb-5'>
              <span className='icon-exclamation-circle'></span>
              <p className='mb-4'>Are you sure you want to continue?</p>
              <div>
                You are about to remove the following credit/debit card:
              </div>
              <div className='cardR'>
                <p className='titleCard'>
                  {props?.cardInformation?.brand} XXXX{' '}
                  {props?.cardInformation?.last4}
                </p>
                <span className='expirationDate'>
                  Expire {props?.cardInformation?.exp_month}/
                  {props?.cardInformation?.exp_year?.toString().charAt(2)}
                  {props?.cardInformation?.exp_year?.toString().charAt(3)}
                </span>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='contentBorder formForEmail text-right'>
            <button
              className='btn btn-devinsider cancel'
              onClick={() => props?.closePopRemoveCard()}
            >
              Cancel
            </button>
            <button
              className='btn btn-devinsider px-5 save'
              onClick={() => {
                removeCardController.handleRemoveCard();
              }}
            >
              <BtnNext
                showLoader={removeCardController?.removeCard === true}
                text='Remove card'
              />
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RemoveCard;

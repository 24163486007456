import { Modal } from 'react-bootstrap';
import { useKeycloakHooks } from '../../hooks/keycloak';
import { ReactComponent as Loader } from '../../resources/images/Rolling.svg';
import { TPropsContactSupport } from './type';

export const DATA_TOPICS = [
  {
    id: 0,
    label: "Select a topic",
    value: "",
  },
  {
    id: 1,
    label: "My plan",
    value: "My plan",
  },
  {
    id: 2,
    label: "Billing",
    value: "Billing",
  },
  {
    id: 3,
    label: "Payment",
    value: "Payment",
  },
  {
    id: 4,
    label: "Other",
    value: "Other",
  },
];

export default function ContactSupportModal(props: TPropsContactSupport) {
  const { keycloak, tokenInfo } = useKeycloakHooks();

  return (
    <Modal
      show={props?.isShowContactSupport}
      className='createCompanypopup large forStep5custom'
    >
      <form onSubmit={props?.handleSubmit(props?.submit)}>
        <Modal.Header>
          <div className='headerpopupCompany noStep'>
            <h2 className='title'>Contact support</h2>
            <a
              className='close'
              onClick={() => props?.setIsShowContactSupport(false)}
            >
              <span className='icon-close-pop'></span>
            </a>
          </div>
        </Modal.Header>
        <Modal.Body className=''>
          <div className='contentBorder forBillingCenter contact'>
            <div className='contact row'>
              <div className='name required col-6'>
                <label htmlFor=''>
                  First name
                  {props.errors?.firstName && (
                    <div className='container-error pl-2 d-inline'>
                      {/* change d-inline on d-none */}
                      <span className='error-red'> error </span>
                      <span className='txt-error'>This field is required</span>
                    </div>
                  )}
                </label>
                <input
                  type='text'
                  defaultValue={tokenInfo?.firstname}
                  className='form-control'
                  {...props?.register('firstName', {
                    required: props?.required,
                  })}
                ></input>
              </div>
              <div className='name required col-6'>
                <label htmlFor=''>
                  Last name
                  {props.errors?.lastName && (
                    <div className='container-error pl-2 d-inline'>
                      {/* change d-inline on d-none */}
                      <span className='error-red'> error </span>
                      <span className='txt-error'>This field is required</span>
                    </div>
                  )}
                </label>
                <input
                  type='text'
                  className='form-control'
                  defaultValue={tokenInfo?.lastname}
                  {...props?.register('lastName', {
                    required: props?.required,
                  })}
                />
              </div>
              <div className='help required col-6'>
                <label htmlFor=''>
                  I'm looking for help with
                  {props.errors?.topics && (
                    <div className='container-error pl-2 d-inline'>
                      {/* change d-inline on d-none */}
                      <span className='error-red'> error </span>
                      <span className='txt-error'>This field is required</span>
                    </div>
                  )}
                </label>
                <select
                  id='help'
                  className='form-control mt-2'
                  {...props?.register('topics', {
                    required: props?.required,
                  })}
                >
                  {DATA_TOPICS?.map(
                    (el: { id: number; label: string; value: string }) => (
                      <option key={el.id} value={el?.value}>
                        {el?.label}
                      </option>
                    )
                  )}
                </select>
              </div>
            </div>
            <br />
            <div className=' subject row'>
              <div className='help required col-12'>
                <label htmlFor=''>
                  Tell us more about how we can help
                  {props.errors?.subject && (
                    <div className='container-error pl-2 d-inline'>
                      {/* change d-inline on d-none */}
                      <span className='error-red'> error </span>
                      <span className='txt-error'>This field is required</span>
                    </div>
                  )}
                </label>
                <div className='form-group col-12 descCompany p-0'>
                  <textarea
                    {...props?.register('subject', {
                      required: props?.required,
                    })}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='contentBorder formForEmail row'>
            <div className='affiliatedPopup formForEmail text-left col-lg-6 d-block'>
              <p className="removeAttachement">
                Attachment (optional){' '}
                {props?.fileName?.[0] ? props?.fileName?.[0].name : ''}
                {
                  props?.fileName?.[0] &&
                  <span>
                    <button title="Remove attachment" id="close" type='button' onClick={() => props.removeFileUploaded()}>
                      <span className='txt'></span>
                    </button>
                  </span>
                }
              </p>
              <label style={{width:"200px"}} className='btn btn-devinsider px-5 m-0'>
                Choose file
                <input
                  className='hidden'
                  type='file'
                  id='getFile'
                  accept='image/png, image/jpeg ,application/pdf'
                  style={{ display: 'none !important' }}
                  {...props?.register('fileAttachement')}
                />
              </label>
            </div>
            <div className='formForEmail text-right text-align-right col-lg-6 d-block'>
              <p>&nbsp;</p>
              <button type='submit' className='btn btn-devinsider px-5 save mt-3'>
                {props?.loader ? <Loader /> : 'Send'}
              </button>
            </div>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

import { IMAContactDetails } from "../../../../../types/MAContactDetails";
import { ICompanyBasicsBuyer } from "../../../../../types/company-basics-buyer";
import { IMAStrategy } from "../../../../../types/MAStrategy";
import { ISubscription } from "../../../../../types/subscription";
import { IMAInterest } from "../../../../../types/MAInterest";

export const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION';
export const SET_MA_STRATEGY = 'SET_MA_STRATEGY';
export const SET_NECESSARY_INFORMATIONS = 'SET_NECESSARY_INFORMATIONS';
export const SET_MA_INTEREST = 'SET_MA_INTEREST';
export const SET_COMPANY_BASICS_BUYER = 'SET_COMPANY_BASICS_BUYER';
export const SET_CONTACT_DETAILS = 'SET_CONTACT_DETAILS';

interface ISetSubscriptionAction {
  type: typeof SET_SUBSCRIPTION;
  payload: ISubscription;
}

interface ISetMAStrategyAction {
  type: typeof SET_MA_STRATEGY;
  payload: IMAStrategy;
}

interface ISeMAInterestAction{
  type: typeof SET_MA_INTEREST;
  payload: IMAInterest;
}

interface ISetCompanyBasicsBuyerAction {
  type: typeof SET_COMPANY_BASICS_BUYER;
  payload: ICompanyBasicsBuyer;
}

interface ISETMAContactDetails {
  type: typeof SET_CONTACT_DETAILS;
  payload: IMAContactDetails;
}

export type SellerActionTypes =
  ISetSubscriptionAction |
  ISetMAStrategyAction |
  ISetCompanyBasicsBuyerAction |
  ISeMAInterestAction |
  ISETMAContactDetails;

export const setSubscriptionAction = (value: ISubscription): ISetSubscriptionAction => ({
  type: SET_SUBSCRIPTION,
  payload: value,
});

export const setMaStrategyAction = (value: IMAStrategy): ISetMAStrategyAction => ({
  type: SET_MA_STRATEGY,
  payload: value,
});


export const setMACompanybasicsBuyerAction = (value: ICompanyBasicsBuyer): ISetCompanyBasicsBuyerAction => ({
  type: SET_COMPANY_BASICS_BUYER,
  payload: value,
});

export const setMAInterestAction = (value: IMAInterest): ISeMAInterestAction => ({
  type: SET_MA_INTEREST,
  payload: value,
});

export const setContactDetailsAction = (value: IMAContactDetails): ISETMAContactDetails => ({
  type: SET_CONTACT_DETAILS,
  payload: value,
});
import { INecessaryInformations } from "../types/necessary-informations";

export const initialNecessaryInformations: INecessaryInformations = {
    summarize: "",
    lifecycle_stage: "",
    main_value: "",
    key_assets: "",
    target_audience: "",
    top_competitors: "",
    acquirer_interest: "",
};
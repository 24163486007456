import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useEffect, useState } from 'react';
import { toast } from "react-toastify";
import { PaymentBillingAddress } from '../../../components/payment/payment-billing-address/payment-billing-address';
import { PaymentCard } from '../../../components/payment/payment-card/payment-card';
import { PaymentInputErrors } from "../../../components/payment/payment-info/payment-info";
import { BtnNext } from '../../../widget/buttons/btn-next';

export type CardRegistrationInputProps = {
    setUnpaidInvoice: (invoice: {
        amount: string,
        currency: string
    }) => void
    setPaymentMethodId: (id: string) => void
    setShowConfirmPayment: (show: boolean) => void
    showButtonLoader: boolean
    setShowModalPaymentInfo: (show: boolean) => void
    setShowButtonLoader: (show: boolean) => void
    postNewCard:  (id: string) => void
}

export const CardRegistrationInput: React.FC<CardRegistrationInputProps> = (props) => {
    const stripe = useStripe();
    const elements = useElements();
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [isBusiness, setIsBusiness] = useState(true);
    const [companyName, setCompanyName] = useState('');
    const [vatNumber, setVatNumber] = useState('');
    const [currency, setCurrency] = useState('');
    const [billingAddress, setBillingAddress] = useState({
        country: '',
        city: '',
        line1: '',
        state: '',
        postal_code: '',
    });
    const [inputErrors, setInputErrors] = useState<PaymentInputErrors>({
        billingAddress: false,
        companyName: false,
        firstname: false,
        lastname: false
    });
    const [shouldValidateInputs, setShouldValidateInputs] = useState(false);

    useEffect(() => {
        if (shouldValidateInputs) {
            setInputErrors({
                firstname: firstname === '',
                lastname: lastname === '',
                companyName: companyName === '',
                billingAddress:
                    billingAddress.country === '' ||
                    billingAddress.city === '' ||
                    billingAddress.line1 === '' ||
                    billingAddress.state === '' ||
                    billingAddress.postal_code === ''
            })
        }
    }, [firstname, lastname, billingAddress, companyName, shouldValidateInputs]);

    const handleCountryChange = (value: string) => {
        if (value !== '') {
            setCurrency('');
            setBillingAddress({
                ...billingAddress,
                country: value
            });
            // getDataMicroService(
            //     url.microservice.payment.get_currency_by_country + value,
            //     {},
            //     userReducer.token)
            //     .then(response => {
            //         if (response.data.message === 'success') {
            //             setCurrency(response.data.data)
            //         }
            //         else
            //             throw response.data.data.detail;
            //     })
            //     .catch(error => {
            //         toast('Error getting country currency')
            //         console.log(error)
            //     })
        }
    }

    const isFormValid = () => {
        if (firstname === '') {
            document.getElementById('input-firstname')?.focus()
            return false;
        }
        if (lastname === '') {
            document.getElementById('input-lastname')?.focus()
            return false;
        }
        if (billingAddress.country === '') {
            document.getElementById('input-country')?.focus()
            return false;
        }
        if (billingAddress.city === '') {
            document.getElementById('input-city')?.focus()
            return false;
        }
        if (billingAddress.line1 === '') {
            document.getElementById('input-line1')?.focus()
            return false;
        }
        if (billingAddress.state === '') {
            document.getElementById('input-state')?.focus()
            return false;
        }
        if (billingAddress.postal_code === '') {
            document.getElementById('input-postal_code')?.focus()
            return false;
        }
        if (companyName === '') {
            document.getElementById('input-companyName')?.focus()
            return false;
        }

        return true;
    }

    const handleConfirm = async () => {
        setShouldValidateInputs(true);

        if (!isFormValid()) {
            return;
        }

        props.setShowButtonLoader(true)
        if (!stripe || !elements ) {
            toast('An Error occurred');
            props.setShowButtonLoader(false)
            return;
        }

        const cardElement = elements.getElement(CardElement);
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement!,
            billing_details: {
                name: firstname + ' ' + lastname,
                //à ajouter
                // email: userEmail,
                address: billingAddress
            },
            metadata: {
                has_vat: isBusiness && vatNumber !== '' ? 'true' : 'false',
                company_name: companyName,
                vat_number: vatNumber,
                currency: currency ? currency : 'usd'
            }
        });

        if (error) {
            cardElement?.focus()
            props.setShowButtonLoader(false)
            toast("Card validation failed")
            console.error('Erreur lors de la création du PaymentMethod :', error);
        } else {
            if (paymentMethod) {
                props.postNewCard(paymentMethod.id)
            } else {
                toast("An error occurred")
                props.setShowButtonLoader(false)
            }
        }
    }

    return(
        <>
            <PaymentCard
                firstname={firstname}
                lastname={lastname}
                setFirstname={setFirstname}
                setLastname={setLastname}
                inputErrors={inputErrors}/>
            <br/>

            <PaymentBillingAddress
                billingAddress={billingAddress}
                handleCountryChange={handleCountryChange}
                inputErrors={inputErrors}
                setBillingAddress={setBillingAddress}
            />

            <div className='contentCheckboxsolution ml-3 mt-5'>
                <div className="custom-control custom-checkbox">
                    <input
                        type="checkbox"
                        className="custom-control-input"
                        checked={true}
                    />
                    <label htmlFor='' className="custom-control-label">I'm purchasing as a business</label>
                </div>
            </div>
            <div className='vatInformation required'>
                <label className='mr-3 ml-3 mt-3'>
                    VAT information
                </label>
                <div    style={{marginLeft:"1rem"}}
                        className={`container-error-address pl-2 ${
                            inputErrors.companyName ? 'd-inline-block' : 'd-none'
                        }`}
                    >
                        <span className='error-red'> error </span>
                        <span className='txt-error'>Company name field is required</span>
                    </div>
                <div className='name col-6 mt-1'>
                    <input
                        id='input-companyName'
                        type='text'
                        className='form-control'
                        name='companyName'
                        placeholder='Company name'
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                    />
                </div>
                <div className='name col-6 mt-2'>
                    <input
                        type='text'
                        className='form-control'
                        name='vat'
                        placeholder='VAT number'
                        disabled={companyName === ''}
                        value={vatNumber}
                        onChange={(e) => setVatNumber(e.target.value)}
                    />
                </div>
            </div>
            <div className='contentBorder formForEmail text-right'>
                <button
                    type='button'
                    className='btn btn-devinsider px-5 save'
                    onClick={handleConfirm}
                    disabled={props.showButtonLoader}
                >
                    <BtnNext
                        text={'Save payment information'}
                        showLoader={props.showButtonLoader}
                    />
                </button>
            </div>
        </>
    )
}
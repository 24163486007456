import { ChangeEvent, useEffect, useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import { isValidPhoneNumber } from "react-phone-number-input";
import 'react-phone-number-input/style.css';
import BuyerStep from "../../../../../components/buyer/buyer-step";
import { IMAContactDetails } from "../../../../../types/MAContactDetails";
import { contact } from "../../../../../utils/country_phone";

type IProps = {
    handleNextStep: (step: number) => void;
    showCancelModal: () => void;
    setData: (data: IMAContactDetails) => void;
    data: IMAContactDetails;
    validateSteps: Array<number>;
    currentStep: number;
    setCurrentStep: (step: number) => void;
    removeStepInValidated: (step: number) => void;
    addStepToValidated: (step: number) => void;
};

export default function MAContactDetails(props: IProps) {
    const [formState, setFormState] = useState(props?.data);

    let saveFirstname: string | undefined;
    let saveLastname: string | undefined;
    let saveLinkedinurl: string | undefined;
    let saveEmail: string | undefined;

    if ('FirstName' in formState && typeof formState['FirstName'] === 'string') {
        saveFirstname = formState['FirstName'];
    }
    if ('LastName' in formState && typeof formState['LastName'] === 'string') {
        saveLastname = formState['LastName'];
    }
    if ('Linkedin' in formState && typeof formState['Linkedin'] === 'string') {
        saveLinkedinurl = formState['Linkedin'];
    }
    if ('Email' in formState && typeof formState['Email'] === 'string') {
        saveEmail = formState['Email'];
    }
    const [dialCodeSelected, setDialCodeSelected] = useState(
        "+1"
    );

    const [selected, setSelected] = useState(
        "US"
    );

    const isValidLinkedinUrl = (url: string) => {
        const regex = /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[a-zA-Z0-9_-]+(\/)?$/;
        return regex.test(url);
    }

    const updateState = (property: string, value: any) => {
        setFormState((prevState: IMAContactDetails) => {

            const updatedState = {
                ...prevState,
                [property]: value,
            };
            props?.setData(updatedState);
            return updatedState;
        });
    };

    const handleSelect = (countryCode: any) => {
        setSelected(countryCode);
        const dialCode = contact?.find(
            (el: any) => el.code == countryCode
        )?.dial_code;
        setDialCodeSelected(dialCode ? dialCode : "");
        updateState("DialCode", dialCode ? dialCode : "");
        updateState("Country", countryCode || "");
    };


    const [phoneNumber, setPhoneNumber] = useState('');
    
    const handlePhoneChange = (value: string) => {
        setPhoneNumber(value);
    };

    const [firstNameError, setFirstNameError] = useState('');
    const [lastNameError, setLastNameError] = useState('');
    const [linkedinUrlError, setLinkedinUrlError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState('');


    const [firstName, setFirstname] = useState(saveFirstname || '');
    const [lastName, setLastname] = useState(saveLastname || '');
    const [email, setEmail] = useState(saveEmail || '');
    const [linkedinurl, setLinkedinurl] = useState(saveLinkedinurl || '');

    useEffect(() => {
        if (firstName && firstName.trim() !== '') {
            setFirstNameError('');
        }

        if (lastName && lastName.trim() !== '') {
            setLastNameError('');
        }

        if (linkedinurl && linkedinurl.trim() !== '' && isValidLinkedinUrl(linkedinurl)) {
            setLinkedinUrlError('');
        }

        if (email && email.trim() !== '') {
            setEmailError('');
        }

        if (phoneNumber && phoneNumber.trim() !== '') {
            setPhoneNumberError('');
        }
    }, [firstName, lastName, linkedinurl, email, phoneNumber]);

    const validateForm = () => {
        let hasErrors = false;

        if (!firstName || firstName.trim() === '') {
            setFirstNameError('ERROR This field is required');
            hasErrors = true;
        }

        if (!lastName || lastName.trim() === '') {
            setLastNameError(' ERROR This field is required');
            hasErrors = true;
        }
        if (!linkedinurl || linkedinurl.trim() === '') {
            setLinkedinUrlError(' ERROR This field is required');
            hasErrors = true;
        }
        else if (!isValidLinkedinUrl(linkedinurl)) {

            setLinkedinUrlError(' ERROR Invalid LinkedIn URL. eg:https://www.linkedin.com/in/username');
            hasErrors = true;

        }


        if (!email || email.trim() === '') {
            setEmailError(' ERROR This field is required');
            hasErrors = true;
        }
        else if (!isValidEmail(email)) {
            setEmailError('Invalid email. Please enter a valid email.');
            hasErrors = true;
        }


        if (!phoneNumber || phoneNumber.trim() === '') {
            setPhoneNumberError(' ERROR This field is required');
            hasErrors = true;
        }
        else if (!isValidPhoneNumber(contact?.find((el: any) => el.code == selected)?.dial_code + phoneNumber)) {
            setPhoneNumberError('Invalid phone number. Please enter a valid phone number.');
            hasErrors = true;
        }


        return hasErrors;
    }
    const [selectedFlag, setSelectedFlag] = useState("US");

    const isValidEmail = (value: string) => {

        return /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(value);
    }

    // const isValidPhoneNumber = (value: string) => {

    //     return /\+\d{1,4}[-.\s]?\(?\d{1,}\)?[-.\s]?\d{1,}[-.\s]?\d{1,}$/.test(value);
    // }

    const goToNextStep = (step: number, isGoingNextStep: boolean) => {
        const stepHasErrors = validateForm();
        if (stepHasErrors) {
            if (isGoingNextStep) props?.removeStepInValidated(props?.currentStep);
            return;
        }

        props?.addStepToValidated(props?.currentStep);
        props?.setData(formState);
        props?.handleNextStep(step);
    }

    useEffect(() => {
        const selectedFields = ['FirstName', 'LastName', 'Linkedin', 'Email', 'PhoneNumber', 'DialCode', 'Country'];

        selectedFields.forEach((field) => {
            const value = formState[field as keyof IMAContactDetails];

            switch (field) {
                case 'FirstName':
                    setFirstname(value);

                    break;
                case 'LastName':
                    setLastname(value);

                    break;
                case 'Linkedin':
                    setLinkedinurl(value);

                    break;
                case 'Email':
                    setEmail(value);

                    break;
                case 'PhoneNumber':
                    setPhoneNumber(value);

                    break;
                case 'DialCode':
                    setDialCodeSelected(value ? value : "+1");
                    break;
                case 'Country':
                    setSelectedFlag(value ? value : "US");
                    setSelected(value);

                    break;
                default:
                    break;
            }
        });
    }, [formState]);



    return (
        <>
            <BuyerStep
                validatedSteps={props?.validateSteps}
                currentStep={props?.currentStep}
                handleContinue={goToNextStep}
                setCurrentStep={props?.setCurrentStep}
            />
            <div className="findIsvcontainer programContainer sellerContainer">
                <div className='scrollContainer no-scroll'>
                    <div className='contentUser acquisition'>
                        <div className='contentBorder row'>
                            <div className='form-group required mb-4 p-0'>
                                <label htmlFor=''>First name</label>
                                {
                                    firstNameError && (
                                        <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                            <span className='error-red'> ERROR </span>{" "}
                                            <span className='txt-error'>This field is required</span>
                                        </div>
                                    )
                                }

                                <input
                                    maxLength={100}
                                    value={firstName}
                                    onChange={(e) => {
                                        setFirstname(e.target.value);
                                        updateState('FirstName', e.target.value);
                                    }}
                                    className='form-control mt-2'
                                    type="text"
                                    required
                                    name=""
                                    id=""
                                />
                            </div>
                            <div className='form-group required mb-4 p-0'>
                                <label htmlFor=''>Last name</label>
                                {
                                    lastNameError && (
                                        <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                            <span className='error-red'> ERROR </span>{" "}
                                            <span className='txt-error'>This field is required</span>
                                        </div>
                                    )
                                }

                                <input
                                    maxLength={100}
                                    value={lastName}
                                    onChange={(e) => {
                                        setLastname(e.target.value);
                                        updateState('LastName', e.target.value);
                                    }}
                                    className='form-control mt-2'
                                    type="text"
                                    required
                                    name=""
                                    id=""
                                />

                            </div>
                            <div className='form-group required mb-4 p-0'>
                                <label htmlFor=''>LinkedIn URL</label>
                                {
                                    linkedinUrlError && (
                                        <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                            <span className='error-red'> {linkedinUrlError} </span>{" "}
                                        </div>
                                    )
                                }
                                <input
                                    maxLength={100}
                                    value={linkedinurl}
                                    onChange={(e) => {
                                        setLinkedinurl(e.target.value);
                                        updateState('Linkedin', e.target.value);
                                    }}
                                    className='form-control mt-2'
                                    type="text"
                                    required
                                    name=""
                                    id=""
                                />

                            </div>
                            <div className='form-group required mb-4 p-0'>
                                <label htmlFor=''>Email</label>
                                {
                                    emailError && (
                                        <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                            <span className='txt-error'>{emailError}</span>
                                        </div>
                                    )
                                }
                                <input
                                    maxLength={100}
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                        updateState('Email', e.target.value);
                                    }}
                                    className='form-control mt-2'
                                    type="email"
                                    required
                                    name=""
                                    id=""
                                />

                            </div>
                            <div className='form-group required mb-4 p-0'>
                                <label htmlFor=''>Phone number</label>
                                {
                                    phoneNumberError && (
                                        <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                            <span className='txt-error'>{phoneNumberError}</span>
                                        </div>
                                    )
                                }
                                <div className="numberphone" style={{ position: "relative" }}>
                                    <input
                                        type="text"
                                        className="phonenumber"
                                        style={{
                                            textIndent:
                                                dialCodeSelected?.length === 2
                                                    ? 35
                                                    : dialCodeSelected?.length === 3
                                                        ? 40
                                                        : dialCodeSelected?.length === 4
                                                            ? 50
                                                            : 60,
                                            paddingBottom: 5,
                                        }}
                                        value={phoneNumber}
                                        onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                                            const inputText = evt.target.value.replace(/\D/g, '');
                                            setPhoneNumber(inputText);
                                            updateState('PhoneNumber', inputText);
                                        }}
                                    />
                                    <span style={{ position: "absolute", top: 5, left: 60 }}>
                                        ({dialCodeSelected})
                                    </span>
                                    <ReactFlagsSelect
                                        className="selectFlag"
                                        selected={selectedFlag}
                                        onSelect={handleSelect}
                                        searchable={true}
                                        showSelectedLabel={false}
                                        placeholder=" "
                                    />
                                </div>


                            </div>
                        </div>
                        <div className='text-align-right stepsButtons'>
                            <button className='btn btn-devinsider cancel' onClick={() => props?.showCancelModal()}>
                                Cancel
                            </button>
                            <button className='btn btn-devinsider save' onClick={() => goToNextStep(props?.currentStep + 1, true)}>
                                Continue
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
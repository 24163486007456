"use client";

import { useEffect, useReducer, useRef, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { usePostBuyerListing } from "../../../../api/buyer";
import { useGetStaticParams } from "../../../../api/staticParams";
import { useGetTypeUser } from "../../../../api/user";
import CancelCreationModal from "../../../../components/modals/cancel-creation/cancel-creation";
import Payment from "../../../../components/payment/payment";
import { useKeycloakHooks } from "../../../../hooks/keycloak";
import { removeCachePaymentMethod, removeCacheProduct, removeCacheProductWithTax, removeCacheReceipt } from "../../../../query/removeCache";
import { IMAContactDetails } from "../../../../types/MAContactDetails";
import { IMAInterest } from "../../../../types/MAInterest";
import { IMAStrategy } from "../../../../types/MAStrategy";
import { ICompanyBasicsBuyer } from "../../../../types/company-basics-buyer";
import { ISubscription } from "../../../../types/subscription";
import "../../buyer.scss";
import { setContactDetailsAction, setMACompanybasicsBuyerAction, setMAInterestAction, setMaStrategyAction, setSubscriptionAction } from './redux/actions';
import { creationBuyerReducer, initialState } from './redux/reducers';
import CompanyBasicsBuyer from "./steps/Companybasicsbuyer";
import MAContactDetails from "./steps/MAContactDetails";
import MAInterest from "./steps/MAInterest";
import MAStrategy from "./steps/MAStrategy";
import FreeSellerSuccess from "./steps/free-seller-success";
import Subscription from "./steps/subscription";
import FreePublishFailed from "../../../../components/publish/free-publish-failed";

interface UpdatedValuesLog {
  subscriptionPlan?: ISubscription;
  MAStrategy?: IMAStrategy;
  MAInterest?: IMAInterest;
  MAContactDetails?: IMAContactDetails;
  Companybasics?: ICompanyBasicsBuyer;
}
interface DataObject {
  [key: string]: any;
}

export default function BuyerListingCreation() {
  const queryClient = useQueryClient();
  
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [isPlanConfirm, setIsPlanConfirm] = useState<boolean>(false);
  const [isPaymentProcess, setIsPaymentProcess] = useState<boolean>(false);
  const [isFreeProcess, setIsFreeProcess] = useState<boolean>(false);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [state, dispatch] = useReducer(creationBuyerReducer, initialState);
  const [stepsCount, setStepsCount] = useState<number>(5);
  const [updatedValuesLog, setUpdatedValuesLog] = useState<UpdatedValuesLog>({});
  const mut = useMutation({ mutationFn: usePostBuyerListing, retry: 0 });
  const [idParam, setIdParam] = useState<number>(0);
  const [validatedSteps, setValidatedSteps] = useState<Array<number>>([]);
  const [enableHorizontals, setEnableHorizontals] = useState(true);
  const { data: horizontalsData, isFetched: isHorizontalsFetched } = useGetStaticParams('HORIZONTALS', enableHorizontals);
  const [enableSpecificTechnologie, setEnableSpecificTechnologie] = useState(true);
  const [isFreeProcessFailed, setIsFreeProcessFailed] = useState<boolean>(false);
  const [messageFailed, setMessageFailed] = useState<string>("");

  function transformGeographicSalesFootprint(data: { [key: string]: string[] }): { region: string; countries: string[] }[] {
    return Object.entries(data).map(([region, countries]) => ({
      region,
      countries
    }));
  }
  let featuredData: object[] = [];
  let solutionData: object[] = [];
  let specific_tecnhology: object[] = [];

  if (horizontalsData && horizontalsData.data && horizontalsData.data.length > 0) {
    horizontalsData.data.forEach((item: any) => {
      if (item.attributes && item.attributes.Featured === false) {
        solutionData.push(item.attributes.Label);
      }
      else {
        featuredData.push(item.attributes.Label);
      }
    });
  }

  const goToHomePage = () => {
    navigate("/");
  };
  const [enabledGetRoles, setEnabledGetRoles] = useState<boolean>(true);

  const { keycloak, tokenInfo } = useKeycloakHooks();
  const { data: userRoles, refetch: refetchGetRoles } = useGetTypeUser(tokenInfo, enabledGetRoles);
  const [enableEcosystems, setEnableEcosystems] = useState(true);
  const { data: ecosystemsData, isFetched: isEcosystemsFetched } = useGetStaticParams('TECHNO_ECOSYSTEM', enableEcosystems);
  const topRef = useRef<HTMLDivElement>(null);

  const scrollToTop = () => {
    if (topRef.current) {
      window.scrollTo({
        top: topRef.current.offsetTop,
        behavior: 'smooth'
      });
    }
  };

  useEffect(() => {
    if (userRoles) setEnabledGetRoles(false);
    if (!keycloak.authenticated || (keycloak.authenticated && userRoles?.isBuyer)) {
      goToHomePage();
    }
  }, [keycloak.authenticated, userRoles]);
  useEffect(() => {
    if (isEcosystemsFetched) setEnableEcosystems(false);
  }, [isEcosystemsFetched]);

  const openCancelModal = () => {
    setShowCancelModal(true);
  };

  const closeCancelModal = () => {
    setShowCancelModal(false);
  };

  const handleChangeStep = (step: number) => {
    setCurrentStep(step);
    setIsPaymentProcess(false);
    setIsPlanConfirm(false);
  };

  const closePopUpFreeModal = () => {
    setIsFreeProcess(false);
  };

  const addStepToValidated = (step: number) => {
    if (validatedSteps.indexOf(step) == -1) {
      setValidatedSteps(prevState => [...prevState, step]);
    }
  };

  const removeStepInValidated = (step: number) => {
    setValidatedSteps(prevState => {
      return prevState.filter(item => item < step);
    });
  };

  const handleNextStep = async (step: number, isGoingNextStep?: boolean) => {
    if (isGoingNextStep) {
      if (currentStep === stepsCount) {
        if (!isPaymentProcess) {
          setCurrentStep(1);
          setIsPlanConfirm(true);
        }
      } else if (currentStep === 1 && isPlanConfirm && !isPaymentProcess) {
        setIsPaymentProcess(true);

      } else setCurrentStep(step);
    } else {
      setCurrentStep(step);
    }
    scrollToTop();
  };

  const setMAStrategy = (data: IMAStrategy) => {
    dispatch(setMaStrategyAction(data));
    setUpdatedValuesLog((prevLog) => ({ ...prevLog, MAStrategy: data }));
  };

  const setCompanyBasics = (data: ICompanyBasicsBuyer) => {
    dispatch(setMACompanybasicsBuyerAction(data));
    setUpdatedValuesLog((prevLog) => ({ ...prevLog, Companybasics: data }));
  };

  const setMAInterest = (data: IMAInterest) => {
    dispatch(setMAInterestAction(data));
    setUpdatedValuesLog((prevLog) => ({ ...prevLog, MAInterest: data }));
  };

  const setMAContactDetails = (data: IMAContactDetails) => {
    dispatch(setContactDetailsAction(data));
    setUpdatedValuesLog((prevLog) => ({ ...prevLog, MAContactDetails: data }));
  };

  const setSubscriptionPlan = (data: ISubscription) => {
    dispatch(setSubscriptionAction(data));
    setUpdatedValuesLog((prevLog) => ({ ...prevLog, subscriptionPlan: data }));
  };

  const leaveCreationListing = () => {
    closeCancelModal();
    navigate('/buyer/listing');
  }

  const filterAndMapValues = (data: DataObject | undefined, properties: string[]): DataObject => {
    if (!data) {
      return {};
    }

    return Object.entries(data)
      .filter(([property]) => properties.includes(property))
      .reduce((acc, [property, value]) => ({ ...acc, [property]: value }), {});
  };
  if (ecosystemsData && ecosystemsData.data && ecosystemsData.data.length > 0) {
    ecosystemsData.data.forEach((item: any) => {
      if (item.attributes) {
        specific_tecnhology.push(item.attributes.Label);
      }
    });
  }



  const handlePostListing = async (paymentId: string | null, savePaymentMethod?: boolean) => {
    const subscription = updatedValuesLog.subscriptionPlan;

    const propertiesSub = ['planType'];

    const { planType } = filterAndMapValues(subscription, propertiesSub);

    let isPremium = false;
    let contstant = "cons";

    const companyNameToSend = (state?.company_basics_buyer?.companyname !== '' && state?.company_basics_buyer?.companyname !== undefined && state?.company_basics_buyer?.companyname !== null) ? state?.company_basics_buyer?.companyname : "user_anonyma";
    const companyWebsiteToSend = (state?.company_basics_buyer?.companywebsite !== '' && state?.company_basics_buyer?.companywebsite !== undefined && state?.company_basics_buyer?.companywebsite !== undefined) ? state?.company_basics_buyer?.companywebsite : "web_url_anonyma";
    const softwareSolutionToSend = (state?.ma_interest?.software_solution !== '' && state?.ma_interest?.software_solution !== undefined && state?.ma_interest?.software_solution !== null) ? state?.ma_interest?.software_solution : [];
    const featuredSolutionToSend = (state?.ma_interest?.featured_solution !== '' && state?.ma_interest?.featured_solution !== undefined && state?.ma_interest?.featured_solution !== null) ? state?.ma_interest?.featured_solution : [];
    const preferredsoftwareSolutionToSend = (state?.company_basics_buyer?.software_solution !== '' && state?.company_basics_buyer?.software_solution !== undefined && state?.company_basics_buyer?.software_solution !== null) ? state?.company_basics_buyer?.software_solution : [];
    const preferredfeaturedSolutionToSend = (state?.company_basics_buyer?.featured_solution !== '' && state?.company_basics_buyer?.featured_solution !== undefined && state?.company_basics_buyer?.featured_solution !== null) ? state?.company_basics_buyer?.featured_solution : [];

    const filteredSoftwareSolutions = softwareSolutionToSend.filter((solution: string) =>
      solutionData.map(obj => JSON.stringify(obj)).indexOf(JSON.stringify(solution)) !== -1
    );

    const filteredPreferredSoftwareSolutions = preferredsoftwareSolutionToSend.filter((solution: string) =>
      solutionData.map(obj => JSON.stringify(obj)).indexOf(JSON.stringify(solution)) !== -1
    );

    const filteredFeaturedSolutions = featuredSolutionToSend.filter((solution: string) =>
      featuredData.map(obj => JSON.stringify(obj)).indexOf(JSON.stringify(solution)) !== -1
    );

    const filteredPreferredFeaturedSolutions = preferredfeaturedSolutionToSend.filter((solution: string) =>
      featuredData.map(obj => JSON.stringify(obj)).indexOf(JSON.stringify(solution)) !== -1
    );


    const isvBased = state?.ma_interest?.isvBased;
    let isvBasedArray: any[] = [];

    if (isvBased) {
      isvBasedArray = Array.isArray(isvBased) ? isvBased : Object.values(isvBased).find(Array.isArray) || [];
    }

    let technology_ecosystem = state?.ma_interest?.ecosystem?.map((item: { label: any; }) => item.label) || [];
   
    let specific_technology_ecosystem =technology_ecosystem.filter((value: object) => !specific_tecnhology.includes(value));


      
    const salesFootprint = state?.company_basics_buyer?.geographic_sales_footprint;
    let salesArray: { region: string; countries: unknown; }[] = [];
    if (salesFootprint) {
      salesArray = Object.entries(salesFootprint).map(([region, countries]) => {
        return {
          region: region,
          countries: countries
        };
      });
    }

    let lifecycles = state?.ma_interest?.lifecycle;


    if (planType && planType === 'opportunistic') {
      isPremium = false;
    }
    else {
      isPremium = true;
    }

    const lifecycleStages = state?.ma_interest?.lifecycle;
    const labelsArray = lifecycleStages.map((stage: { label: any; }) => stage.label);



    const dataParam = {
      idUser: tokenInfo?.sub,
      //MA Strategy
      ma_intention: state?.ma_strategy?.intentions,
      recent_acquisition: state?.ma_strategy?.acquisition,
      challenge: state?.ma_strategy?.challenge,
      ma_budget_indication: state?.ma_strategy?.acquisitionBudget,
      main_acquisition_goal: state?.ma_strategy?.mainAcquisition,

      //MA Interest
      opportunity: state?.ma_interest?.opportunity,
      lifecycle_stage: labelsArray,
      software_solution: filteredSoftwareSolutions,
      featured_solution: filteredFeaturedSolutions,
      targeted_industry: state?.ma_interest?.targeted_industry,
      location: state?.company_basics_buyer?.companyHeadCarter,
      // location: state?.ma_interest?.isvBased,
      ecosystem: technology_ecosystem,

      //MA Contact
      firstname: state?.ma_contact?.FirstName,
      lastname: state?.ma_contact?.LastName,
      linkedin_url: state?.ma_contact?.Linkedin,
      email: state?.ma_contact?.Email,
      phone_number: state?.ma_contact?.DialCode + state?.ma_contact?.PhoneNumber,

      //Company basics buyer
      founded: state?.company_basics_buyer?.founded,
      company_size: state?.company_basics_buyer?.employe,
      preferred_featured_software: filteredPreferredFeaturedSolutions,
      preferred_industries: state?.company_basics_buyer?.targeted_industry,
      preferred_software: filteredPreferredSoftwareSolutions,
      prefered_hq_location: isvBasedArray,
      company_name: companyNameToSend,
      company_url: companyWebsiteToSend,
      companyimage:"",
      company_reason: state?.company_basics_buyer?.brief,
      councilregion: contstant,
      country: state?.company_basics_buyer?.geographic_sales_footprint,


      userSpecificEcosystem: specific_technology_ecosystem,
      type: isPremium,
      paymentId,
      savePaymentMethod,
      planType: state?.subscription?.planType,
    };

    const data = await mut.mutateAsync(dataParam)
    return data?.data;
  }

  const paymentMethodCallBack = async (
    paymentMethodId: string | null,
    setLoading: (l: boolean) => void,
    savePaymentMethod?: boolean,
    setShowPaymentStatus?: (
      show: boolean,
      data: {
        status: 'success' | 'error';
        invoiceNumber?: string;
        invoiceUrl?: string;
      }
    ) => void
  ) => {
    setLoading(true);

    try {
      const datas = await handlePostListing(paymentMethodId, savePaymentMethod);
      if (datas?.success) {
        setIdParam(datas?.listing_id);
        setShowPaymentStatus && setShowPaymentStatus(true, {
          status: 'success',
          invoiceNumber: datas.invoice_number,
          invoiceUrl: datas.invoice_url
        })
      }
    } catch (error) {
      const handlePostListing = (error as any)?.response;
      if(handlePostListing?.status === 409) {
        console.log("dataaa", handlePostListing?.data);
        setIdParam(handlePostListing?.data?.id);
        setIsFreeProcessFailed(true);
        setMessageFailed("You already have a buyer listing");
      } else {
        setIdParam(handlePostListing?.data?.listing_id);

        setShowPaymentStatus && setShowPaymentStatus(true, {
          status: 'error',
        });
      }
      // const handlePostListingData = (error as any)?.response.data;
    }

    setLoading(false);
  };



  const handleStatusOk = () => {
    navigate(`/buyer/listing/details/${idParam}`);
    refetchGetRoles();
    removeCacheProductWithTax(queryClient);
    removeCacheReceipt(queryClient);
    removeCacheProduct(queryClient);
    removeCachePaymentMethod(queryClient);
  };
  type StepData = IMAStrategy | IMAInterest | IMAContactDetails | ICompanyBasicsBuyer;

  const isStepClickable = (stepNumber: number): boolean => {
    const currentStepData: StepData | undefined = [
      updatedValuesLog.MAStrategy,
      updatedValuesLog.MAInterest,
      updatedValuesLog.MAContactDetails,
      updatedValuesLog.Companybasics,
    ][stepNumber - 2];

    return !!currentStepData && Object.keys(currentStepData).length > 0;
  };

  return (
    <>
      <div className="findIsvcontainer programContainer sellerContainer" ref={topRef}>
        <div className='filterFindIsv notSecondMenu'>
          {currentStep == 1 && !isPaymentProcess &&
            <div className='contentFilter mt-4'>
              <div className="block titleLeft border-0 active">
                <span className="icon-prevbtn txtL"></span> <span className='txt'>
                  {isPlanConfirm ? "Confirm your subscription plan" : "Select your subscription plan"}
                </span>
              </div>
              {isPlanConfirm && (
                <a className="go-back-link" onClick={() => setCurrentStep(stepsCount)}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                </svg>
                  Go back</a>
              )}
            </div>
          }

          {isPaymentProcess &&
            <div className='contentFilter mt4'>
              <div
                className={`block titleLeft border-0 active`}
              >
                <span className="icon-prevbtn txtL"></span> <span className='txt'>Complete payment details </span>
              </div>
            </div>
          }
        </div>
        <div className='container mx-auto minHeightwin pt-4'>
          {currentStep === 1 && !isPaymentProcess &&
            <Subscription
              handleNextStep={handleNextStep}
              showCancelModal={openCancelModal}
              setData={setSubscriptionPlan}
              data={state.subscription}
              currentStep={currentStep}
            />}
          {currentStep === 2 &&
            <MAStrategy
              handleNextStep={handleNextStep}
              showCancelModal={openCancelModal}
              setData={setMAStrategy}
              data={state.ma_strategy}
              validateSteps={validatedSteps}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              removeStepInValidated={removeStepInValidated}
              addStepToValidated={addStepToValidated}
            />
          }
          {currentStep === 3 &&
            <MAInterest
              handleNextStep={handleNextStep}
              showCancelModal={openCancelModal}
              setData={setMAInterest}
              data={state.ma_interest}
              validateSteps={validatedSteps}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              removeStepInValidated={removeStepInValidated}
              addStepToValidated={addStepToValidated}
            />
          }
          {currentStep === 4 &&
            <MAContactDetails
              handleNextStep={handleNextStep}
              showCancelModal={openCancelModal}
              setData={setMAContactDetails}
              data={state.ma_contact}
              validateSteps={validatedSteps}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              removeStepInValidated={removeStepInValidated}
              addStepToValidated={addStepToValidated}
            />
          }
          {currentStep === 5 &&
            <CompanyBasicsBuyer
              handleNextStep={handleNextStep}
              showCancelModal={openCancelModal}
              setData={setCompanyBasics}
              data={state.company_basics_buyer}
              validateSteps={validatedSteps}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              removeStepInValidated={removeStepInValidated}
              addStepToValidated={addStepToValidated}
            />
          }
          {isPaymentProcess &&
            <Payment
              planType={state?.subscription?.planType}
              listingType="buyer"
              successCallback={paymentMethodCallBack}
              handleStatusOk={handleStatusOk}
              title={"Publish listing"}
              showTryAgain={false}
              showContinue={true}
              handleClose={handleStatusOk}
              handleCancel={openCancelModal}
            />
          }
          {isFreeProcess &&
            <FreeSellerSuccess
              show={isFreeProcess}
              handleOk={closePopUpFreeModal}
            />
          }
          {
            isFreeProcessFailed && 
            <FreePublishFailed
              show={isFreeProcessFailed}
              handleOk={handleStatusOk}
              message={messageFailed}
            />
          }
        </div>
      </div>
      <CancelCreationModal
        isOpen={showCancelModal}
        closeModal={closeCancelModal}
        confirmCancel={leaveCreationListing}
        title={'Publish listing'}
      />
    </>
  );
}
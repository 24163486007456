import { IAcquisitionOverview } from "../../../../../types/acquisition-overview";
import { ICompanyBasics } from "../../../../../types/company-basics";
import { IFinancialDetails } from "../../../../../types/financial-details";
import { INecessaryInformations } from "../../../../../types/necessary-informations";
import { ISubscription } from "../../../../../types/subscription";

export const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION';
export const SET_ACQUISITION_OVERVIEW = 'SET_ACQUISITION_OVERVIEW';
export const SET_NECESSARY_INFORMATIONS = 'SET_NECESSARY_INFORMATIONS';
export const SET_COMPANY_BASICS = 'SET_COMPANY_BASICS';
export const SET_FINANCIAL_DETAILS = 'SET_FINANCIAL_DETAILS';

interface ISetSubscriptionAction {
  type: typeof SET_SUBSCRIPTION;
  payload: ISubscription;
}

interface ISetAcquisitionOverviewAction {
  type: typeof SET_ACQUISITION_OVERVIEW;
  payload: IAcquisitionOverview;
}

interface ISetNecessaryInformationsAction {
  type: typeof SET_NECESSARY_INFORMATIONS;
  payload: INecessaryInformations;
}

interface ISetCompanyBasicsAction {
  type: typeof SET_COMPANY_BASICS;
  payload: ICompanyBasics;
}

interface ISetFinancialDetailsAction {
  type: typeof SET_FINANCIAL_DETAILS;
  payload: IFinancialDetails;
}

export type SellerActionTypes =
  ISetSubscriptionAction |
  ISetAcquisitionOverviewAction |
  ISetNecessaryInformationsAction |
  ISetCompanyBasicsAction |
  ISetFinancialDetailsAction;

export const setSubscriptionAction = (value: ISubscription): ISetSubscriptionAction => ({
  type: SET_SUBSCRIPTION,
  payload: value,
});

export const setAcquisitionOverviewAction = (value: IAcquisitionOverview): ISetAcquisitionOverviewAction => ({
  type: SET_ACQUISITION_OVERVIEW,
  payload: value,
});

export const setNecessaryInformationsAction = (value: INecessaryInformations): ISetNecessaryInformationsAction => ({
  type: SET_NECESSARY_INFORMATIONS,
  payload: value,
});

export const setCompanyBasicsAction = (value: ICompanyBasics): ISetCompanyBasicsAction => ({
  type: SET_COMPANY_BASICS,
  payload: value,
});

export const setFinancialDetailsAction = (value: IFinancialDetails): ISetFinancialDetailsAction => ({
  type: SET_FINANCIAL_DETAILS,
  payload: value,
});
import { useEffect, useState } from 'react';
import { Accordion, Form } from 'react-bootstrap';
import { useGetSolutionsFiltre } from '../api/seller';


type ISolution = {
    attributes : {
        Label: string
    }
}

type IProps = {
    featuredSolutionSelected: Array<string>;
    solutionsSelected: Array<string>;
    addFeaturedSolution: (el: ISolution) => void;
    addSoftwareSolution: (el: ISolution) => void;
    removeSolution: (el: ISolution) => void;
    removeItemSelected: (el: string) => void;
}

export default function SolutionsList(props: IProps) {
    const _ = require('lodash');

    const [enabled, setEnabled] = useState(true);

    const { data: dataSolutions, isFetched } = useGetSolutionsFiltre(enabled);

    let featuredSolutions = dataSolutions?.data?.filter((el: any) => el?.attributes?.Featured === true);
    let otherSolutions = dataSolutions?.data.filter((el: any) => el?.attributes?.Featured === false);
    let lengthOtherSolutions = otherSolutions?.length;

    let leftSolution: Array<string> = [];
    let rightSolution: Array<string>= [];

    if (lengthOtherSolutions > 0) {
        if (lengthOtherSolutions % 2 === 0) {
            leftSolution = otherSolutions?.slice(0, lengthOtherSolutions / 2);
            rightSolution = otherSolutions?.slice(lengthOtherSolutions / 2, lengthOtherSolutions + 1);
        } else {
            leftSolution = otherSolutions?.slice(0, (lengthOtherSolutions - 1) / 2 + 1);
            rightSolution = otherSolutions?.slice((lengthOtherSolutions - 1) / 2 + 1, lengthOtherSolutions + 1);
        }
    }

    let allSolutions =  _.uniq([...props?.featuredSolutionSelected, ...props?.solutionsSelected]);

    const addOrRemoveSolution = (element: any) => {
        const allSolution =  _.uniq([...props?.solutionsSelected, ...props?.featuredSolutionSelected]);
        
        const isElementSelectedInSolution = props?.solutionsSelected.includes(element?.attributes?.Label);
        const isElementSelectedInFeaturedSolution = props?.featuredSolutionSelected.includes(element?.attributes?.Label);

        let foundInSoftwareList = otherSolutions?.find((el: any) => el.attributes.Label == element.attributes.Label);
        if (foundInSoftwareList) {
            if (!isElementSelectedInSolution && allSolution.length < 3) props?.addSoftwareSolution(element);
            else if(isElementSelectedInSolution) props?.removeSolution(element);
        }

        let foundInFeaturedList = featuredSolutions?.find((el: any) => el.attributes.Label == element.attributes.Label);
        if (foundInFeaturedList) {
            if (!isElementSelectedInFeaturedSolution && allSolution.length < 3){
                props?.addFeaturedSolution(element);
            }
            else if(isElementSelectedInFeaturedSolution) props?.removeSolution(element);
        }
    }

    useEffect(() => {
        if (isFetched) setEnabled(false);
    }, [isFetched]);

    return (
        <div className='getstartedContainer largeContainer'>
            <div className='contentCheckboxsolution'>
                <div className='solutionChecked'>
                {
                    allSolutions
                    .map((el: any) => (
                        <div className='item' onClick={() => props.removeItemSelected(el)}>
                        {el}
                        <span className='icon-close'></span>
                        </div>
                    ))
                }
                </div>


                <h2 className='title'>Featured Solutions</h2>
                <div className='flex-checkbox'>
                {
                    featuredSolutions?.map((el: any, index: number) => (
                    <Form.Check
                        key={index}
                        label={el?.attributes?.Label}
                        id={el?.attributes?.Label}
                        checked={
                        props?.featuredSolutionSelected?.find(
                            (element: any) => element === el?.attributes?.Label
                        )
                            ? true
                            : false
                        }
                        onClick={() => addOrRemoveSolution(el)}
                        onChange={() => null}
                    />
                    ))
                }
                </div>
                <div className='listAccordionSolution'>
                <Accordion>
                    <div className='row'>
                    <div className='col-xl-6 col-lg-6 col-md-12 col-12 pr-xl-2 pr-lg-2 p-0'>
                        {
                            leftSolution?.map((el: any, index: number) => {
                                return (
                                    <div
                                        className={`panel-wrap active-panel ${props?.solutionsSelected?.find((element: any) =>
                                        element === el?.attributes?.Label) ? 'colored' : ''}`}
                                        onClick={() => addOrRemoveSolution(el)}
                                        key={index}
                                        id={el?.attributes?.Label}
                                    >
                                        <div className='panel-header'>
                                        <Form.Check
                                            key={index}
                                            label={el?.attributes?.Label}
                                            onChange={() => null}
                                            checked={
                                            props?.solutionsSelected?.find((element: any) =>
                                                element === el?.attributes?.Label
                                            ) ? true
                                                : false
                                            }
                                            className={`panel-toggle noicon`}
                                        />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className='col-xl-6 col-lg-6 col-md-12 col-12 pr-xl-2 pr-lg-2 p-0'>
                        {
                        rightSolution?.map((el: any, index: number) => {
                            return (
                            <div
                                className={`panel-wrap active-panel ${props?.solutionsSelected?.find((element: any) =>
                                element === el?.attributes?.Label) ? 'colored' : ''}`}
                                onClick={() => addOrRemoveSolution(el)}
                                key={index}
                                id={el?.attributes?.Label}
                            >
                                <div className='panel-header'>
                                <Form.Check
                                    key={index}
                                    label={el?.attributes?.Label}
                                    onChange={() => null}
                                    checked={
                                    props?.solutionsSelected?.find((element: any) =>
                                        element === el?.attributes?.Label
                                    ) ? true
                                        : false
                                    }
                                    className={`panel-toggle noicon`}
                                />
                                </div>
                            </div>
                            )
                        })
                        }
                    </div>
                    </div>
                </Accordion>
                </div>
            </div>
            </div>
    )
}

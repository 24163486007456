import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useGetPaymentMethod, usePostPaymentMethod } from "../../../api/payment";

interface ICardInformation {
    brand: string;
    exp_month: string;
    exp_year: string;
    last4: string;
}

const cardTxt = {
    withoutCard: {
        addOrUpdate: 'Add billing information',
        carteInfo: 'No credit/debit card registered'
    },
    withCard: {
        addOrUpdate: 'Update billing information',
        carteInfo: 'You have a registered card'
    },
}

export default function usePaymentMethodHook(
    setShowModalPaymentInfo: (show: boolean) => void
) {
    const [txtMessage, setTxtMessage] = useState({
        carteInfo: 'Loading...',
        addOrUpdate: '',
        infoSuccessfull: '',
    });

    const [enabledGetPaymentMethod, setEnabledGetPaymentMethod] = useState<boolean>(true);
    const {data: paymentMethod, isFetched: isPaymentMethodFetched, refetch: refetchPaymentMethod} = useGetPaymentMethod(enabledGetPaymentMethod);

    const mut = useMutation({ mutationFn: usePostPaymentMethod, retry: 0 });

    const [removeCard, setRemoveCard] = useState(false);
    const [showRemoveCard, setShowRemoveCard] = useState(false);
    const [loadingCardInformation, setLoadingCardInformation] = useState<boolean>(false);
    const [cardInformation, setCardInformation] = useState<ICardInformation>({
        brand: '',
        exp_month: '',
        exp_year: '',
        last4: '',
    });
    const [currentUserHasRegisteredCard, setCurrentUserHasRegisteredCard] = useState(false)
    const [unpaidInvoice, setUnpaidInvoice] = useState<{
        currency: string,
        amount: string
    }>();
    const [paymentMethodId, setPaymentMethodId] = useState<string>()
    const [showConfirmPayment, setShowConfirmPayment] = useState(false);
    const [showButtonLoader, setShowButtonLoader] = useState(false);

    const showPopRemoveCard = () => {
        setShowModalPaymentInfo(false);
        setShowRemoveCard(true);
    };
    const closePopRemoveCard = () => {
        setShowRemoveCard(false);
    };

    useEffect(() => {
        if(paymentMethod && isPaymentMethodFetched) {
            if (paymentMethod.message === 'success') {
                if (paymentMethod.data) {
                    setCurrentUserHasRegisteredCard(true)
                    setCardInformation(paymentMethod.data.card)
                    setTxtMessage({
                        ...txtMessage,
                        ...cardTxt.withCard
                    })
                } else {
                    setCurrentUserHasRegisteredCard(false)
                    setTxtMessage({
                        ...txtMessage,
                        ...cardTxt.withoutCard
                    })
                }
                setEnabledGetPaymentMethod(false);
            } else {
                throw paymentMethod.data.detail
            }
        }
    }, [paymentMethod]);

    const successUpdate = (isAdd: boolean) => {
        setCurrentUserHasRegisteredCard(isAdd);
        refetchPaymentMethod();
        setTxtMessage({
            ...txtMessage,
            ...(isAdd ? cardTxt.withCard : cardTxt.withoutCard)
        })
    }

    const postNewCard = async (paymentMethod: string) => {
        const postData = await mut.mutateAsync({ payment_method_id: paymentMethod });

        if (postData.data.message === 'success') {
            successUpdate(true);
            setShowModalPaymentInfo(false);
            setCardInformation(postData.data.data.card)
            toast("You have successfully registered your payment information.");
            setShowButtonLoader(false);
            refetchPaymentMethod();
        } else {
            toast(postData.data.data.detail);
        }
    }

    const confirmPay = () => {
        if (paymentMethodId) {
            setShowButtonLoader(true)
            postNewCard(paymentMethodId)
        }
    }

    return {
        txtMessage,
        currentUserHasRegisteredCard,
        removeCard,
        showPopRemoveCard,
        setRemoveCard,
        loadingCardInformation,
        cardInformation,
        successUpdate,
        closePopRemoveCard,
        showRemoveCard,
        unpaidInvoice,
        showConfirmPayment,
        setShowConfirmPayment,
        confirmPay,
        setUnpaidInvoice,
        setPaymentMethodId,
        postNewCard,
        showButtonLoader,
        setShowButtonLoader
    }
}
import { ChangeEvent, useEffect, useState } from "react";
import { Accordion, Modal } from "react-bootstrap";
import { useGetStaticParams } from "../../../api/staticParams";
import { ReactComponent as Loader } from '../../../resources/images/Rolling.svg';
import { renderOptions } from '../utils/renderOptions';

export default function MAIntention(props: any) {

    const getInitialState = () => {
        return {
            ...props?.data,
            ma_intention: props?.data?.ma_intention !== 'manda_deleted_manda' ? props?.data?.ma_intention : '',
            challenge: props?.data?.challenge !== 'manda_deleted_manda' ? props?.data?.challenge : ''
        };
    }

    const [formState, setFormState] = useState(getInitialState());

    useEffect(() => {
        if (props?.isOpen) {
            setFormState(getInitialState());
        }
    }, [props?.isOpen]);

    const [isUpdating, setIsUpdating] = useState<boolean>(false);

    const updateState = (property: string, value: any) => {
        setFormState((prevState: any) => ({
            ...prevState,
            [property]: value,
        }));
    }
    
    const maxLength = 450;
    const maxDisplay = 200;
    const [intentions, setIntentions] = useState(formState?.ma_intention || '');

    const [selectedOption, setSelectedOption] = useState({
        challenge: formState?.challenge || '',
    });

    const [enableGetChallenges, setEnableGetChallenges] = useState(true);
    const { data: challenge, isFetched: isChallengesFetched } = useGetStaticParams('CHALLENGES', enableGetChallenges);
    const [intentionsError, setIntentionsError] = useState('');

    useEffect(() => {
        if (isChallengesFetched) setEnableGetChallenges(false);
    }, [isChallengesFetched]);

    const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>, property: string) => {
        const value = event.target.value;
        setSelectedOption((prevState) => ({ ...prevState, [property]: value }));
    };

    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    const [isDisplayIntentions, setIsDisplayIntentions] = useState(false);
    const [isDisplayChallenge, setIsDisplayChallenge] = useState(false);
    const [challengeError, setChallengeError] = useState('');

    const handleAccordionOpen = (eventKey: string | null) => {
        if (eventKey && eventKey !== isAccordionOpen.toString()) {
            setIsAccordionOpen(true);
        }
        switch (eventKey) {
            case '0':
                setIsAccordionOpen(true);
                setIsDisplayIntentions(!isDisplayIntentions);
                setIsDisplayChallenge(false);
                break;
            case '1':
                setIsAccordionOpen(true);
                setIsDisplayChallenge(!isDisplayChallenge);
                setIsDisplayIntentions(false);
                break;
            default:
                setIsAccordionOpen(false);
                break;
        }
    };

    useEffect(() => {
        if (intentions && intentions.trim() !== '') {
            setIntentionsError('');
        }

        if (selectedOption.challenge !== undefined && selectedOption.challenge !== 'Select an option...' && selectedOption.challenge !== '') {
            setChallengeError('');
        }

    }, [intentions, selectedOption]);

    const validateForm = () => {
        let hasError = false;

        if (!formState?.ma_intention || (formState?.ma_intention && formState?.ma_intention.trim() === '')) {
            setIntentionsError(' ERROR This field is required');
            hasError = true;
        }

        if (!formState?.challenge || formState?.challenge === '' || formState?.challenge === 'Select an option...') {
            setChallengeError(' ERROR This field is required');
            hasError = true;
        }

        return hasError;
    }

    const handleCloseModal = () => {
        setFormState({ ...props?.data });
        setIsAccordionOpen(false);
        setIsDisplayChallenge(false);
        setIsDisplayIntentions(false);
        props?.closeModal();
    };

    const handleSave = async () => {
        setIsUpdating(!props?.isUpdating);
        const stepHasErrors = validateForm();
        if (stepHasErrors) {
            setIsUpdating(false);
            return
        }

        await props?.confirmSave(formState);
        setIsUpdating(false);
        setIsDisplayChallenge(false);
        setIsDisplayIntentions(false);
        setIsAccordionOpen(false);

    };
    const handleChange = (e: { target: { value: any; }; }) => {
        const inputValue = e.target.value;
        if (inputValue.length <= maxLength) {
            setIntentions(inputValue);
            updateState('ma_intention', inputValue);
        }
    };
    const spanContent = isDisplayIntentions === false   && formState?.ma_intention != ''  ? formState?.ma_intention.substring(0, maxDisplay) + "..." : "";
    return (
        <Modal show={props?.isOpen} className='createCompanypopup large forStep5custom buyer-modal'>
            <Modal.Header>
                <div className='headerpopupCompany noStep'>
                    <h2 style={{ fontSize: "1rem", color: "#656565" }} className='title'>{props?.title}</h2>

                    <button onClick={() => handleCloseModal()} className="close border-1">
                        <span className="icon-close-pop" aria-hidden="true"></span>
                    </button>
                </div>
            </Modal.Header>
            <Modal.Body className=''>

                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header onClick={() => handleAccordionOpen('0')}>
                            <div className='form-group required p-0 d-flex '>
                                <label htmlFor='' style={{ fontWeight: 600, minWidth:"110px" }}>M&A intentions</label>{" "}<h2 style={{display: "inline-block", marginLeft: "1rem"}} className="about fs-6"><span>{spanContent}</span></h2>
                                {
                                    intentionsError && (
                                        <div className="container-error pl-2 d-inline" style={{ color: 'red' }}>
                                            <span className='error-red'> {intentionsError} </span>{" "}
                                        </div>
                                    )
                                }
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                                <p> Describe your M&A intentions in 450 characters or less</p>
                            <textarea
                                id='intentions'
                                className='form-control mt-2'
                                maxLength={450}
                                value={formState?.ma_intention}
                                onChange={handleChange}
                            />
                            <p>{intentions.length}/450</p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header  onClick={() => handleAccordionOpen('1')}>
                            <div className='form-group required py-2 d-flex'>
                                <label htmlFor='' style={{ fontWeight: 600 }}>Intended acquisitions in the next 12 months:</label>{" "}<br></br><span style={{display:"inline-block", marginLeft: "1rem"}}>{isDisplayChallenge === false ? formState?.challenge : ""}</span>

                            </div>
                            {
                                challengeError && (
                                    <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                        <span className='error-red'> {challengeError} </span>{" "}
                                    </div>
                                )
                            }
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className='form-group required mb-4 p-0'>
                                <label htmlFor=''>How many techology and/or Go-to-Market challenges do you plan to solve with acquisitions in the next 12 months </label>

                                <select className='form-control mt-2' value={formState?.challenge} onChange={(e) => { handleSelectChange(e, 'challenge'); updateState('challenge', e.target.value); }}>
                                    {renderOptions(challenge?.data)}
                                </select>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Modal.Body>
            <Modal.Footer>
                <div className='contentBorder formForEmail'>
                    {!isAccordionOpen && (
                        <>
                            <button
                                style={{ marginLeft: "1rem" }}
                                className='btn btn-devinsider px-5'
                                onClick={() => handleCloseModal()}
                            >
                                Close
                            </button>
                        </>
                    )}
                    {isAccordionOpen && (
                        <>
                            <button
                                style={{ marginLeft: "1rem" }}
                                className='btn btn-devinsider px-5 cancel'
                                onClick={() => handleCloseModal()}
                            >
                                Cancel
                            </button>
                            <button
                                className='btn btn-devinsider save text-white'
                                onClick={() => handleSave()}
                            >
                                {isUpdating ? <Loader /> : "Save"}
                            </button>
                        </>
                    )}
                </div>
            </Modal.Footer >
        </Modal>
    );
}

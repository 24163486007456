import Modal from 'react-bootstrap/Modal';
import Buyer from '../../../resources/images/buyer.png';
import './enroll-buyer.scss'

type IProps = {
    show: boolean;
    handleOk?: () => void;
    continue?: () => void;
};

const EnrollBuyer = (props: IProps) => {
    return (
        <Modal
            show={props.show}
            className='createCompanypopup medium forStep5custom'
        >
            <Modal.Header>
                <div className='headerpopupCompany noStep'>
                <h2 className='title'>Enroll</h2>
                <a
                    className='close'
                    onClick={() => props.handleOk && props.handleOk()}
                >
                    <span className='icon-close-pop'></span>
                </a>
                </div>
            </Modal.Header>
            <Modal.Body className=''>
                <div className='contentBorder forBillingCenter pt-3 pb-0 text-center enr'>
                <div className='payement_success'>
                    <div className='ico'>
                    {/* <span className='icon-check-article'></span> */}
                    </div>
                    <div className="enrollImage">
                        <img
                        src={Buyer}
                        alt=""
                        />
                    </div>
                    <p className='txt mt-3 mb-1'>Enroll as a buyer</p>
                    <span>To view the details of this seller listing, enroll as a buyer.</span>
                </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className='contentBorder text-center'>
                <button
                    className='btn btn-devinsider containerBtnBtn pl-5 pr-5 mt-5'
                    onClick={() => props.continue && props.continue()}
                >
                    Enroll
                </button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default EnrollBuyer;
/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import Select from "react-select";
import { BtnNext } from '../../../widget/buttons/btn-next';

type Props = {
    prices?: {[key: string]: number}
    paymentMethod: any
    currency?: string
    setCurrency: (currency: string) => void
    getCurrencySymbol: (currency?: string) => string
    productPrice: {
        amount: number
        tax: number
        currency: string
        totalAmount: number
    }
    setProductPrice: (price: {
        amount: number
        tax: number
        currency: string
        totalAmount: number
    }) => void,
    handleCancel: () => void,
    successCallback?: (
        paymentMethodId: string | null,
        setLoading: (l: boolean) => void,
        savePaymentMethod?: boolean,
        setShowPaymentStatus?: (show: boolean, data: {status: 'success' | 'error'; invoiceNumber?: string; invoiceUrl?: string}) => void
    ) => void,
    handleStatus: (show: boolean, data: {status: 'success' | 'error'; invoiceNumber?: string; invoiceUrl?: string}) => void
}

export const ProductInfo:React.FC<Props> = ({
    prices,
    currency,
    setCurrency,
    paymentMethod,
    getCurrencySymbol,
    setProductPrice,
    productPrice,
    successCallback,
    handleStatus,
    handleCancel
}) => {
    const [showButtonLoader, setShowButtonLoader] = useState(false);

    const handleConfirm = async () => {
        setShowButtonLoader(true);
        successCallback &&
        successCallback(
            paymentMethod.id,
            setShowButtonLoader,
            false,
            handleStatus
        );
    };

    const handleCurrencyChange = (cur: string) => {
        setCurrency(cur)
        setProductPrice({
            ...productPrice,
            currency: cur,
            amount: prices ? prices[cur] : 0,
            tax: 0,
            totalAmount: prices ? prices[cur] : 0
        })
    }

    const priceOptions = prices ? Object.keys(prices).map((key) => ({
            label: getCurrencySymbol(key) + ' ' + key.toUpperCase(),
            value: key
        })
    ) : [];

    const goToTermOfUse = () => {
        const url = '/terms-and-conditions';
        const tab = window.open(url, '_blank');
        tab?.focus();
    };

    return(
        <div className='forAds p-3'>
            {prices &&
                <>
                    {priceOptions.length > 1 &&
                        <div id="currencies" className='col-3 pr-0 float-right'>
                            <Select
                                classNamePrefix='currencies'
                                options={priceOptions}
                                onChange={(el: any) => {
                                    handleCurrencyChange(el.value);
                                }}
                                value={priceOptions.find(el => el.value === currency)}
                                placeholder='Currency ...'
                            />
                        </div>
                    }
                    <div className='border radius col-12 d-flex'>
                        <h5 className='pl-0'>Total:</h5>
                        <h5 className='color-orange text-align-right pr-0'>
                            <>
                                {' '}{currency ? (getCurrencySymbol() + ' ' + prices[currency]) : 0.00}{' '}
                                {currency?.toUpperCase()}
                                <p className='small color-light-gray'>
                                    (ex. VAT)
                                </p>
                            </>
                        </h5>
                    </div>

                    {paymentMethod  && (
                        <>
                            <div className='border radius col-12 d-flex'>
                                <input
                                    type='radio'
                                    name='choice'
                                    value='PAY'
                                    checked={true}
                                />
                                <h5>
                                    {paymentMethod.card.brand && (
                                        <>{paymentMethod.card.brand}</>
                                    )}{' '}
                                    ***********
                                    {paymentMethod.card.last4 && (
                                        <>{paymentMethod.card.last4}</>
                                    )}
                                    <p className='small cdt-dbt color-light-gray mt-3'>
                                        Credit/Debit Card
                                    </p>
                                </h5>
                            </div>
                            <div className='payC'>
                                <label htmlFor=''>
                                    {`By clicking "Confirm", you agree to our`}{' '}
                                    <a onClick={() => goToTermOfUse()}>
                                        terms & conditions
                                    </a>
                                    <>
                                        . You will be automatically charged for your
                                        purchase.
                                    </>
                                </label>
                            </div>
                        </>
                    )}
                </>
            }
            <button
                className='btn btn-devinsider cancel'
                onClick={handleCancel}
                disabled={showButtonLoader}
                >
                Cancel
            </button>
            <button
                onClick={handleConfirm}
                type='submit'
                className='btn btn-devinsider px-5 save'
                disabled={showButtonLoader}
            >
                <BtnNext text={'Confirm'} showLoader={showButtonLoader} />
            </button>
        </div>
    )
};
import Modal from 'react-bootstrap/Modal';
import './forms.scss';
import IndustriesList from './industriesList';

export default function ModalIndustryList(props: any) {
    return (
        <Modal
          show={props.show}
          className='createCompanypopup large forStep5custom'
        >
          <Modal.Header>
            <div className='headerpopupCompany noStep'>
              <h2 className='title'>Select all industries that apply.</h2>
              <a className='close' onClick={() => props?.cancel()}><span className='icon-close-pop'></span></a>
            </div>
          </Modal.Header>
          <Modal.Body className='withMinHeight'>
              <IndustriesList
                  industriesSelected={props?.industrySelected}
                  addIndustry={props?.addIndustry}
                  removeIndustry={props?.removeIndustry}
              />
          </Modal.Body>
          <Modal.Footer>
            <div className='contentBorder formForEmail text-right'>
              <button
                className='btn btn-devinsider cancel'
                onClick={() => props?.cancel()}
              >
                Cancel
              </button>
              <button
                className='btn btn-devinsider px-5 save'
                onClick={() => props?.valid()}
                disabled={props?.loader}
              >
                {/* {props?.loaderTargetIndustrie ? <Loader /> : 'Save'} */}
                Save
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      );
}
import ContactSupportModal from "../../widget/contact-us-widget";
import UseContactSupportCtr from "../../widget/contact-us-widget/use-contact-us-widget";
import ContactSupportModalSuccess from "./modal-success";

const ContactSupport = () => {
    const providerUseContactUsWidget = UseContactSupportCtr();

    return (
        <>
        <div className="itemBilling">
            <h2 className="title">Contact support</h2>
            <div className="contentItem">
            <div className="itemctt">
                <p>
                <small style={{ fontSize: "14px" }}>
                    For help and support with your billing questions, get in touch
                    with our support team right now.
                </small>
                </p>
            </div>
            <div className="footerItem" style={{ fontSize: "14px" }}>
                <a
                    onClick={() =>
                        providerUseContactUsWidget?.setIsShowContactSupport(true)
                    }
                >
                    Get in touch
                </a>
            </div>
            </div>
            <ContactSupportModal {...providerUseContactUsWidget} required />
            <ContactSupportModalSuccess {...providerUseContactUsWidget} required />
        </div>
        </>
    );
};

export default ContactSupport;
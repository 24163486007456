import { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useGetTypeUser } from "../../api/user";
import { useKeycloakHooks } from "../../hooks/keycloak";
import { LoaderCommon } from "../../widget/loader";
import PlansAndBilling from "./plans-and-billing";

export default function Settings() {
    const navigate = useNavigate();

    const { isUpgrade } = useParams();

    const [activeTab, setActiveTab] = useState('billing');

    const { keycloak, tokenInfo, initialized } = useKeycloakHooks();

    const [enableGetRoles, setEnableGetRoles] = useState<boolean>(true);
    const { data: userRoles } = useGetTypeUser(tokenInfo, enableGetRoles);

    useEffect(() => {
        if(userRoles) {
            setEnableGetRoles(false);
        }
    }, [userRoles]);

    const goToHomePage = () => {
        navigate("/");
    };

    useEffect(() => {
        if(initialized && !keycloak?.authenticated){
            goToHomePage();
        }
    }, [initialized, keycloak?.authenticated]);

    return (
        <div className='settings mt-5'>
            <div className='container mx-auto'>
                <div className='contentTabSettings'>
                    <Tabs
                        defaultActiveKey={
                            activeTab === 'billing'
                            ? 'billing'
                            : 'billing'
                        }
                    >
                        <Tab eventKey='billing' title='Plans and billing'>
                            {!initialized && <LoaderCommon />}
                            {initialized && keycloak?.authenticated && <PlansAndBilling isUpgrade= {isUpgrade} />}
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    );
}

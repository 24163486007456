import * as React from "react";
import { Accordion, Form } from "react-bootstrap";
import { Geographic } from "../../types/company-basics";
import { ITransformedCountry } from "../../types/countries";

type Props = {
    geographicData: any;
    activeId: string;
    toggleActive: (value: string) => void;
    data: any;
    stateName: string;
    updateState: (name: string, value: any) => void;
    isEdit: boolean;
};

export const Countries: React.FC<Props> = (props) => {
    const [allContinentSelected, setAllContinentSelected] = React.useState<
        Array<string>
    >([]);

    const transformDataCountry = (data: any) => {
        let custom = data.countriesData.map((el: any) => {
            let temp = {
                continent: data?.continent,
                country: el && el?.Name,
                iso2: el && el?.ISO2,
            };
            return temp;
        });
        return custom;
    }

    const addCountryArray = (data: any) => {
        let dataMake: Array<Geographic> = [];
        if (data && data?.countriesData) {
            let continentItemIsSelected = allContinentSelected?.find(
                (el: any) => el === data?.continent
            );

            let dataAfterTransform = transformDataCountry(data);
            dataMake = [...dataAfterTransform];

            if (!continentItemIsSelected) {
                let dataToStore = props?.data?.filter(
                    (el: any) => el?.concil_region !== data?.continent
                );

                let transFormDataToStore = [...dataToStore, ...dataMake];

                props?.updateState(props?.stateName, transFormDataToStore);

                setAllContinentSelected([...allContinentSelected, data?.continent]);
            } else {
                props?.updateState(props?.stateName, props?.data.filter((el: any) => el?.continent !== data?.continent));

                setAllContinentSelected(
                    allContinentSelected?.filter((el: any) => el !== data?.continent)
                );
            }

        } else {
            dataMake = [data];
            let found = props?.data?.find((el: any) => el?.country === data?.country);

            if (!found) {
                props?.updateState(props?.stateName,  [...props?.data, ...dataMake]);
            } else {
                props?.updateState(props?.stateName,  props?.data?.filter((elem: any) => elem?.country !== data?.country));
            }
        }
    }
    return (
        <>
            {
                props?.geographicData && Object.values(props?.geographicData)?.map((item: any, index: number) => {
                    return (
                        <div
                            className={`${props?.activeId == index.toString() ? "panel-wrap active-panel" : "panel-wrap"}`}
                            key={index}
                        >
                            <Accordion.Item eventKey={index.toString()}>
                                <div className="panel-header">
                                    <Accordion.Header
                                        onClick={() => props?.toggleActive(index.toString())}
                                        className={
                                            `panel-toggle selectedItem ${props?.isEdit && props?.data.find((country: ITransformedCountry) => country.continent === item?.Continent) ? " continent-checked" : ""}`
                                        }
                                    >
                                    {item && item?.Continent}{" "}

                                        {props?.data?.filter(
                                            (el: any) => el?.continent === item?.Continent
                                        ).length > 1
                                        ?
                                            `( ${props?.data?.filter(
                                                (el: any) => el?.continent === item?.Continent
                                            ).length}
                                            countries selected )`
                                        : ""}

                                        {props?.data?.filter(
                                            (el: any) => el?.continent === item?.Continent
                                        ).length == 1
                                        ?
                                        "( 1 country  selected )"
                                        : ""}

                                    </Accordion.Header>
                                </div>

                                <Accordion.Body>
                                    <div className="panel-body">
                                        <Form.Check
                                            label={`Select all `}
                                            id={`all${index}`}
                                            checked={
                                                allContinentSelected?.find(
                                                (el: any) => el == item?.Continent
                                                )
                                                ? true
                                                : false
                                            }
                                            onChange={() => {}}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                addCountryArray({
                                                    continent: item?.Continent,
                                                    countriesData: item?.Countries,
                                                });
                                            }}
                                        />
                                    </div>
                                    {
                                        item &&
                                        item?.Countries &&
                                        item?.Countries?.map((pays: any, key: number) => {
                                            return (
                                                <div className="panel-body" key={key}>
                                                    <Form.Check
                                                        label={pays?.Name}
                                                        id={pays?.Name}
                                                        checked={
                                                            props?.data?.find(
                                                                (t: any) => t.country === pays?.Name
                                                            )
                                                            ? true
                                                            : false
                                                        }
                                                        onClick={() => {
                                                            addCountryArray({
                                                                country: pays?.Name,
                                                                iso2: pays?.ISO2,
                                                                continent: item?.Continent
                                                            })
                                                        }}
                                                        onChange={() => {}}
                                                    />
                                                </div>
                                            )
                                        })
                                    }
                                </Accordion.Body>
                            </Accordion.Item>

                        </div>
                    )
                })
            }
        </>
    );
};

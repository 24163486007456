import { useEffect, useState } from "react";
import { Accordion, Modal } from "react-bootstrap";
import { useMutation } from "react-query";
import { UseUpdateSeller, useGetListingById } from "../../../../api/seller";
import { ReactComponent as Loader } from '../../../../resources/images/Rolling.svg';

export default function FinancialEdit(props: any) {
    const [enabled, setEnabled] = useState(false);

    const { data, refetch } = useGetListingById(props?.idSeller, enabled);
    
    const mutUpdate = useMutation({mutationFn:UseUpdateSeller, 
        retry: 0,
        onSuccess: (data) => {
            props.setIsLoading(false);
            props.handleOk();
            props?.setData(formState);
        },
        onSettled: (data, error, variables, context) => {
            setIsAccordionOpen(false);
            refetch();
            setActiveId('');
        }
    });

    useEffect(() => {
        if (props?.idSeller) setEnabled(true);
        if (data) setEnabled(false);
    }, [data, props?.idSeller]);
    
    const currentYear = new Date().getFullYear();
    const recentYears = [currentYear, currentYear-1, currentYear-2];
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    const [activeId, setActiveId] = useState<string>("");

    console.log("ee", props?.value?.profit_mades[0]?.amount_value);

    const getInitialState = () => {
        return {
            ...props?.data,
            ownership_structure: props?.value?.structure_ownership === "manda_deleted_manda" ? "" : props?.value?.structure_ownership,
            raised_funds: props?.value?.raised_funds === "manda_deleted_manda" ? "" : props?.value?.raised_funds,
            last_quarter_revenue: props?.value?.quarter_revenue === "manda_deleted_manda" ? "" : props?.value?.quarter_revenue,
            profitability: props?.value?.profitability === "manda_deleted_manda" ? "" : props?.value?.profitability,
            total_revenue1: props?.value?.revenue_generated[0]?.amount_value !== -1 && props?.value?.revenue_generated[0]?.amount_value !== 0 ? props?.transformStateRevenue(props?.value?.revenue_generated)[0] : null,
            total_revenue2: props?.value?.revenue_generated[1]?.amount_value !== -1 && props?.value?.revenue_generated[1]?.amount_value !== 0 ? props?.transformStateRevenue(props?.value?.revenue_generated)[1] : null,
            total_revenue3: props?.value?.revenue_generated[2]?.amount_value !== -1 && props?.value?.revenue_generated[2]?.amount_value !== 0 ? props?.transformStateRevenue(props?.value?.revenue_generated)[2] : null,
            total_profit1: props?.value?.profit_mades[0]?.amount_value !== -1 && props?.value?.profit_mades[0]?.amount_value !== 0 ? props?.transformStateRevenue(props?.value?.profit_mades)[0] : null,
            total_profit2: props?.value?.profit_mades[1]?.amount_value !== -1 && props?.value?.profit_mades[1]?.amount_value !== 0 ? props?.transformStateRevenue(props?.value?.profit_mades)[1] : null,
            total_profit3: props?.value?.profit_mades[2]?.amount_value !== -1 && props?.value?.profit_mades[2]?.amount_value !== 0 ? props?.transformStateRevenue(props?.value?.profit_mades)[2] : null,
        }
    }

    const [formState, setFormState] = useState(getInitialState());

    useEffect(() => {
        if(props?.show) setFormState(getInitialState());
    }, [props?.show]);
    
    function toggleActive(id: string|null) {
        if (activeId === id) {
            setActiveId("");
        } else {
            setActiveId(id ? id : '');
        }
    }

    const updateState = (property: string, value: any) => {
        setFormState((prevState: any) => ({
            ...prevState,
            [property]: value,
        }));
    }

    const save = () => {
        let revenues: any[] = [];
        let profits: any[] = [];

        for (let i = 0; i < recentYears.length; i++) {
            const year = recentYears[i];
            let totalRevenue = formState?.[`total_revenue${i + 1}`];
            let totalProfit = formState?.[`total_profit${i + 1}`];

            totalRevenue = totalRevenue !== undefined && totalRevenue !== "" ? totalRevenue : 0;
            totalProfit = totalProfit !== undefined && totalProfit !== "" ? totalProfit : 0;
 
            const revenueString = `${year}-${totalRevenue}`;
            const profitString = `${year}-${totalProfit}`;
      
            revenues.push(revenueString);
            profits.push(profitString);
        }

        if (formState?.ownership_structure === "") {
            props?.setErrorStructure(true);
        }
      
        if (formState?.raised_funds === "") {
            props?.setErrorRaisedFund(true);
        }
      
        if (formState?.last_quarter_revenue === "") {
            props?.setErrorQuarterRevenue(true);
        }
      
        if (formState?.profitability === "") {
            props?.setErrorProfitability(true);
            return;
        }
        if(formState?.ownership_structure !== "" && formState?.raised_funds !== "" && formState?.last_quarter_revenue !== "" && formState?.profitability !== "") {
            props.setIsLoading(true);
            let param = {
                idSeller: props?.idSeller,
                structure_ownership: formState?.ownership_structure,
                raised_funds: formState?.raised_funds,
                quarter_revenue: formState?.last_quarter_revenue,
                profitability: formState?.profitability,
                revenue_generated: revenues,
                profit_made: profits,
            }

            mutUpdate.mutateAsync(param);
        }
    }

    // Enlever les erreurs lors d'un modif
    useEffect(() => {
        if(formState?.ownership_structure !== "")  props?.setErrorStructure(false);
        if(formState?.raised_funds !== "")  props?.setErrorRaisedFund(false);
        if(formState?.last_quarter_revenue !== "")  props?.setErrorQuarterRevenue(false);
        if(formState?.profitability !== "")  props?.setErrorProfitability(false);
    }, [formState?.ownership_structure, props, formState?.raised_funds, formState?.last_quarter_revenue, formState?.profitability]);

    const handleAccordionOpen = (eventKey: string | null) => {
        if (eventKey && eventKey !== isAccordionOpen.toString()) {
            setIsAccordionOpen(true);
        }
    };

    const setActiveSection = (eventKey: string | null) => {
        handleAccordionOpen(eventKey);
        toggleActive(eventKey);
    };

    const handleExit = () => {
        setIsAccordionOpen(false);
        setActiveId('');
        return  props.handleOk && props.handleOk();
    }

    return (
        <Modal
            show={props.show}
            className='createCompanypopup large forStep5custom buyer-modal'
        >
            <Modal.Header>
                <div className='headerpopupCompany noStep'>
                    <h2 className='title'>Financial</h2>
                    <a
                        className='close'
                        onClick={() => !props.isLoading && handleExit()}
                    >
                        <span className='icon-close-pop'></span>
                    </a>
                </div>
            </Modal.Header>
            <Modal.Body className=''>
                <Accordion defaultActiveKey={activeId}>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header onClick={() => setActiveSection('0')}>
                            <div className='form-group required p-0 overview'>
                                <label htmlFor='' style={{fontWeight: 600, marginRight:"1rem", display: "inline-block"}}>What is the current ownership structure of the business?</label>{" "}
                                {(!isAccordionOpen ||  activeId != '0') &&  <span>{formState?.ownership_structure}</span>}
                                {
                                    props?.errorStructure && (
                                        <div className="container-error pl-2  d-inline" style={{color: 'red'}}>
                                            <span className='error-red'> ERROR </span>{" "}
                                            <span className='txt-error'>This field is required</span>
                                        </div>
                                    )
                                }
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <textarea className='form-control mt-2'
                                maxLength={500}
                                placeholder='e.g "50/50 split ownership","80% ownership, 20% outside investment..."'
                                value={formState?.ownership_structure}
                                name='ownership_structure'
                                onChange={(evt) => updateState(evt.target.name, evt.target.value)}
                            />
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="1">
                        <Accordion.Header onClick={() => setActiveSection('1')}>
                            <div className='form-group required p-0'>
                                <label htmlFor='' style={{fontWeight: 600, marginRight: "1rem"}}>How much funding have you raised?</label>{" "}
                                {(!isAccordionOpen ||  activeId != '1') && <span>{formState?.raised_funds}</span>}
                                {
                                    props?.errorRaisedFund && (
                                        <div className="container-error pl-2  d-inline" style={{color: 'red'}}>
                                            <span className='error-red'> ERROR </span>{" "}
                                            <span className='txt-error'>This field is required</span>
                                        </div>
                                    )
                                }
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <select className='form-control mt-3'
                                name='raised_funds'
                                onChange={(evt) => updateState(evt.target.name, evt.target.value)}
                            >
                                <option value="">Select an option ...</option>
                                    {
                                        props?.dataFundingRaised?.data?.map((el : any, index : any) => (
                                            <option selected={el?.attributes?.Label == formState?.raised_funds} value={el?.attributes?.Label} key={index}>{el?.attributes?.Label}</option>
                                        ))
                                    }
                            </select>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="2">
                        <Accordion.Header onClick={() => setActiveSection('2')}>
                            <div className='form-group required p-0'>
                                <label htmlFor='' style={{fontWeight: 600, marginRight: "1rem"}}>How much revenue did your business generate last quarter?</label>{" "}
                                {(!isAccordionOpen ||  activeId != '2') && <span>{formState?.last_quarter_revenue}</span>}
                                {
                                    props?.errorQuarterRevenue && (
                                        <div className="container-error pl-2  d-inline" style={{color: 'red'}}>
                                            <span className='error-red'> ERROR </span>{" "}
                                            <span className='txt-error'>This field is required</span>
                                        </div>
                                    )
                                }
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <select className='form-control mt-2'
                                name='last_quarter_revenue'
                                onChange={(evt) => updateState(evt.target.name, evt.target.value)}
                            >
                                <option value="">Select an option ...</option>
                                    {
                                        props?.dataRevenue?.data?.map((el : any, index : any) => (
                                            <option selected={el?.attributes?.Label == formState?.last_quarter_revenue} value={el?.attributes?.Label} key={index}>{el?.attributes?.Label}</option>
                                        ))
                                    }
                            </select>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="3">
                        <Accordion.Header onClick={() => setActiveSection('3')}>
                            <div className='form-group required p-0'>
                                <label htmlFor='' style={{fontWeight: 600, marginRight: "1rem"}}>How would you describe the profitability of your business?</label>{" "}
                                {(!isAccordionOpen ||  activeId != '3') && <span>{formState?.profitability}</span>}
                                {
                                    props?.errorProfitability && (
                                        <div className="container-error pl-2  d-inline" style={{color: 'red'}}>
                                            <span className='error-red'> ERROR </span>{" "}
                                            <span className='txt-error'>This field is required</span>
                                        </div>
                                    )
                                }
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <select className='form-control mt-2'
                                name='profitability'
                                onChange={(evt) => updateState(evt.target.name, evt.target.value)}
                            >
                                <option value="">Select an option ...</option>
                                    {
                                        props?.dataProfitabilityBusiness?.data?.map((el : any, index : any) => (
                                            <option selected={el?.attributes?.Label == formState?.profitability} value={el?.attributes?.Label} key={index}>{el?.attributes?.Label}</option>
                                        ))
                                    }
                            </select>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="4">
                        <Accordion.Header onClick={() => setActiveSection('4')}>
                            <div className='form-group p-0'>
                                <label htmlFor='' style={{fontWeight: 600, marginRight: "1rem"}}>What was the total revenue generated over the past 3 years?</label>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <input
                                value={formState?.total_revenue1}
                                type='text'
                                className='form-control mt-3'
                                placeholder={`${recentYears[0]}-$`}
                                name='total_revenue1'
                                onChange={(evt) => updateState(evt.target.name, evt.target.value)}
                            />
                            <input
                                value={formState?.total_revenue2}
                                type='text'
                                className='form-control mt-2'
                                placeholder={`${recentYears[1]}-$`}
                                name='total_revenue2'
                                onChange={(evt) => updateState(evt.target.name, evt.target.value)}
                            />
                            <input
                                value={formState?.total_revenue3}
                                type='text'
                                className='form-control mt-2'
                                placeholder={`${recentYears[2]}-$`}
                                name='total_revenue3'
                                onChange={(evt) => updateState(evt.target.name, evt.target.value)}
                            />
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="5">
                        <Accordion.Header onClick={() => setActiveSection('5')}>
                            <div className='form-group p-0'>
                                <label htmlFor='' style={{fontWeight: 600, marginRight: "1rem"}}>What was the total profit made in the past 3 years?</label>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <input
                                value={formState?.total_profit1}
                                type='text'
                                className='form-control mt-3'
                                placeholder={`${recentYears[0]}-$`}
                                name='total_profit1'
                                onChange={(evt) => updateState(evt.target.name, evt.target.value)}
                            />
                            <input
                                value={formState?.total_profit2}
                                type='text'
                                className='form-control mt-2'
                                placeholder={`${recentYears[1]}-$`}
                                name='total_profit2'
                                onChange={(evt) => updateState(evt.target.name, evt.target.value)}
                            />
                            <input
                                value={formState?.total_profit3}
                                type='text'
                                className='form-control mt-2'
                                placeholder={`${recentYears[2]}-$`}
                                name='total_profit3'
                                onChange={(evt) => updateState(evt.target.name, evt.target.value)}
                            />
                        </Accordion.Body>
                    </Accordion.Item>

                </Accordion>
            </Modal.Body>
            <Modal.Footer>
                <div className='text-align-right'>
                    {!isAccordionOpen && (
                        <>
                            <button
                                style={{ marginLeft: "1rem" }}
                                className='btn btn-devinsider save'
                                onClick={() => handleExit()}>
                                Close
                            </button>
                        </>
                    )}
                    {isAccordionOpen && (
                        <>
                            <button
                                style={{ marginLeft: "1rem" }}
                                className='btn btn-devinsider cancel'
                                onClick={() => !props.isLoading && handleExit()}>
                                Cancel
                            </button>
                            <button
                               className='btn btn-devinsider save'
                                onClick={() => !props.isLoading && save()}>
                            
                                {props.isLoading ? <Loader/> : "Save"}
                            </button>
                        </>
                    )}
                </div>
            </Modal.Footer>
        </Modal>
    )

}
import { useEffect, useState } from "react";
import { useKeycloakHooks } from "../../hooks/keycloak";
import { useGetTypeUser } from "../../api/user";
import SellerSecondMenu from "../../layouts/second-headers/seller";
import BuyerSecondMenu from "../../layouts/second-headers/buyer";

export default function TermAndConditions(){

    const [enabled, setEnabled] = useState(false);
    const { keycloak, tokenInfo, initialized } = useKeycloakHooks();
    const { data: userRoles } = useGetTypeUser(tokenInfo, enabled);

    useEffect(() => {
        if (keycloak?.token) setEnabled(true);
        if (userRoles) setEnabled(false);
    }, [keycloak?.token, userRoles])

    return(
        <div>
            {
                userRoles?.isSeller ? (
                    <SellerSecondMenu
                        showSearch={false}
                    />
                ) : (
                    <BuyerSecondMenu
                        showSearch={false}
                    />
                )
            }
            <section className="term-of-use">
                <div className="container mx-auto">
                    {/* {
                     txtTermOfUse &&  ReactHtmlParser(txtTermOfUse && txtTermOfUse.body)
                    }
                    <div className="short-height"></div>
                    <div className="short-height"></div> */}
                    <section className="term-of-use">
                        <div className="container mx-auto">
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <strong>
                                                <span>
                                                    <span>Devinsider Terms and Conditions</span>
                                                </span>
                                            </strong>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>1- ABOUT US</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>2- TERMS AND CONDITIONS INTRODUCTION</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>3- COMMUNITY GUIDELINES</span>
                                        </span>
                                    </span>
                                </span><br /><br />
                                <span>
                                    <span>
                                        <span>
                                            <span>3.1 Respect community members</span>
                                        </span>
                                    </span>
                                </span><br />
                                <span>
                                    <span>
                                        <span>
                                            <span>3.2 Posting of contributions</span>
                                        </span>
                                    </span>
                                </span><br />
                                <span>
                                    <span>
                                        <span>
                                            <span>3.3 Messaging other community members</span>
                                        </span>
                                    </span>
                                </span><br />
                                <span>
                                    <span>
                                        <span>
                                            <span>3.4 Respect others’ rights and privacy</span>
                                        </span>
                                    </span>
                                </span><br />
                                <span>
                                    <span>
                                        <span>
                                            <span>3.5 Moderator rights</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>4- DEVINSIDER USER OBLIGATIONS</span>
                                        </span>
                                    </span>
                                </span><br />
                                <span>
                                    <span>
                                        <span>
                                            <span>4.1 Registration and Account access</span>
                                        </span>
                                    </span>
                                </span><br />
                                <span>
                                    <span>
                                        <span>
                                            <span>4.2 Login</span>
                                        </span>
                                    </span>
                                </span><br />
                                <span>
                                    <span>
                                        <span>
                                            <span>4.3 Acceptable Usage Policy</span>
                                        </span>
                                    </span>
                                </span><br />
                                <span>
                                    <span>
                                        <span>
                                            <span>4.4 Subscription Payment</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>5- RIGHTS AND LIMITS</span>
                                        </span>
                                    </span>
                                </span><br />
                                <span>
                                    <span>
                                        <span>
                                            <span>5.1 Contribution License</span>
                                        </span>
                                    </span>
                                </span><br />
                                <span>
                                    <span>
                                        <span>
                                            <span>5.2 Intellectual Property</span>
                                        </span>
                                    </span>
                                </span><br />
                                <span>
                                    <span>
                                        <span>
                                            <span>5.3 Privacy Policy</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>6- DISCLAIMERS AND LIMITATIONS OF LIABILITY</span>
                                        </span>
                                    </span>
                                </span><br />
                                <span>
                                    <span>
                                        <span>
                                            <span>6.1 Disclaimers</span>
                                        </span>
                                    </span>
                                </span><br />
                                <span>
                                    <span>
                                        <span>
                                            <span>6.2 Limitations of liability</span>
                                        </span>
                                    </span>
                                </span><br />
                                <span>
                                    <span>
                                        <span>
                                            <span>6.3 Investor Risks</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>7- TERM &amp; TERMINATION</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>8- ASSIGNEMENT</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>9- INDEPENDENCE OF PARTIES</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>10- ENTIRE AGREEMENT</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>11- CONTACT US</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>12- LAW AND JURIDICTION</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <ol>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>ABOUT US</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                            </ol>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>Devinsider is a SaaS platform as well as any other media form, media channel, mobile website, linked, or otherwise connected thereto (collectively, the “Platform”).</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>Devinsider provides a</span>
                                            <span>social media</span>
                                            <span>platform where ISVs, OEM, investors, resellers and experts, can meet and discuss</span>
                                            <span>to grow their businesses globally.</span>
                                            <span>We leverage a community that help you to overcome business challenges. Devinsider's platform lets you access to partner programs and helps you find the resources you need.</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <ol>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>&nbsp;TERMS AND CONDITIONS INTRODUCTION</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                            </ol>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>These Terms and Conditions constitute a legally binding agreement made between you, whether personally or on behalf of an entity (“you”) and Devinsider (“we,” “us” or “our”), concerning your access to and use of the Platform.</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>You agree that by accessing the Platform, you have read, understood, and accepted these Terms and Conditions in full. If you do not agree with all or any part of these Terms and Conditions, then you are expressly prohibited from using the Platform and you must discontinue use immediately.</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>You represent and warrant that:</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <ul>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>You have the legal capacity and you agree to comply with these Terms and Conditions;</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>You are not under the minimum age of 18 years old;</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                            </ul>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>Changes to these Terms and Conditions:</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>Supplemental Terms and Conditions or documents that may be posted on the Platform from time to time are hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or modifications to these Terms and Conditions at any time and for any reason, effective from the date of publication.</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>We will alert you about any changes by updating the “Last updated” date of these Terms and Conditions, and you waive any right to receive specific notice of each such change.</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>Add-On Services:</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>You may choose to access or install additional services as part of the Platform. in which case such add-on will be incorporated by reference as part of this Agreement.</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <ol>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>COMMUNITY GUIDELINES</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                            </ol>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>The Devinsider community aims to advise and help software vendors to find solutions to their business challenges.</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>By publishing content on the Devinsider community, you agree to the terms and conditions, to be part of the community and follow in the community guidelines.</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <strong>
                                                <span>3.1 Respect community members&nbsp;</span>
                                            </strong>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>Devinsider strives to maintain a civil and friendly community for our members.</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>Devinsider will not allow posts or messages that use racism, sexism, harassment, insulting, or discriminatory statements.</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>You may question ideas and expertise of other users if it is relevant and done in a polite and non-threatening manner.</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <strong>
                                                <span>3.2 Posting of contributions</span>
                                            </strong>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>You represent and warrant that you will share content professional, relevant, and on-topic. Content you submit should be constructive and based on your honest opinion or experience.</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>Questions must be clear and contain enough information for meaningful answers to be posted. Answers that are not directly relevant to the question or are overly broad should not be posted.&nbsp;</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>Before posting, we ask you to double check if another member has already posted a question you would like to ask. Posting the same question repeatedly and starting multiple threads on the same subject can be confusing.</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>The community shall not be used to promote a business, website or other commercial activities.</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>To make this forum and the posts understandable for the majority of our members, all content must be written in English.&nbsp;&nbsp;</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <strong>
                                                <span>3.3 Messaging other community members</span>
                                            </strong>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>Make sure that all the messages you send to community members have a professional business purpose and legitimate interest in your message can be assumed. Spamming is not allowed.&nbsp;</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <strong>
                                                <span>3.4 Respect others’ rights and privacy</span>
                                            </strong>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>Make sure you have the legal rights to share information:</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <ul>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>Don’t post anything that would break any law, any contractual agreement that you have made, or nondisclosure agreements to which you are a party.</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>Don’t communicate the contact information of others without their consent.</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                            </ul>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <strong>&nbsp;</strong>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <strong>
                                                <span>3.5 Moderator rights</span>
                                            </strong>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>Devinsider has the right to edit, move, block or remove discussions, signatures or images that are in violation with these community guidelines.</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>Repeat and/or severe violation of these community guidelines may result in a profile ban.</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <ol>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>DEVINSIDER USER OBLIGATIONS</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                            </ol>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <strong>
                                                <span>4.1 Registration and Account access</span>
                                            </strong>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>You represent and warrant that:</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <ul>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>By using the Platform, all registration information you submit will be true, accurate, current, and complete;</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>You will maintain the accuracy of such information and promptly update such registration information as necessary;</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>You won’t disclose information that you do not have the consent to disclose (such as confidential information of others);</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>You will not access the Platform through automated or non-human means, whether through a bot, script, or otherwise;</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>You shall not use a false or deceptive name or email address owned or controlled by another person with the intent to impersonate that person or for any other reason;</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>
                                                    You must notify
                                                    <span>Devisinder</span>
                                                    in writing immediately if you become aware of any unauthorised use of your account;
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>You must not use any other person's account to access the Platform, unless you have that person's express permission to do so;</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>You shall be responsible for ensuring that your equipment is compatible with the Platform. You shall also be responsible for maintaining the security of the equipment, your account, your passwords and for all uses of your account.</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                            </ul>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <strong>
                                                <span>4.2 Login</span>
                                            </strong>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>By using the Platform, you represent and warrant that:</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <ul>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>You must keep your password confidential;</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>You shall not use a User ID name that is subject to any rights of a person other than yourself without appropriate authorization;</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>You must notify us in writing immediately if you become aware of any disclosure of your password;</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>You are responsible for any activity on our Platform arising out of any failure to keep your password confidential, and may be held liable for any losses arising out of such a failure.</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                            </ul>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <strong>
                                                <span>4.3 Acceptable Usage Policy</span>
                                            </strong>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>By using the Platform, You may:</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <ol>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>view pages from our Platform in a web browser;</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>supply information to us</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>print pages from our Platform</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                            </ol>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>You represent and warrant that:</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <ol>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>Except as expressly permitted by these Terms and Conditions, you must not download any material from our Platform or save any such material;</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>You may only use our Platform for your own personal and business purposes, and you must not use our Platform for any other purposes.</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>You warrant and represent that your content will comply with these Terms and Conditions.</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>You will not use the Platform in any way that is unlawful, illegal, fraudulent or harmful, or in connection with any unlawful, illegal, fraudulent or harmful purpose or activity;</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>You must ensure that all the information and content you supply to us through our Platform, or in relation to Devinsider:</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                            </ol>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>- is true, accurate, current, complete and non-misleading;</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>- must not infringe any copyright, moral right, database right, trade mark right, design right, right in passing off, or other intellectual property right</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>- must not infringe any right of confidence, right of privacy or right under data protection legislation;</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>- must not constitute negligent advice or contain any negligent statement;</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>- must not be in contempt of any court, or in breach of any court order;</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>- must not be blasphemous;</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>- must not constitute spam</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>- be offensive, deceptive, fraudulent, threatening, abusive, harassing, anti-social, menacing, hateful, discriminatory or inflammatory;</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <ol>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>You will not use any content, or other information acquired through your use of the Platform, for commercial or investment activity outside of the Platform, without prior written approval from Devinsider;</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>You will not market competing services to people you’ve identified through Devinsider (including without limitation email marketing, SMS marketing, telemarketing and direct mailing);</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>You will not conduct any systematic or automated data collection activities (including without limitation scraping, data mining, data extraction and data harvesting) on or in relation to our Platform without Devinsider express written consent;</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>You won’t copy, use, disclose or distribute any information obtained from the Platform, whether directly or through third parties (such as search engines), without the consent of Devinsider;</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>You are promising not to claim or violate any ownership or other proprietary right in any material, software or other intellectual property displayed on, published by or otherwise available through Devinsider, other than Content, software or intellectual property that you own;</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>We reserve the right to restrict access to areas of our Platform, or indeed our whole Platform, at our discretion; you must not circumvent or bypass, or attempt to circumvent or bypass, any access restriction measures on our Platform;</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>You will not reverse engineer, decompile, disassemble, decipher or otherwise attempt to derive the source code for the Platform or any related technology that is not open source;</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>You must not use our Platform in any way or take any action that causes, or may cause, damage to the Platform or impairment of the performance, availability or accessibility of the Platform;</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>You will not use our Platform to copy, store, host, transmit, send, use, publish or distribute any material which consists of (or is linked to) any spyware, computer virus, Trojan horse, worm, keystroke logger, rootkit or other malicious computer software;</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                            </ol>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <strong>
                                                <span>4.4 Subscription Payment</span>
                                            </strong>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>To extend your opportunities with Devinsider, you have the possibility to subscribe to a monthly or pay as you go plan which is accessible on our website devinsider.com, or part of an individual service offering contract provided by us.</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>All invoices shall be due no longer than 10 days after receipt.</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>If you subscribe to any of our paid offers, you agree to pay us the applicable fees and provide us information regarding your credit card or other payment instrument. Failure to pay these fees will result in the termination of your paid offers.</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>Also, you agree that:</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <ul>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>You represent and warrant to us that such information is true and that you are authorized to use the payment instrument.</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>You will promptly update your account information with any changes (for example, a change in your billing address or credit card expiration date) that may occur.</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>Your purchase may be subject to foreign exchange fees or differences in prices based on location.</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>We may store and continue billing your payment method (e.g. credit card) even after it has expired. To avoid interruptions in your services you agree to regularize your situation within 48h after notification.</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>Your payment method will automatically be charged at the start of each subscription period for the fees and taxes applicable to that period and will be renewed automatically until termination.</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>You can terminate your paid account at any time with a 4 weeks prior written notice before the end of the regular billing cycle.</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>You will be responsible for subscription fees through the end of your subscription term and no refunds will be issued if you terminate your subscription prior to the expiration of your subscription term.</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>If you terminate a subscription in the middle of a billing cycle, you will not receive a refund, unless you are terminating the subscription for any of the following reasons: we have materially breached these Terms, and failed to cure that breach within 30 days after you have so notified us in writing; or law requires a refund.</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>If you dispute any charges, you must let us know within thirty (30) days after the date that we invoices you.</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>We are entitled to terminate this agreement or suspend all accounts under that account plan in case you fail to make timely payment, or you violate these Terms of Service.</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>We reserve the right to change Devinsider’s prices. We will provide notice of the change on the Platform or in email to you, at least thirty (30) days before the change is to take effect. Your continued use of the services after the price change becomes effective constitutes your agreement to pay the changed amount.</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>All advertised prices shall be net, exclusive of value-added-tax. You are responsible for all applicable taxes and we will charge tax when required to do so.</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                            </ul>
                        
                            <ol>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>RIGHTS AND LIMITS</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                            </ol>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <strong>
                                                <span>5.1 Contribution License</span>
                                            </strong>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>As between you and Devinsider, you own the content (all works and materials) and information that you submit or post to the Platform, and you are only granting Devinsider the following non-exclusive license:</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>A worldwide, transferable and sub licensable right to use, copy, modify, distribute, publish and process, information and content that you provide through our Platform, without any further consent, notice and/or compensation to you.</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>Consequently, you grant to us the right to sub-license and the rights bring an action for infringement of the rights licensed, to the maximum extent permitted by applicable law.</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>These rights are limited in the following ways:</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <ol>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>You can end this license for specific content by deleting such content from the Platform, or generally by closing your account, except to the extent you shared it with others as part of the Platform and they copied, re-shared it or stored it and for the reasonable time it takes to remove from backup and other systems.</span>
                                            </span>
                                        </span>
                                    </span><br />
                                </li>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>We will not spread your content in advertisements for the products and services of third parties to others without your separate consent (including sponsored content). However, we have the right, without payment to you or others, to serve ads near your content and information.</span>
                                            </span>
                                        </span>
                                    </span><br />
                                </li>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>We will ask for your consent if we want to give others the right to publish your content beyond the Platform. However, if you choose to share your post as "public", we will enable a feature that allows other members to embed that public post onto third-party services, and we enable search engines to make that public content findable though their services.</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>While we may edit and make format changes to your content (such as translating or transcribing it, modifying the size, layout or file type or removing metadata), we will not modify the meaning of your expression.</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                            </ol>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <strong>
                                                <span>5.2 Devinsider Intellectual Property</span>
                                            </strong>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>Unless otherwise indicated, Devinsider is our proprietary property and all source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics on the Site (collectively, the “Content”) and the trademarks, service marks, and logos contained therein (the “Marks”) are owned or controlled by us or licensed to us, and are protected by copyright and trademark laws and various other intellectual property rights and unfair competition laws of the United States, foreign jurisdictions, and international conventions.</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>Except as expressly provided in these Terms and Conditions, no part of the Platform and no content or marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without our express prior written permission.</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>Provided that you are eligible to use the Platform, you are granted a limited license to access and use the Platform and to download or print a copy of any portion of the Content to which you have properly gained access.</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>You agree not to access the Platform by any means other than through the interface that is provided by Devinsider for use in accessing the Platform. We reserve all rights not expressly granted to you in and to the Platform, the Content and the Marks.</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <strong>
                                                <span>5.3 Privacy Policy</span>
                                            </strong>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <a>
                                                <span>As a visitor or member of our Platform or agreeing to these Terms and Conditions, the collection, use and sharing of your personal data is subject to our Privacy Policy. This Terms and Conditions refers to our Privacy Policy which also applies to your use of the Platform and which sets out the terms on which we process any personal data we collect from you, or that you provide to us.</span>
                                            </a>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>You shall use any personal data downloaded, accessed or otherwise received though the Platform solely in connection with your use of the Platform in accordance with this Terms and Conditions. You represent, warrant, and covenant that you shall process any and all personal data for this limited and specified purpose, consistent with any consent provided by the individual to whom personal data relates, and that you shall process all Personal Data in compliance with the EU and U.S. legislation.</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>You shall not, and shall not allow any third party to, present the data so that it appears to be made available by any third party;</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>You shall comply with any codes of conduct, policies or other notices we provide you or publish in connection with the Platform, and you shall promptly notify Devinsider if you learn of a security breach related to the Platform.</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>Devinsider reserves the right to use your name and logo as a reference for marketing or promotional purposes only on Devinsider Platform and in other communication with existing or potential customers.</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>You and Devinsider agree that we may access, store, process and use any information and personal data that you provide in accordance with our terms of the Privacy Policy and your choices (including settings).</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <ol>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>DISCLAIMERS AND LIMITATIONS OF LIABILITY</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                            </ol>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <strong>
                                                <span>6.1 Disclaimers</span>
                                            </strong>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>THE PLATFORM IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE PLATFORM AND YOUR USE THEREOF. WE MAKE NO WARRANTIES AS TO THE DATA OR RESULTS THAT MAY BE OBTAINED FROM USE OF THE PLATFORM, AND NO INFORMATION, ADVICE OR SERVICES OBTAINED BY YOU FROM Devinsider OR THROUGH THE PLATFORM SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THIS. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS &nbsp;OR UP TO DATE OF THE PLATFORM’S CONTENT OR THE CONTENT OF ANY WEBSITES LINKED TO THE PLATFORM AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE PLATFORM, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM Devinsider PLATFORM, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE PLATFROM BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE PLATFORM. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE PLATFORM, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE PLATFORM, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>WE DO NOT WARRANT OR REPRESENT THAT THE PLATFORM OR ANY SERVICE ON THE PLATFORM WILL REMAIN AVAILABLE. WE RESERVE THE RIGHT TO DISCONTINUE OR ALTER ANY OR ALL OF OUR PLATFORM SERVICES, AND TO STOP PUBLISHING OUR PLATFORM, AT ANY TIME IN OUR SOLE DISCRETION WITHOUT NOTICE OR EXPLANATION; AND SAVE TO THE EXTENT EXPRESSLY PROVIDED OTHERWISE IN THESE TERMS AND CONDITIONS, YOU WILL NOT BE ENTITLED TO ANY COMPENSATION OR OTHER PAYMENT UPON THE DISCONTINUANCE OR ALTERATION OF ANY PLATFORM SERVICES, OR IF WE STOP PUBLISHING THE PLATFORM.</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>THE SERVICE MAY CONTAIN LINKS TO THIRD-PARTY WEBSITES, SERVICES, SPECIAL OFFERS, OR OTHER EVENTS OR ACTIVITIES THAT ARE NOT OWNED OR CONTROLLED BY US. WE DO NOT ENDORSE OR ASSUME ANY RESPONSIBILITY FOR ANY SUCH THIRD-PARTY SITES, INFORMATION, MATERIALS, PRODUCTS, OR SERVICES. IF YOU ACCESS A THIRD PARTY WEBSITE FROM THE SERVICE, YOU DO SO AT YOUR OWN RISK, AND YOU UNDERSTAND THAT THIS TERMS AND CONDITIONS AGREEMENT DO NOT APPLY TO YOUR USE OF SUCH SITES. YOU EXPRESSLY RELIEVE&nbsp; FROM Devinsider ANY AND ALL LIABILITY ARISING FROM YOUR USE OF ANY THIRD-PARTY WEBSITE, SERVICE, OR CONTENT.</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <strong>
                                                <span>6.2 Limitations of liability</span>
                                            </strong>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>TO THE FULLEST EXTENT PERMITTED BY LAW (AND UNLESS Devinsider HAS ENTERED INTO A SEPARATE WRITTEN AGREEMENT THAT OVERRIDES THESE TERMS AND CONDITIONS), Devinsider, INCLUDING ITS AFFILIATES, WILL NOT BE LIABLE IN CONNECTION WITH THESE TERMS AND CONDITIONS FOR LOST PROFITS OR LOST BUSINESS OPPORTUNITIES, REPUTATION (E.G., OFFENSIVE OR DEFAMATORY STATEMENTS), LOSS OF DATA (E.G., DOWN TIME OR LOSS, USE OF, OR CHANGES TO, YOUR INFORMATION OR CONTENT) OR WITHOUT LIMITING THE FOREGOING, FOR DAMAGES OF ANY KIND RESULTING FROM YOUR USE OF OR INABILITY TO USE THE PLATFORM OR ANY THIRD PARTY MATERIALS, INCLUDING FROM ANY VIRUS THAT MAY BE TRANSMITTED IN CONNECTION THEREWITH OR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL OR PUNITIVE DAMAGES.</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>You understand that Devinsider, including its affiliates, will not, endorse, support, represent or guarantee the completeness, truthfulness, accuracy or reliability of any content or communications posted via the Platform. You understand that by using the Platform, you may be exposed to content that might be offensive, harmful, inaccurate or otherwise inappropriate, and that you have no claim against Devinsider for any such material.&nbsp;</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>Devinsider and its affiliates will not be liable to you in connection with these Terms and Conditions for any amount that exceeds the total fees paid or payable by you to Devinsider for the services during the term of these Terms and Conditions, if any one hundred U.S. dollars ($100).</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>You shall defend, indemnify, and hold harmless Devinsider and each of the other Devinsider Parties from and against any actions, damages, obligations, losses, liabilities, demands or expenses, including without limitation reasonable legal and accounting fees, arising or resulting from, or in connection with your breach of any of the articles of this Terms and Conditions (including without limitation violation of any intellectual property right) or your violation of any applicable law, rule or regulation. Devinsider shall provide notice to you of any such claim, suit or demand. Devinsider reserves the right to assume the exclusive defence and control of any matter which is subject to indemnification under this section. In such case, you agree to cooperate with any reasonable requests to assist Devinsider’s defence of such matter.</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>Force majeure: neither party will be liable for performance delays nor for non-performance due to causes beyond its reasonable control, except for payment obligations.</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <strong>
                                                <span>6.3 Investor Risks</span>
                                            </strong>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>
                                                <span>This site is operated by Devinsider, which is not a registered broker-dealer. Devinsider does not give investment advice, endorsement, analysis or recommendations.</span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>Investments made through the Devinsider platform are entirely at the investor's risk.</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>
                                                <span>Risk Inherent in Investments</span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>
                                                <span>Every investor should be aware that an investment in a single company or multiple companies involves a high degree of risk, regardless of whether such investment is direct or through a Fund formed for purposes of accommodating co-investment arrangement.</span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>
                                                <span>The following considerations, among others, should be carefully evaluated before making an investment in ISVs:</span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>
                                                <span>There can be no assurance that (i) ISVs will achieve its business plan, or (ii) an Investor will receive a return of any part of its investment.</span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>
                                                <span>Financial and operating risks confronting ISVs are significant (risks of change in ISVs's plans, markets and products, changing economic conditions). While targeted returns should reflect the perceived level of risk in any investment situation, such returns may never be realized and/or may not be adequate to compensate an investor or a fund for risks taken. Loss of an investor’s entire investment is possible and can easily occur. Moreover, the timing of any return on investment is highly uncertain.</span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>
                                                <span>The past performance of ISVs or its management is not predictive of future results. There can be no assurance that targeted results will be achieved. Loss of principal is possible, and even likely, on any given investment.</span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>
                                                <span>Tax Risks</span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>
                                                <span>There are many tax risks relating to investments. Each investor is urged to seek its own independent legal and tax advice and read the relevant investment documents before making an investment. Investors should consult their own professional advisors with respect to the tax consequences to them of an investment under the laws of the jurisdictions in which the investors and/or the ISVs or fund are liable for taxation.</span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>
                                                <span>Tax reporting requirements may be imposed on investors under the laws of the jurisdictions in which investors are liable for taxation or in which a fund makes investments.</span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>
                                                <span>Lack of investor control and forecasts expectations</span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>
                                                <span>The minority investors will not make decisions regarding the management, disposition or other realization of any investment made, or other decisions regarding the business and affairs of the ISVs.</span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>
                                                <span>Each ISV’s forward-looking statements are based on management's current expectations and assumptions regarding the ISV’s business and performance, the economy and other future conditions and forecasts of future events, circumstances and results. As with any projection or forecast, forward-looking statements are inherently susceptible to uncertainty and changes in circumstances.</span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>
                                                <span>Important factors that could cause the ISV’s actual results to differ materially from those in its forward-looking statements include the following non-exhaustive list (government regulation, economic, strategic, political and social conditions, future changes in technology, consumer behaviour, competitive pressures...)</span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>&nbsp;</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <ol>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>TERM &amp; TERMINATION</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                            </ol>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>The Terms and Conditions becomes effective upon the date that you are first provided with use or access to the Platform and remains in effect, (a) with respect to any free service, until you uninstall or remove such free service, or (b) with respect to paid plans executed through self-service on the Platform, for the term specified in your self-service subscription.</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>Upon expiration, the initial term will automatically renew upon expiration for the same period, unless either party provides the other with written notice of non-renewal at least thirty (30) days prior to the expiration of the then-current term.</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>If Devinsider materially breaches this Terms and Conditions, and does not cure such breach within thirty (30) days after receiving written notice from you of the breach, or Devinsider makes a material change to this Terms and Conditions that you do not accept, you have the right to terminate your subscription by sending a cancellation request to our support.</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <strong>
                                                <span>Cancellation and suspension of account</span>
                                            </strong>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to suspend temporarily or terminate your account and refuse any and all current or future use of the Platform (or any portion thereof).</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>Any suspected fraudulent, abusive or illegal activity that may be grounds for termination of your use of the Platform, may be referred to appropriate law enforcement authorities. You agree that Devinsider will not be liable to you or any third party for any termination of your access to the Platform.</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>We may terminate or suspend your account and this Terms and Conditions effective immediately upon notice to the administrative email address associated with your account if you violate any of articles of this Terms and Conditions.</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <strong>
                                                <span>Surviving content and liabilities</span>
                                            </strong>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>Both you and Devinsider may terminate your account at any time with notice to the other. On termination, you lose the right to access or use the Platform.</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>The following shall survive termination:</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <ul>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>Our rights to use and disclose your feedback;</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>Members and/or Visitors’ rights to further re-share content and information you shared through the Platform;</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>Any amounts owed by either party prior to termination remain owed after termination.</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                            </ul>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <strong>
                                                <span>Legal action following suspended account</span>
                                            </strong>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on behalf of the third party.</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>In addition to terminating or suspending your account, we reserve the right to take appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress.</span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <ol>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>ASSIGNEMENT</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                            </ol>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>
                                                <span>
                                                    <span>You hereby agree that we may assign, transfer, sub-contract or otherwise deal with our rights and/or obligations under these Terms and Conditions.</span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>
                                                <span>
                                                    <span>You may not without our prior written consent assign, transfer, sub-contract or otherwise deal with any of your rights and/or obligations under these Terms and Conditions.</span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <ol>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>INDEPENDENCE OF PARTIES</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                            </ol>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>
                                                <span>
                                                    <span>No agency, partnership, joint venture, or employment is created as a result of these Terms and Conditions and you do not have any authority of any kind under Devinsider.</span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <ol>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>ENTIRE AGREEMENT</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                            </ol>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>
                                                <span>
                                                    <span>These Terms and Conditions, shall constitute the entire agreement between you and Devinsider in relation to your use of our Platform and shall supersede any and all prior or contemporaneous written or oral agreements or understandings between you and us relating to such subject matter.</span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>
                                                <span>
                                                    <span>If an article of these Terms and Conditions is determined by any court or other competent authority to be unlawful and/or unenforceable, the other articles will continue in effect.</span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <ol>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>CONTACT US</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                            </ol>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>
                                                <span>
                                                    <span>By post:</span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>
                                                <span>
                                                    <span>Devinsider</span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>
                                                <span>
                                                    <span>23-25 rue Jean-Jacques Rousseau, Paris 75001, France;</span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>
                                                <span>
                                                    <span>Using our Platform contact form;</span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>
                                                <span>
                                                    <span>By email, using:&nbsp;contact@devinsider.com;</span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <ol>
                                <li>
                                    <span>
                                        <span>
                                            <span>
                                                <span>LAW AND JURIDICTION</span>
                                            </span>
                                        </span>
                                    </span>
                                </li>
                            </ol>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>
                                                <span>
                                                    <span>These Terms and Conditions shall be governed by and construed in accordance with French law.</span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span>
                                    <span>
                                        <span>
                                            <span>
                                                <span>
                                                    <span>In the event of disputes between the parties that they are unable to resolve through discussion, they agree to submit to the exclusive jurisdiction of the French Courts.</span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </p>
                        </div>
                    </section>
                </div>
            </section>
        </div>
    );
}

const RegisteredCard = (props: any) => {
  return (
    <div className="contentBorder row forPaiement registeredCard radius">
      {props?.cardInformation === "" ? (
        "Loading card information..."
      ) : (
        <>
          <span className="title title-registered">Registered credit card:</span>
          {props.loadingCardInformation === false ? (
            <div className="cardR">
              <p className="titleCard">
                {props?.cardInformation?.brand} XXXX{" "}
                {props?.cardInformation?.last4}
              </p>
              <span className="expirationDate">
                Expire {props?.cardInformation?.exp_month}/
                {props?.cardInformation?.exp_year?.toString().charAt(2)}
                {props?.cardInformation?.exp_year?.toString().charAt(3)}
              </span>
              {props.removeCard ? (
                <a onClick={props.showPopRemoveCard}>
                  <span className="removeCard">Remove credit card</span>
                </a>
              ) : null}
              <button onClick={() => props?.setRemoveCard(!props.removeCard)}>
                <span className="icon-inprogress others"></span>
              </button>
            </div>
          ) : (
            <> Loading...</>
          )}
        </>
      )}
    </div>
  );
};

export default RegisteredCard;

import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { BtnNext } from '../../../widget/buttons/btn-next';

type Props = {
    show: boolean;
    closeModal: () => void;
    confirm: () => void;
    invoice: {
        currency: string,
        amount: string
    };
}

export const ConfirmPay: React.FC<Props> = ({
    show,
    closeModal,
    confirm,
    invoice
}) => {
    return (
        <>
            <Modal
                show={show}
                className='createCompanypopup small forRemoveCard'
            >
                <Modal.Header>
                    <div className='headerpopupCompany noStep'>
                        <h2 className='title'>Payment required</h2>
                        <a className='close' onClick={closeModal}>
                            <span className='icon-close-pop'></span>
                        </a>
                    </div>
                </Modal.Header>
                <Modal.Body className=''>
                    <div className='contentBorder forBillingCenter forPaiement registeredCard'>
                        <div className='continue mb-5'>
                            <span className='icon-exclamation-circle'></span>
                            <p className='mb-4'>Are you sure you want to continue?</p>
                            <div>
                                By clicking 'confirm', your new credit card will be automatically charged with your current balance for the amount of
                                {' '}{invoice.currency === 'eur' ? '€ ' : '$ '}{invoice.amount + invoice.currency.toUpperCase()}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className='contentBorder formForEmail text-right'>
                        <button
                            className='btn btn-devinsider cancel'
                            onClick={closeModal}
                        >
                            Cancel
                        </button>
                        <button
                            className='btn btn-devinsider px-5 save'
                            onClick={confirm}
                        >
                            <BtnNext
                                text='Confirm'
                            />
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
};

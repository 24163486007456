import { api } from '../query/api';
import { useFetch, useFetchWithoutToken } from "../query/reactQuery";
import { urlsApi } from "./urls/apiUrl";

export const useGetBuyerListe = (data?: any) =>
    useFetchWithoutToken<any>(urlsApi.buyer.getAllList, data, {});


export const useGetBuyer = (enabled: boolean) =>
    useFetch<any>(urlsApi.buyer.getAll, {}, { enabled });


export const generateYearList = () => {
    const currentYear = new Date().getFullYear();
    const startYear = 1979;
    const years = [];

    for (let year = currentYear; year >= startYear; year--) {
        years.push(year);
    }

    return years;
};


export const useGetFiltersBuyer = (enabled: boolean) =>
    useFetchWithoutToken<any>(urlsApi.buyer.filters, {}, { enabled });

export const usePostBuyerListing = (data: any) =>
    api.post<any>(urlsApi.buyer.add_buyer, data);

export const usePostBuyerListingImage = (imagename: string) =>
    api.post<any>(urlsApi.upload.upload_image, imagename);

export const UseUnlockBuyer= (data: any) =>
    api.post<any>(urlsApi.buyer.unlock, data);
  
export const UseAddToFavorite= (data: any) =>
    api.post<any>(urlsApi.buyer.favorite, data);

export const UseGetMyBuyerMatchingListing= (data: any) =>
    api.post<any>(urlsApi.buyer.buyer_matching+'/'+data.planType, data.params);
// export const UseGetMyBuyerMatchingListing = (plantType: any,enabled: boolean,data: any) =>
//     api.post<any>(urlsApi.buyer.buyer_matching+ '/' + plantType,data, { enabled });

// /matchmaking/favorite/{id}

export const useGetListingBuyerImage = (imagename: any) =>
    useFetch<any>(urlsApi.upload.get_upload_image + '/' + imagename, {});

export const useGetListingByIdBuyer = (id: any, enabled: boolean) =>
    useFetch<any>(urlsApi.buyer.listing_by_id + '/' + id, {}, { enabled: !!id && enabled });

export const useGetListingBuyerByUser = (enabled: boolean) =>
    useFetch<any>(urlsApi.buyer.mylisting, {}, { enabled: enabled });

export const useGetUserSubscription = (enabled = true) =>
    useFetch<any>(urlsApi.buyer.get_check, {}, { enabled });

export const UseDeleteBuyerListing = (data: any) =>
    api.delete(urlsApi.buyer.delete + '/' + data.id);

export const useUnpublishListing = (data: any) =>
    api.put(urlsApi.buyer.unpublish + '/' + data.id);

export const useUpdateBuyerListing = (data: any) =>
    api.put<any>(urlsApi.buyer.update + '/' + data.id, data);

export const usePublishWithPaymentListing = (data: any) =>
    api.put(urlsApi.buyer.publish_payment, data);

export const useGetSubscription = () =>
    useFetch<any>(urlsApi.buyer.get_subscription, {}, {});

export const useEnableRenewalSubscription = () =>
    api.put<any>(urlsApi.buyer.enable_renew_subscription);

export const useCancelRenewalSubscription = () =>
    api.put<any>(urlsApi.buyer.cancel_renew_subscription);

export const useGetMyBuyerListing = (enabled: boolean) =>
    useFetch<any>(urlsApi.buyer.mylisting, {}, { enabled });

export const UseGetMyBuyerFilterListing= (data: any) =>
    api.post<any>(urlsApi.buyer.filtersMatching, data);



export const useGetMySpecificTehnologyListing = (enabled: boolean) =>
    useFetch<any>(urlsApi.buyer.myspecifictechnology, {}, { enabled });

export const useUpgradeSubscription = (data: any) =>
    api.post<any>(urlsApi.buyer.upgrade_subscription, data);

export const useGetDetailBuyerMatching = (idUser: string, enabled: boolean) =>
    useFetch<any>(urlsApi.buyer.detail_buyer_matching+"/"+idUser, {}, { enabled });
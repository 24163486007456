import { ChangeEvent, useEffect, useState } from "react";
import { IMAStrategy } from "../../../../../types/MAStrategy";

import { useGetStaticParams } from "../../../../../api/staticParams";
import BuyerStep from "../../../../../components/buyer/buyer-step";
import { renderOptions } from '../../../utils/renderOptions';

export type IProps = {
    handleNextStep: (step: number) => void;
    showCancelModal: () => void;
    setData: (data: IMAStrategy) => void;
    data: IMAStrategy;
    validateSteps: Array<number>;
    currentStep: number;
    setCurrentStep: (step: number) => void;
    removeStepInValidated: (step: number) => void;
    addStepToValidated: (step: number) => void;
};

export default function MAStrategy(props: IProps) {
    const [intentions, setIntentions] = useState('');

    const [intentionsError, setIntentionsError] = useState('');
    const [acquisitionError, setAcquisitionError] = useState('');
    const [challengeError, setChallengeError] = useState('');
    const [acquisitionBudgetError, setAcquisitionBudgetError] = useState('');
    const [mainAcquisitionError, setMainAcquisitionError] = useState('');

    const [selectedOption, setSelectedOption] = useState({
        acquisition: '',
        challenge: '',
        acquisitionBudget: '',
        mainAcquisition: '',
    });

    const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>, property: string) => {
        const value = event.target.value;
        setSelectedOption((prevState) => ({ ...prevState, [property]: value }));
    };

    const [enableGetAcquisitions, setEnableGetAcquisitions] = useState(true);
    const [enableGetChallenges, setEnableGetChallenges] = useState(true);
    const [enableGetBudgets, setEnableGetBudgets] = useState(true);
    const [enableMainAcquisitions, setEnableMainAcquisitions] = useState(true);

    const { data: acquisitionsData, isFetched: isAcquisitionsFetched } = useGetStaticParams('ACQUISITIONS', enableGetAcquisitions);
    const { data: challenge, isFetched: isChallengesFetched } = useGetStaticParams('CHALLENGES', enableGetChallenges);
    const { data: acquisitionBudget, isFetched: isBudgetsFetched } = useGetStaticParams('ACQUISITION_BUDGETS', enableGetBudgets);
    const { data: mainAcquisition, isFetched: isMainAcquisitionsFetched } = useGetStaticParams('MAIN_PURPOSES', enableMainAcquisitions);

    useEffect(() => {
        if (isAcquisitionsFetched) setEnableGetAcquisitions(false);
    }, [isAcquisitionsFetched]);

    useEffect(() => {
        if (isChallengesFetched) setEnableGetChallenges(false);
    }, [isChallengesFetched]);

    useEffect(() => {
        if (isBudgetsFetched) setEnableGetBudgets(false);
    }, [isBudgetsFetched]);

    useEffect(() => {
        if (isMainAcquisitionsFetched) setEnableMainAcquisitions(false);
    }, [isMainAcquisitionsFetched]);

    const [formState, setFormState] = useState(props?.data);

    const updateState = (property: string, value: any) => {
        setFormState((prevState: IMAStrategy) => {
            const updatedState = {
                ...prevState,
                [property]: value,
            };
            props?.setData(updatedState);
            return updatedState;
        });
    };

    useEffect(() => {
        if (intentions && intentions.trim() !== '') {
            setIntentionsError('');
        }

        if (selectedOption.acquisition !== undefined && selectedOption.acquisition !== 'Select an option...' && selectedOption.acquisition !== '' ) {
            setAcquisitionError('');
        }

        if (selectedOption.challenge !== undefined && selectedOption.challenge !== 'Select an option...'&& selectedOption.challenge !== '' ) {
            setChallengeError('');
        }

        if (selectedOption.mainAcquisition !== undefined && selectedOption.mainAcquisition !== 'Select an option...'&& selectedOption.mainAcquisition !== '' ) {
            setMainAcquisitionError('');
        }

    }, [intentions, selectedOption]);


    const goToNextStep = (step: number, isGoingNextStep: boolean) => {
        setIntentionsError('');
        setAcquisitionError('');
        setChallengeError('');
        setAcquisitionBudgetError('');
        setMainAcquisitionError('');

        let hasErrors = false;

        if(isGoingNextStep){
            if (!intentions || (intentions && intentions.trim() === '')) {
                setIntentionsError(' ERROR This field is required');
                hasErrors = true;
            }
            else if (intentions.length > 450) {
                setIntentionsError(' ERROR Exceeded the limit of 450 characters');
                hasErrors = true;
            }
    
            if (!selectedOption.acquisition || selectedOption.acquisition === '' || selectedOption.acquisition === 'Select an option...') {
                setAcquisitionError(' ERROR This field is required');
                hasErrors = true;
            }
    
            if (!selectedOption.challenge || selectedOption.challenge === '' || selectedOption.challenge === 'Select an option...') {
                setChallengeError(' ERROR This field is required');
                hasErrors = true;
            }
    
            if (!selectedOption.mainAcquisition || selectedOption.mainAcquisition === ''|| selectedOption.mainAcquisition === 'Select an option...') {
                setMainAcquisitionError(' ERROR This field is required');
                hasErrors = true;
            }
    
            if (hasErrors) {
                if(isGoingNextStep) props?.removeStepInValidated(props?.currentStep);
                return;
            }
        }

        props?.addStepToValidated(props.currentStep);
        props?.setData(formState);
        props?.handleNextStep(step);
    }

    useEffect(() => {
        const selectedFields = ['mainAcquisition', 'acquisition', 'acquisitionBudget', 'challenge', 'intentions'];

        selectedFields.forEach((field) => {
            const value = formState[field as keyof IMAStrategy];

            switch (field) {
                case 'intentions':
                    setIntentions(value);

                    break;
                case 'acquisition':
                    setSelectedOption((prevState) => ({ ...prevState, acquisition: value }));

                    break;
                case 'challenge':
                    setSelectedOption((prevState) => ({ ...prevState, challenge: value }));

                    break;
                case 'acquisitionBudget':
                    setSelectedOption((prevState) => ({ ...prevState, acquisitionBudget: value }));
                    break;
                case 'mainAcquisition':
                    setSelectedOption((prevState) => ({ ...prevState, mainAcquisition: value }));

                    break;
                default:
                    break;
            }
        });
    }, [formState]);

    return (
        <>
            <BuyerStep
                validatedSteps={props?.validateSteps}
                currentStep={props?.currentStep}
                handleContinue={goToNextStep}
                setCurrentStep={props?.setCurrentStep}
            />
            <div className="scrollContainer">
                <div className='contentUser acquisition'>
                    <div className='contentBorder row'>
                        <div className='form-group required mb-4 p-0'>
                            <label htmlFor='intentions'>
                                Describe your M&A intentions in 450 characters or less
                            </label>{""}
                            {
                                intentionsError && (
                                    <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                        <span className='error-red'> ERROR </span>{" "}
                                        <span className='txt-error'>This field is required</span>
                                    </div>
                                )
                            }
                            <textarea
                                id='intentions'
                                className='form-control mt-2'
                                maxLength={450}
                                value={intentions}
                                onChange={(e) => {
                                    setIntentions(e.target.value);
                                    updateState('intentions', e.target.value);
                                }}
                            />
                        </div>
                        <div className='form-group required mb-4 p-0'>
                            <label htmlFor=''>Specify how many acquisitions you have made in the past 5 years</label>
                            {""}
                            {
                                acquisitionError && (
                                    <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                        <span className='error-red'> ERROR </span>{" "}
                                        <span className='txt-error'>This field is required</span>
                                    </div>
                                )
                            }
                            <select className='form-control mt-2' value={selectedOption.acquisition} onChange={(e) => { handleSelectChange(e, 'acquisition'); updateState('acquisition', e.target.value); }}>
                                {renderOptions(acquisitionsData?.data)}
                            </select>
                        </div>

                        <div className='form-group required mb-4 p-0'>
                            <label htmlFor=''>How many techology and/or Go-to-Market challenges do you plan to solve with acquisitions in the next 12 months </label>      {""}
                            {
                                challengeError && (
                                    <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                        <span className='error-red'> ERROR </span>{" "}
                                        <span className='txt-error'>This field is required</span>
                                    </div>
                                )
                            }
                            <select className='form-control mt-2' value={selectedOption.challenge} onChange={(e) => { handleSelectChange(e, 'challenge'); updateState('challenge', e.target.value); }}>
                                {renderOptions(challenge?.data)}
                            </select>
                        </div>
                        <div className='form-group not-required mb-4 p-0'>
                            <label htmlFor=''>What is your overall acquistion budget? </label>
                            <select className='form-control mt-2' value={selectedOption.acquisitionBudget} onChange={(e) => { handleSelectChange(e, 'acquisitionBudget'); updateState('acquisitionBudget', e.target.value); }}>
                                {renderOptions(acquisitionBudget?.data)}
                            </select>
                        </div>
                        <div className='form-group required mb-4 p-0'>
                            <label htmlFor=''>What would be the main purpose of acquisition?</label>
                            {
                                mainAcquisitionError && (
                                    <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                        <span className='error-red'> ERROR </span>{" "}
                                        <span className='txt-error'>This field is required</span>
                                    </div>
                                )
                            }
                            <select className='form-control mt-2' value={selectedOption.mainAcquisition} onChange={(e) => { handleSelectChange(e, 'mainAcquisition'); updateState('mainAcquisition', e.target.value); }}>
                                {renderOptions(mainAcquisition?.data)}
                            </select>
                        </div>
                    </div>
                    <div className='text-align-right stepsButtons'>
                        <button className='btn btn-devinsider cancel' onClick={() => props?.showCancelModal()}>
                            Cancel
                        </button>
                        <button className='btn btn-devinsider save' onClick={() => goToNextStep(props?.currentStep + 1, true)}>
                            Continue
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}
export type StaticApiUrls = {
    ACQUISITIONS: string;
    CHALLENGES: string;
    ACQUISITION_BUDGETS: string;
    MAIN_PURPOSES: string;
    OPPORTUNITY_INTERESTS: string;
    COUNTRIES: string;
    LIFECYCLES: string;
    TECHNO_ECOSYSTEM: string;
    VERTICALS: string;
    HORIZONTALS: string;
    EMPLOYEES: string;
    COUNTRIES_SORT_NAME: string;
};

const STATIC_API_URLS : StaticApiUrls = {
    ACQUISITIONS: '/api/how-many-acquisitions-past5-years',
    CHALLENGES: '/api/how-many-challenges-next12-months',
    ACQUISITION_BUDGETS: '/api/what-acquisition-budgets',
    MAIN_PURPOSES: '/api/what-main-purpose-acquisitions?sort[1]=Label:asc',
    OPPORTUNITY_INTERESTS: '/api/what-type-opportunity-interests',
    COUNTRIES: '/api/countries?pagination[page]=1&pagination[pageSize]=300&sort[1]=Continent:asc&sort[2]=Name:asc',
    LIFECYCLES: '/api/what-stage-lifecycles',
    TECHNO_ECOSYSTEM: '/api/what-techno-ecosystem-you-parts?pagination[page]=1&pagination[pageSize]=300',
    VERTICALS: '/api/verticals?sort[1]=Label:asc&pagination[start]=0&pagination[limit]=30',
    HORIZONTALS: '/api/horizontal-mains?pagination[page]=1&pagination[pageSize]=300&sort[1]=Label:asc',
    EMPLOYEES:'/api/how-many-people-employeds',
    COUNTRIES_SORT_NAME: '/api/countries?pagination[page]=1&pagination[pageSize]=300&sort[1]=Name:asc',
};

export default STATIC_API_URLS;
import Keycloak from 'keycloak-js';

const keycloakSetting = {
    url: process.env.REACT_APP_KEYCLOAK_URL ?? '',
    realm: process.env.REACT_APP_KEYCLOAK_REALM ?? '',
    clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID ?? '',
};

// Check if the document object is defined (i.e., running in a browser environment)
export const authInstance = new Keycloak(keycloakSetting);

// Create Keycloak instance only if running in a browser environment
// export const authInstance = new Keycloak(keycloakSetting);

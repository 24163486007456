import { api } from '../query/api';
import { useFetch, useFetchWithoutToken, usePost } from "../query/reactQuery";
import { urlsApi } from "./urls/apiUrl";

export const useGetSellerListe = (enabled: boolean, data?: any) =>
  useFetchWithoutToken<any>(urlsApi.seller.getAllList, data, { enabled: !!data && enabled });

export const useGetFiltersListing = (enabled: boolean) =>
  useFetchWithoutToken<any>(urlsApi.seller.filters, {}, { enabled: enabled });

export const useGetSellerListingsList = (
  updater: (oldData: any, newData: any) => any
) => {
  return usePost<any, any>(urlsApi.seller.listDi, {}, updater);
};

export const usePostItemListe = (
  updater: (oldData: any, newData: any) => any
) => {
  return usePost<any, any>(urlsApi.seller.listDi, undefined, updater);
};

export const useGetSolutionsFiltre = (enabled: boolean) =>
  useFetchWithoutToken<any>(urlsApi.seller.solutionFilter, {}, { enabled }, process.env.REACT_APP_API_PARAMS_URL);

export const usePostSellerListing = (data: any) =>
  api.post<any>(urlsApi.seller.add_seller, data);

export const useGetIndustryFiltre = (enabled: boolean) =>
  useFetchWithoutToken<any>(urlsApi.seller.industryFilter, {}, { enabled }, process.env.REACT_APP_API_PARAMS_URL);

export const useGetAcquisitionType = (enabled: boolean) =>
  useFetchWithoutToken<any>(urlsApi.seller.acquisitionTypes, {}, { enabled }, process.env.REACT_APP_API_PARAMS_URL);

export const useGetTimeframes = (enabled: boolean) =>
  useFetchWithoutToken<any>(urlsApi.seller.timeframes, {}, { enabled }, process.env.REACT_APP_API_PARAMS_URL);

export const useGetHowValueBusiness = (enabled: boolean) =>
  useFetchWithoutToken<any>(urlsApi.seller.how_value_business, {}, { enabled }, process.env.REACT_APP_API_PARAMS_URL);

export const useGetReasonListingBusiness = (enabled: boolean) =>
  useFetchWithoutToken<any>(urlsApi.seller.reason_listing_business, {}, { enabled }, process.env.REACT_APP_API_PARAMS_URL);

export const useGetLifecycleStage = (enabled: boolean) =>
  useFetchWithoutToken<any>(urlsApi.seller.lifecycle, {}, { enabled }, process.env.REACT_APP_API_PARAMS_URL);

export const useGetMainValueBusiness = (enabled: boolean) =>
  useFetchWithoutToken<any>(urlsApi.seller.main_value_business, {}, { enabled }, process.env.REACT_APP_API_PARAMS_URL);

export const useGetHowManyPeopleEmployed = (enabled: boolean) =>
  useFetchWithoutToken<any>(urlsApi.seller.how_many_people, {}, { enabled }, process.env.REACT_APP_API_PARAMS_URL);

export const generateYearList = () => {
  const currentYear = new Date().getFullYear();
  const startYear = 1960;
  const years = [];

  for (let year = currentYear; year >= startYear; year--) {
    years.push(year);
  }
  years.push("Before " + startYear);
  return years;
};
export const useGetMySpecificTehnologyListing = (enabled: boolean) =>
  useFetch<any>(urlsApi.buyer.myspecifictechnology, {}, { enabled });

export const useGetBusinessModel = (enabled: boolean) =>
  useFetchWithoutToken<any>(urlsApi.seller.business_model, {}, { enabled }, process.env.REACT_APP_API_PARAMS_URL);

export const useGetFundingRaised = (enabled: boolean) =>
  useFetchWithoutToken<any>(urlsApi.seller.funding_raised, {}, { enabled }, process.env.REACT_APP_API_PARAMS_URL);

export const useGetRevenueQuarter = (enabled: boolean) =>
  useFetchWithoutToken<any>(urlsApi.seller.revenue_last_quarter, {}, { enabled }, process.env.REACT_APP_API_PARAMS_URL);

export const useGetTechnologyPartenership = (enabled: boolean) =>
  useFetchWithoutToken<any>(urlsApi.seller.technology_partenership, {}, { enabled }, process.env.REACT_APP_API_PARAMS_URL);

export const useGetCountryFiltre = (enabled: boolean, data?: any) =>
  useFetchWithoutToken<any>(urlsApi.seller.countryFilter, data, { enabled }, process.env.REACT_APP_API_PARAMS_URL);

export const useGetProfitability = (enabled: boolean) =>
  useFetchWithoutToken<any>(urlsApi.seller.profitability, {}, { enabled }, process.env.REACT_APP_API_PARAMS_URL);

export const useGetListingByUser = (iduser: any, enabled: boolean) =>
  useFetch<any>(urlsApi.seller.listing_user + '/' + iduser, {}, { enabled: !!iduser && enabled });

export const useGetListingById = (id: any, enabled: boolean) =>
  useFetch<any>(urlsApi.seller.listing_by_id + '/' + id, {}, { enabled: !!id && enabled });

export const UseDeleteSellerListing = (id: string) =>
  api.delete(urlsApi.seller.delete_seller_listing + '/' + id);

export const useUnpublishListing = (data: any) =>
  api.put(urlsApi.seller.unpublish_seller_listing + '/' + data.id, data.data);

export const UseUpdateSeller = (data: any) =>
  api.post<any>(urlsApi.seller.update_seller + '/' + data.idSeller, data);


export const UseGetMySellerListing = (enabled: boolean) =>
  useFetch<any>(urlsApi.seller.my_listing, {}, { enabled: enabled });

export const useGetSubscription = () =>
  useFetch<any>(urlsApi.seller.get_subscription, {}, {});

export const UseCheckValidSubscription = (enabled = true) =>
  useFetch<any>(urlsApi.seller.check_valid_subscription, {}, { enabled });

export const useEnableRenewalSubscription = () =>
  api.put<any>(urlsApi.seller.enable_renew_subscription);

export const useCancelRenewalSubscription = () =>
  api.put<any>(urlsApi.seller.cancel_renew_subscription);

export const useUpgradeSubscription = (data: any) =>
  api.post<any>(urlsApi.seller.upgrade_subscription, data);

export const useGetSellerMatchingListing= (data: any) =>
  api.post<any>(urlsApi.seller.seller_matching, data.params);
// export const useGetSellerMatchingListing = (enabled: boolean) =>
//   useFetch<any>(urlsApi.seller.seller_matching, {}, { enabled });

export const useGetDetailSellerMatching = (idUser: string, enabled: boolean) =>
  useFetch<any>(urlsApi.seller.detail_seller_matching+"/"+idUser, {}, { enabled });

import Modal from 'react-bootstrap/Modal';
import icoFailed from '../../resources/images/ban-solid.svg';

type IProps = {
    show: boolean;
    handleOk?: () => void;
    message?: string;
};

const FreePublishFailed = (props: IProps) => {
    return (
        <Modal
            show={props.show}
            className='createCompanypopup medium forStep5custom'
        >

            <Modal.Header>
                <div className='headerpopupCompany noStep'>
                <h2 className='title'>Publish listing</h2>
                <a
                    className='close'
                    onClick={() => props.handleOk && props.handleOk()}
                >
                    <span className='icon-close-pop'></span>
                </a>
                </div>
            </Modal.Header>

            <Modal.Body className=''>
                <div className='contentBorder forBillingCenter pt-3 pb-0 text-center'>
                    <div className='payement_failed'>
                    <div className="error-img">
                        <img
                            src={icoFailed}
                            alt=""
                            width={150}
                        />
                    </div>
                    <span className='txt mb-3 warning'>Unable to process your request</span>
                    <p>{props?.message ? props?.message : "Please complete all required fields."}</p>
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <div className='contentBorder text-center'>
                <button
                    className='btn btn-devinsider containerBtnBtn pl-5 pr-5'
                    onClick={() => props.handleOk && props.handleOk()}
                >
                    OK
                </button>
                </div>
            </Modal.Footer>

        </Modal>
    );
}

export default FreePublishFailed;


"use client";


import { useKeycloak } from "@react-keycloak/web";
import { NavLink, useNavigate } from "react-router-dom";
import avatar from "../resources/images/avatar.png";
import logoLettre from "../resources/images/logo-lettre.svg";
import "./MainLayout.scss";

interface HeaderProps {
    isEditMode: boolean;
}

const Header: React.FC<HeaderProps> = ({ isEditMode }) => {
    const { keycloak, initialized } = useKeycloak();
    const navigate = useNavigate();

    const handleLogOut = () => {
        if (initialized && keycloak?.authenticated) {
            keycloak.logout().then(() => {navigate('/');});
        }
    };

    const handleGotoDI = () => {
        window.open(process.env.REACT_APP_DI_URL, '_blank');
    };

    const handleSignin = () => {
        keycloak.login();
    }

    return (
        <div id="principalMenu" className={`${ isEditMode ? 'listingEditMode' : ''}` }>
            <div className="dynamicMenu">
                <div className="principalMenu">
                <div className="container mx-auto">
                    <div className="leftItemHeader">
                    <div className="logoHeader">
                        <img src={logoLettre} alt="" />
                    </div>
                    <div className="menuDynamic tl">
                        <div className="item">
                        <NavLink to="/">M&A matchmaking</NavLink>
                        </div>
                    </div>
                    </div>
                    <div className="rightItemHeader">
                    { initialized &&
                        <div className="menuDynamic tr">
                            <div className="item">
                            {keycloak?.authenticated &&
                                (
                                    <a href="javascript:;" className="linkHeader">
                                        <span className="icon-message"></span>
                                        <span className="mobile">Messages</span>
                                    </a>
                                )
                            }
                            </div>
                            <div className="item">
                                {keycloak?.authenticated &&
                                    (
                                        <a href="javascript:;" className="linkHeader">
                                            <span className="icon-notification"></span>
                                            <span className="mobile">Notification</span>
                                        </a>
                                    )
                                }
                            </div>
                            <div className="item">
                                {keycloak?.authenticated &&
                                    <>
                                        <a href="javascript:;" className="parent linkHeader">
                                            <div className={`profilImg`}>
                                            <img src={avatar} alt="" />
                                            </div>
                                            <span className="mobile">My account</span>
                                        </a>
                                        <div className="submenu">
                                            <a href="javascript:;" onClick={() => handleGotoDI()}>Go to Devinsider</a>
                                            <a href="javascript:;" onClick={() => handleLogOut()}>Sign out</a>
                                        </div>
                                    </>
                                }
                                
                                {!keycloak?.authenticated &&
                                    <>
                                    {/* TODO Jacky */}
                                    <div className="logins">
                                        <a className="link" href="javascript:;" onClick={() => handleSignin()}>Sign in</a>
                                        <a className="link" href="javascript:;">Get started</a>
                                    </div>
                                    </>
                                }
                            </div>
                        </div>
                    }
                    </div>
                </div>
                </div>
            </div>
        </div>
    );
}

export default Header;

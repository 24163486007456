import { useState } from "react";
import { PaymentInfoWrapper } from "../../components/payment/payment-info/payment-info-wrapper";
import usePaymentHook from "../../components/payment/use-payment-hook";
import "../../pages/seller/seller.scss";
import { capitalizeFirstLetter } from "../../utils/outils";
import { LoaderCommon } from "../../widget/loader";
import { PaymentStatus } from "./payment-status/payment-status";
import { ProductInfo } from "./product-info/product-info";

export type IProps = {
    planType: string;
    listingType: string;
    successCallback: (
        paymentMethodId: string | null,
        setLoading: (l: boolean) => void,
        savePaymentMethod?: boolean,
        setShowPaymentStatus?: (show: boolean, data: {status: 'success' | 'error'; invoiceNumber?: string; invoiceUrl?: string}) => void
    ) => void;
    title: string;
    handleStatusOk: () => void;
    showTryAgain: boolean;
    showContinue: boolean;
    handleClose?: () => void;
    handleCancel: () => void;
};

export default function Payment(props: IProps) {
    const productKey = (props?.planType+ '_' + props?.listingType).toUpperCase();
    const [product, setProduct] = useState<{
        lookupKey: string;
        name: string;
    }>({
        lookupKey: productKey,
        name: capitalizeFirstLetter(props?.planType) + ' ' + props?.listingType,
    });

    const [showStatus, setShowStatus] = useState(false);
    const [status, setStatus] = useState<'success' | 'error'>('success');
    const [invoiceNumber, setInvoiceNumber] = useState<string>('');
    const [invoicePdf, setInvoicePdf] = useState<string>('');

    const handleStatus = (
        show: boolean,
        data: {
            status: 'success' | 'error';
            invoiceNumber?: string;
            invoiceUrl?: string;
        }
    ) => {
        setStatus(data.status);
        setInvoiceNumber(data.invoiceNumber || '');
        setInvoicePdf(data.invoiceUrl || '');
        setShowStatus(show);
    };

    const {
        prices,
        paymentMethod,
        currency,
        setCurrency,
        getProductPriceWithTax,
        getCurrencySymbol,
        productPrice,
        setProductPrice,
        userEmail,
        setModalSize,
        isLoading
    } = usePaymentHook(product);

    return (
        <div className='contentUser payment-form'>
            {isLoading && <div className='forBillingCenter pt-3'> <LoaderCommon /></div>}
            
            {!isLoading && <div className='forBillingCenter pt-3'>
                {!paymentMethod &&
                    <PaymentInfoWrapper
                        product={product}
                        currency={currency}
                        setCurrency={setCurrency}
                        getProductPriceWithTax={getProductPriceWithTax}
                        getCurrencySymbol={getCurrencySymbol}
                        productPrice={productPrice}
                        setProductPrice={setProductPrice}
                        userEmail={userEmail}
                        successCallback={props?.successCallback}
                        handleStatus={handleStatus}
                        handleCancel={props?.handleCancel}
                        isUpgradePlan={false}
                    />
                }
                {paymentMethod &&
                    <ProductInfo
                        prices={prices}
                        paymentMethod={paymentMethod}
                        setCurrency={setCurrency}
                        currency={currency}
                        getCurrencySymbol={getCurrencySymbol}
                        productPrice={productPrice}
                        setProductPrice={setProductPrice}
                        successCallback={props?.successCallback}
                        handleStatus={handleStatus}
                        handleCancel={props?.handleCancel}
                    />
                }
                <PaymentStatus
                    title={props?.title}
                    invoiceNumber={invoiceNumber}
                    invoiceUrl={invoicePdf}
                    show={showStatus}
                    status={status}
                    handleClose={() => {
                        setShowStatus(false);
                        props?.handleClose && props.handleClose();
                    }}
                    handleOk={props?.handleStatusOk}
                    paymentMethod={paymentMethod}
                    tryAgain={() => {
                        setModalSize('large');
                        setShowStatus(false);
                    }}
                    showTryAgain={props?.showTryAgain}
                    showContinue={props?.showContinue}
                    isUpgradePlan={false}
                    lookupKey={product.lookupKey}
                />
            </div>}
            
        </div>
    );
}
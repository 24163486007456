
import { useEffect, useState } from "react";
import { useGetFundingRaised, useGetProfitability, useGetRevenueQuarter } from "../../../../../api/seller";
import SellerSteps from "../../../../../components/seller/seller-steps";
import { IFinancialDetails } from "../../../../../types/financial-details";

export type IProps = {
    handleNextStep: (step: number, isGoingNextStep?: boolean) => void;
    showCancelModal: () => void;
    setData: (data: IFinancialDetails) => void;
    data: IFinancialDetails;
    validateSteps: Array<number>;
    currentStep: number;
    setCurrentStep: (step: number) => void;
    removeStepInValidated: (step: number) => void;
    addStepToValidated: (step: number) => void;
};

export default function FinancialDetails(props: IProps) {
    const currentYear = new Date().getFullYear();
    const recentYears = [currentYear, currentYear-1, currentYear-2];
    const [enabled, setEnabled] = useState(false);

    const [formState, setFormState] = useState(props?.data);
    const { data: dataFundingRaised } = useGetFundingRaised(enabled);
    const { data: dataRevenue } = useGetRevenueQuarter(enabled);
    const { data: dataProfitabilityBusiness } = useGetProfitability(enabled);

    const [errorCurrentOwnership, setErrorCurrentOwnership] = useState(false);
    const [errorRaise, setErrorRaise] = useState(false);
    const [errorQuarterRevenue, setErrorQuarterRevenue] = useState(false);
    const [errorProfitability, setErrorProfitability] = useState(false);

    const updateState = (property: string, value: string) => {
        setFormState((prevState: any) => ({
            ...prevState,
            [property]: value,
        }));
    }

    const goToStep = (step: number, isGoingNextStep: boolean) => {
        if(formState?.ownership_structure !== "" && formState?.raised_funds !== "" && formState?.last_quarter_revenue !== "" && formState?.profitability !== "") {
            props?.setData(formState);
            props?.handleNextStep(step, isGoingNextStep);
            props?.addStepToValidated(props?.currentStep);
        } else {
            if(isGoingNextStep) {
                if(formState?.ownership_structure == "")
                    setErrorCurrentOwnership(true);
                if(formState?.raised_funds == "")
                    setErrorRaise(true);
                if(formState?.last_quarter_revenue == "")
                    setErrorQuarterRevenue(true);
                if(!formState?.profitability)
                    setErrorProfitability(true);
            }
            
            props?.removeStepInValidated(props?.currentStep);
                
            return;
        }
        
    }

    useEffect(() => {
        if(props?.currentStep === 5) setEnabled(true);
        if(dataFundingRaised && dataRevenue && dataProfitabilityBusiness) setEnabled(false);
    }, [props?.currentStep, dataFundingRaised, dataRevenue, dataProfitabilityBusiness]);

    useEffect(() => {
        setErrorCurrentOwnership(false);
    }, [formState?.ownership_structure]);

    useEffect(() => {
        setErrorRaise(false);
    }, [formState?.raised_funds]);

    useEffect(() => {
        setErrorQuarterRevenue(false);
    }, [formState?.last_quarter_revenue]);

    useEffect(() => {
        setErrorProfitability(false);
    }, [formState?.profitability]);

    useEffect(() => {
        props?.setData(formState);
    }, [formState]);

    return (
        <>
            <SellerSteps
                validatedSteps= {props?.validateSteps}
                currentStep= {props?.currentStep}
                handleContinue= {goToStep}
                setCurrentStep= {props?.setCurrentStep}
            />
            <div className='container mx-auto minHeightwin pt-4'>
                <div className="scrollContainer">
                    <div className='contentUser acquisition'>
                    <div className='contentBorder row'>
                        <div className='form-group required mb-4 p-0'>
                            <label htmlFor=''>What is the current ownership structure of the business?</label>
                            {
                                errorCurrentOwnership && (
                                    <div className="container-error pl-2  d-inline" style={{color: 'red'}}>
                                        <span className='error-red'> ERROR </span>{" "}
                                        <span className='txt-error'>This field is required</span>
                                    </div>
                                )
                            }
                            <textarea className='form-control mt-2'
                                maxLength={500}
                                placeholder='e.g "50/50 split ownership","80% ownership, 20% outside investment..."'
                                value={formState?.ownership_structure}
                                name='ownership_structure'
                                onChange={(evt) => updateState(evt.target.name, evt.target.value)}
                            />
                        </div>
                        <div className='form-group required mb-4 p-0'>
                            <label htmlFor=''>How much funding have you raised?</label>
                            {
                                errorRaise && (
                                    <div className="container-error pl-2  d-inline" style={{color: 'red'}}>
                                        <span className='error-red'> ERROR </span>{" "}
                                        <span className='txt-error'>This field is required</span>
                                    </div>
                                )
                            }
                            <select className='form-control mt-2'
                                name='raised_funds'
                                onChange={(evt) => updateState(evt.target.name, evt.target.value)}
                            >
                                <option value="">Select an option ...</option>
                                {
                                    dataFundingRaised?.data?.map((el : any, index : any) => (
                                        <option selected={el?.attributes?.Label == formState?.raised_funds} value={el?.attributes?.Label} key={index}>{el?.attributes?.Label}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className='form-group required mb-4 p-0'>
                            <label htmlFor=''>How much revenue did your business generate last quarter? </label>
                            {
                                errorQuarterRevenue && (
                                    <div className="container-error pl-2  d-inline" style={{color: 'red'}}>
                                        <span className='error-red'> ERROR </span>{" "}
                                        <span className='txt-error'>This field is required</span>
                                    </div>
                                )
                            }
                            <select className='form-control mt-2'
                                name='last_quarter_revenue'
                                onChange={(evt) => updateState(evt.target.name, evt.target.value)}
                            >
                                <option value="">Select an option ...</option>
                                {
                                    dataRevenue?.data?.map((el : any, index : any) => (
                                        <option selected={el?.attributes?.Label == formState?.last_quarter_revenue} value={el?.attributes?.Label} key={index}>{el?.attributes?.Label}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className='form-group required mb-4 p-0'>
                            <label htmlFor=''>How would you describe the profitability of your business? </label>
                            {
                                errorProfitability && (
                                    <div className="container-error pl-2  d-inline" style={{color: 'red'}}>
                                        <span className='error-red'> ERROR </span>{" "}
                                        <span className='txt-error'>This field is required</span>
                                    </div>
                                )
                            }
                            <select className='form-control mt-2'
                                name='profitability'
                                onChange={(evt) => updateState(evt.target.name, evt.target.value)}
                            >
                                <option value="">Select an option ...</option>
                                {
                                    dataProfitabilityBusiness?.data?.map((el : any, index : any) => (
                                        <option selected={el?.attributes?.Label == formState?.profitability} value={el?.attributes?.Label} key={index}>{el?.attributes?.Label}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className='form-group not-required mb-4 p-0'>
                            <label htmlFor=''>What was the total revenue generated over the past 3 years?</label>
                            <input
                                value={formState?.total_revenue1}
                                type='text'
                                className='form-control mt-2'
                                placeholder={`${recentYears[0]}-$`}
                                name='total_revenue1'
                                onChange={(evt) => updateState(evt.target.name, evt.target.value)}
                            />
                            <input
                                value={formState?.total_revenue2}
                                type='text'
                                className='form-control mt-2'
                                placeholder={`${recentYears[1]}-$`}
                                name='total_revenue2'
                                onChange={(evt) => updateState(evt.target.name, evt.target.value)}
                            />
                            <input
                                value={formState?.total_revenue3}
                                type='text'
                                className='form-control mt-2'
                                placeholder={`${recentYears[2]}-$`}
                                name='total_revenue3'
                                onChange={(evt) => updateState(evt.target.name, evt.target.value)}
                            />
                        </div>
                        <div className='form-group not-required mb-4 p-0'>
                            <label htmlFor=''>What was the total profit made in the past 3 years?</label>
                            <input
                                value={formState?.total_profit1}
                                type='text'
                                className='form-control mt-2'
                                placeholder={`${recentYears[0]}-$`}
                                name='total_profit1'
                                onChange={(evt) => updateState(evt.target.name, evt.target.value)}
                            />
                            <input
                                value={formState?.total_profit2}
                                type='text'
                                className='form-control mt-2'
                                placeholder={`${recentYears[1]}-$`}
                                name='total_profit2'
                                onChange={(evt) => updateState(evt.target.name, evt.target.value)}
                            />
                            <input
                                value={formState?.total_profit3}
                                type='text'
                                className='form-control mt-2'
                                placeholder={`${recentYears[2]}-$`}
                                name='total_profit3'
                                onChange={(evt) => updateState(evt.target.name, evt.target.value)}
                            />
                        </div>
                    </div>
                    <div className="text-align-right stepsButtons">
                    <button className='btn btn-devinsider cancel' onClick={() => props?.showCancelModal()}>
                        Cancel
                    </button>
                    <button className='btn btn-devinsider save' onClick={() => goToStep(props?.currentStep + 1, true)}>
                        Continue
                    </button>
                    </div>
                </div>
                </div>
            </div>
        </>
    );
}
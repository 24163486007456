import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useCancelRenewalSubscription, useEnableRenewalSubscription, useGetMyBuyerListing, useGetUserSubscription, useUpgradeSubscription } from "../../../api/buyer";
import ManagePlan from "../../../components/payment/manage-plan";
import ConfirmUpdateRenewalSubscription from "../../../components/payment/manage-plan/confirm-auto-renewal";
import PaymentPopup from "../../../components/payment/payment-popup";
import { invalidateQueryProduct, invalidateQueryProductWithTax, refetchPaymentMethod, refetchReceipt } from "../../../query/removeCache";
import { capitalizeFirstLetter } from "../../../utils/outils";
import { LoaderCommon } from "../../../widget/loader";
import CurrentPlan from "../current-plan";

interface PlansAndBillingProps {
    isUpgrade?: string
}

export default function BuyerPlanDetails(props: PlansAndBillingProps) {
    const queryClient = useQueryClient();

    const navigate = useNavigate();
    
    const [isUnpaid, setIsUnpaid] = useState<boolean>(false);

    const [enableCheckSubscription, setEnableCheckSubscription] = useState<boolean>(true);
    const { data: checkSubscription, refetch: refetchCheck, isLoading } = useGetUserSubscription(enableCheckSubscription);

    const [plan, setPlan] = useState<string>('No plan');
    const [enableUpgrade, setEnableUpgrade] = useState<boolean>(false);

    const [enabledMyBuyerListing, setEnabledMyBuyerListing] = useState<boolean>(true);
    const { data: myBuyerListing, isFetched: isMyBuyerListingFetched, isLoading: myBuyerListingsLoading, } = useGetMyBuyerListing(enabledMyBuyerListing);


    useEffect(() => {
        if (isMyBuyerListingFetched) setEnabledMyBuyerListing(false);
        if(props?.isUpgrade && props?.isUpgrade == "upgrade") setShowMyPlanModal(true);
    }, [isMyBuyerListingFetched, props?.isUpgrade])

    useEffect(() => {
        if(checkSubscription && !checkSubscription.data.subscription && myBuyerListing){
            setIsUnpaid(true);
        } else setIsUnpaid(false);
    }, [myBuyerListing, checkSubscription])

    const goToEnrollAsBuyer = () => {
        navigate("/buyer/listing/creation");
    };
    

    const [showMyPlanModal, setShowMyPlanModal] = useState<boolean>(false);

    const closeMyPlanModal = () => {
        setShowMyPlanModal(false);
    };

    const subscriptions = [
        {
            type: "opportunistic",
            title: "OPPORTUNISTIC BUYER",
            content: (
                <div>
                    - Basic annual subscription of $199 <br />
                    - Devinsider will recommend seller opportunities <br />
                    - Passive buyer profile is listed on the buyers result matchmaking (but not publicaly)
                </div>
            ),
        },
        {
            type: "motivated",
            title: "MOTIVATED BUYER",
            content: (
                <div>
                    - Premium annual subscription of $999 <br />
                    - Access to matchmaking where sellers can reach out to you as a buyer <br />
                    - Proactively reach out to sellers through the matchmaking results: &quot;proactive seller&quot; and &quot;passive seller profile&quot; <br />
                    - Devinsider will recommend seller opportunities (first 7 days after a seller lists themselves it is only visible to motivated buyers before it is opened up to the passive buyer)
                </div>
            ),
        },
    ];

    const [showManagePlan, setShowManagePlan] = useState<boolean>(false);

    const [showConfirmAutoRenewal, setShowConfirmAutoRenewal] = useState<boolean>(false);

    const [showLoaderConfirm, setShowLoaderConfirm] = useState<boolean>(false);

    const cancelSubscriptionMut = useMutation({
        mutationFn: useCancelRenewalSubscription,
        onSuccess: (data) => {
            if (data.status === 200) {
                toast('Automatic renewal turned OFF successfully');
                setShowConfirmAutoRenewal(false);
                setShowLoaderConfirm(false);
            } else {
                toast('An error occurred');
            }

            refetchCheck();
        }
    })

    const enableSubscriptionMut = useMutation({
        mutationFn: useEnableRenewalSubscription,
        onSuccess: (data) => {
            if (data.status === 200) {
                toast('Automatic renewal turned ON successfully');
                setShowConfirmAutoRenewal(false);
                setShowLoaderConfirm(false);
            } else {
                toast('An error occurred');
            }

            refetchCheck();
        }
    })

    const cancelSubscriptionRenew = async () => {
        setShowLoaderConfirm(true);
        await cancelSubscriptionMut.mutateAsync();
    };
    
    const enableSubscriptionRenew = async () => {
        setShowLoaderConfirm(true);
        await enableSubscriptionMut.mutateAsync();
    };

    const [planTypeChecked, setPlanTypeChecked] = useState<string>('');

    const [showPaymentModal, setShowPaymentModal] = useState<boolean>(false);
    
    const mutUpgrade = useMutation({mutationFn: useUpgradeSubscription, retry: 0});

    useEffect(() => {
        if(checkSubscription && checkSubscription.data) {
            let subscription = checkSubscription.data.subscription;
            if(subscription){
                setPlan(capitalizeFirstLetter(subscription.type.name));

                if(subscription.type.type == "opportunistic")  setEnableUpgrade(true);
                else setEnableUpgrade(false)

                setPlanTypeChecked(checkSubscription.data.subscription.type.type);
            } else {
                if(myBuyerListing) {
                    setPlan(myBuyerListing?.userAccount?.isPremium ? 'Motivated buyer unpaid' : 'Opportunistic buyer unpaid');
                }
            }
            setEnableCheckSubscription(false);
        }
    }, [checkSubscription, myBuyerListing]);

    const paymentMethodCallBack = async (
        paymentMethodId: string | null,
        setLoading: (l: boolean) => void,
        savePaymentMethod?: boolean,
        setShowPaymentStatus?: (
            show: boolean,
            data: {
                status: 'success' | 'error';
                invoiceNumber?: string;
                invoiceUrl?: string;
            }
        ) => void
    ) => {
        try {
            const data = await upgradeSubscription(paymentMethodId, savePaymentMethod);

            if (data?.status == 200) {
                setShowPaymentStatus && setShowPaymentStatus(true, {
                    status: 'success',
                    invoiceNumber: data.data?.invoice_number,
                    invoiceUrl: data.data?.invoice_url
                });
                refetchReceipt(queryClient);
            }
        } catch (error) {
            setShowPaymentStatus && setShowPaymentStatus(true, {
                status: 'error',
            });
            setPlanTypeChecked(checkSubscription.data.subscription.type.type);
        } finally {
            invalidateQueryProductWithTax(queryClient);
            invalidateQueryProduct(queryClient);

            refetchPaymentMethod(queryClient);
            
            setLoading(false);
        }
    };
    
    const handleStatusOk = () => {
        setShowPaymentModal(false);
        refetchCheck();
    };

    const upgradeSubscription = async (paymentId: string | null, savePaymentMethod?: boolean) => {
        const params = {
            planType: planTypeChecked,
            paymentId,
            savePaymentMethod
        }
        const p = await mutUpgrade.mutateAsync(params);
    
        return p?.data;
    }

    const handleGoToMyListing = () => {
        navigate("/buyer/listing/details/" + myBuyerListing?.id);
    };

    return (
        <>
            <div className="itemBilling">
                <h2 className="title">Buyer plan details</h2>

                    <div className="contentItem">
                        {isLoading &&
                            <LoaderCommon />
                        }
                        {!isLoading &&
                            <>
                                <div className="itemctt">
                                    <>
                                        Subscription plan : {plan}
                                        <br />
                                    </>
                                </div>
                                <div className="footerItem" style={{ fontSize: "14px" }}>
                                {(checkSubscription && !checkSubscription.data.subscription && isMyBuyerListingFetched && !myBuyerListing) &&
                                    <a onClick={() => goToEnrollAsBuyer()}>
                                        Enroll
                                    </a>
                                }
                                {
                                    isUnpaid &&
                                    <a onClick={() => handleGoToMyListing()}>
                                        View my listing
                                    </a>
                                }
                                {checkSubscription && checkSubscription.data.subscription && enableUpgrade &&
                                    <>
                                        <a onClick={() => setShowMyPlanModal(true)}>
                                            View plan and upgrade
                                        </a>
                                        {" "}
                                        <span className="separ"></span>{" "}
                                        <a onClick={() => {setShowManagePlan(true)}}>
                                            Manage plan
                                        </a>
                                    </>
                                }
                                {checkSubscription && checkSubscription.data.subscription && !enableUpgrade &&
                                    <>
                                        <a onClick={() => setShowMyPlanModal(true)}>
                                            View plan
                                        </a>
                                        {" "}
                                        <span className="separ"></span>{" "}
                                        <a onClick={() => {setShowManagePlan(true)}}>
                                            Manage plan
                                        </a>
                                    </>
                                }
                                </div>
                            </>
                        }
                    </div>
            </div>
            {checkSubscription && checkSubscription.data && checkSubscription.data.subscription &&
                <CurrentPlan
                    subscriptionsTypes={subscriptions}
                    myPlan={checkSubscription.data.subscription.type.type}
                    closeModal={
                        () => {
                            setPlanTypeChecked(checkSubscription.data.subscription.type.type);
                            closeMyPlanModal();
                        }
                    }
                    show={showMyPlanModal}
                    title={
                        enableUpgrade ?
                        "Buyer Plan > Upgrade your subscription plan"
                        : "Buyer Plan > View plan"
                    }
                    enableUpgrade={enableUpgrade}
                    handleUpgrade={() => {
                        setShowPaymentModal(true);
                        closeMyPlanModal();
                    }}
                    planTypeChecked={planTypeChecked}
                    setPlanTypeChecked={setPlanTypeChecked}
                />
            }
            {checkSubscription && checkSubscription.data && checkSubscription.data.subscription &&
                <>
                    <ManagePlan
                        show={showManagePlan}
                        checkSubscription={checkSubscription.data}
                        type="buyer"
                        handleShow={() =>
                            setShowManagePlan(!showManagePlan)
                        }
                        handleShowConfirm={(value: boolean) => setShowConfirmAutoRenewal(value)}
                    />
                    <ConfirmUpdateRenewalSubscription
                        show={showConfirmAutoRenewal}
                        checkSubscription={checkSubscription.data}
                        handleShowConfirm={() =>
                            setShowConfirmAutoRenewal(false)
                        }
                        cancelSubscriptionRenew={cancelSubscriptionRenew}
                        enableSubscriptionRenew={enableSubscriptionRenew}
                        showLoader={showLoaderConfirm}
                        type="buyer"
                    />
                </>
            }
            {enableUpgrade &&
                <PaymentPopup
                    planType={planTypeChecked}
                    listingType="buyer"
                    successCallback={paymentMethodCallBack}
                    handleStatusOk={handleStatusOk}
                    setShowPaymentModal={setShowPaymentModal}
                    title={"Buyer Plan > Upgrade your subscription plan"}
                    showTryAgain={false}
                    showContinue={true}
                    handleClose={
                        () => {
                            setPlanTypeChecked(checkSubscription.data.subscription.type.type);
                            handleStatusOk();
                        }
                    }
                    handleCancel={
                        () => {
                            setPlanTypeChecked(checkSubscription.data.subscription.type.type);
                            setShowPaymentModal(false);
                        }
                    }
                    show={showPaymentModal}
                    modalSize="large"
                    isUpgradePlan={true}
                />
            }
        </>
    );
}
import { useEffect, useState } from "react";
import { ReactComponent as Loader } from '../../../../../resources/images/Rolling.svg';
import { ISubscription } from "../../../../../types/subscription";

type IProps = {
    handleNextStep: (step: number, isGoingNextStep?: boolean) => void;
    showCancelModal: () => void;
    setData: (data: ISubscription) => void;
    data: ISubscription;
    currentStep: number;
    isPostLoading?: boolean;
};

export default function Subscription(props: IProps) {
    const [formState, setFormState] = useState(props?.data);
    const [errorSubscription, setErrorSubscription] = useState(false);

    const subscriptions = [
        {
            planType: "free",
            content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
        },
        {
            planType: "premium",
            content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
        }
    ]

    const updateState = (property: string, value: string) => {
        setFormState((prevState: any) => ({
            ...prevState,
            [property]: value,
        }));
    }
    
    const goToNextStep = () => {
        if(formState?.planType !== "") {
            props?.setData(formState);
            props?.handleNextStep(props?.currentStep + 1, true);
        } else {
            setErrorSubscription(true);
            return;
        }
        
    }

    useEffect(() => {
        setErrorSubscription(false);
    }, [errorSubscription]);

    useEffect(() => {
        props?.setData(formState);
    }, [formState]);

    return (
        <div className='container mx-auto minHeightwin pt-4'>
            <div className='contentUser'>
                <div className="userType chooseSubscription">
                    {
                        subscriptions?.map((el : any, index : any) => (
                            <div className={`item${formState?.planType === el?.planType ? " active" : ""}`} key={index}>
                                <div className='title'>{el?.planType?.toUpperCase()}</div>
                                <span className='txt'>
                                    {el?.content}
                                </span>
                                <div className='select'>
                                    <input
                                        id={`select-${el?.planType}`}
                                        type="radio"
                                        value={el?.planType}
                                        name="planType"
                                        checked={formState?.planType === el?.planType}
                                        onChange={(evt) => updateState(evt.target.name, evt.target.value)}
                                    />
                                    <label htmlFor={`select-${el?.planType}`}>
                                        Select
                                    </label>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className='text-align-right stepsButtons'>
                    <button className='btn btn-devinsider cancel' onClick={() => props?.showCancelModal()}>
                        Cancel
                    </button>
                    <button className={`btn btn-devinsider save ${formState?.planType === "" ? "disabled-ma-btn" : ""}`} disabled={errorSubscription || formState?.planType === ""} onClick={() => {props && !props.isPostLoading && goToNextStep()}}>
                    {props?.isPostLoading ? <Loader /> : 'Continue'}
                    </button>
                </div>
            </div>
        </div>
    );
}
import moment from "moment";
import { ChangeEvent, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useGetProductPrice } from "../../../api/payment";
import toggleoff from "../../../resources/images/toggleoff.svg";
import toggleon from "../../../resources/images/toggleon.svg";
import { capitalizeFirstLetter } from "../../../utils/outils";
import "./manage-plan.scss";

type IPropsManagePlan = {
    type: string;
    show: boolean;
    checkSubscription: any;
    handleShow: (p: boolean) => void;
    handleShowConfirm: (p: boolean) => void;
};

type ISubscription = {
    show?: boolean;
    subscription?: any;
    handleShow: (p: boolean) => void;
    handleShowConfirm: (p: boolean) => void;
};

export default function ManagePlan(props: IPropsManagePlan) {
    const confirmUpdate = () => {
        props?.handleShow(false);
        props?.handleShowConfirm(true);
    }
    const [currency, setCurrency] = useState<string | undefined>();
    const [amount, setAmount] = useState<string | undefined>();
    
    const {
        mutateAsync: getProductPriceMut
    } = useMutation({ mutationFn: useGetProductPrice });

    const getPrice = async (subscription_type: string) => {
        try {
            const response = await getProductPriceMut(subscription_type ?? '');
    
            if (response.data.message === 'success') {
                const productPrices = response.data.data.prices;
                const curr = productPrices.usd === undefined ? 'eur' : 'usd';
                setCurrency(curr);
                setAmount(response.data.data.prices[curr] || 0);
            } else {
                toast('Error getting product price');
            }
        } catch (error) {
            toast('Error getting product price');
        }
    };
    
    useEffect(() => {
        const lookupKey = (props?.checkSubscription?.subscription?.type?.type + '_' + props?.type).toUpperCase();
        getPrice(lookupKey);
    }, [props?.checkSubscription, props?.type]);

    const priceSplitter = (number: string) => (number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));

    return (
        <Modal
            show={props?.show}
            className='createCompanypopup medium forStep5custom'
            id="desactivate-subscription"
        >
            <Modal.Header>
                <div className='headerpopupCompany noStep'>
                    <h2 className='title'>Manage plan</h2>
                    <a className='close' onClick={() => props?.handleShow(false)}><span className='icon-close-pop'></span></a>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="contentBorder col m-0 p-0 desactivepopup">
                    <h3 className="title-cancel mb-4">
                    {capitalizeFirstLetter(props?.checkSubscription?.subscription?.type?.type + ' ' + props?.type)}
                    </h3>
                    <div className="manage-text">
                        {amount && <p>${priceSplitter(amount)}{" "} {currency?.toUpperCase()} / Year </p>}
                        <p>Start date: {moment(props?.checkSubscription?.start_at).format("LL")} </p>
                        <p>
                            {props?.checkSubscription?.subscription?.renewal_enabled ? "Renewal date": "End date"}:{" "}
                            {moment(props?.checkSubscription?.end_at).format("LL")}
                        </p>
                        <div className='slidecheck'>
                            <div className='custom-checkbox d-flex justify-content-center my-4 py-4'>
                                <input
                                type='checkbox'
                                id='hideAuthor'
                                className='custom-control-input'
                                onChange={(event: ChangeEvent<HTMLInputElement>) => confirmUpdate()}
                                checked={props?.checkSubscription?.subscription?.renewal_enabled}
                                />
                                <label htmlFor='hideAuthor'>
                                <span role='button' className="d-flex flex-row justify-content-center align-items-center">
                                    <span style={{display:"inline-block", marginRight:"1rem"}} className="mr-2">Automatic renewal : </span>
                                    <img
                                        className="d-block mx-auto"
                                        src={props?.checkSubscription?.subscription?.renewal_enabled ? toggleon : toggleoff}
                                        style={{width:"70px"}} alt="toggle-disabled"
                                    />
                                </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

import { QueryClient } from "react-query";
import { urlsApi } from "../api/urls/apiUrl";

export const removeCacheReceipt = (queryClient: QueryClient) => {
    queryClient.removeQueries(urlsApi.payment.get_user_invoices + '?paid&invoice_origin=manda_matchmaking');
}

export const removeCacheProductWithTax = (queryClient: QueryClient) => {
    queryClient.removeQueries(urlsApi.payment.get_product_price_with_tax);
}

export const removeCacheProduct= (queryClient: QueryClient) => {
    queryClient.removeQueries(urlsApi.payment.get_product_prices);
}

export const removeCachePaymentMethod = (queryClient: QueryClient) => {
    queryClient.removeQueries(urlsApi.payment.payment_method);
}

export const invalidateQueryPaymentMethod = (queryClient: QueryClient) => {
    queryClient.invalidateQueries(urlsApi.payment.payment_method);
}

export const invalidateQueryProductWithTax = (queryClient: QueryClient) => {
    queryClient.invalidateQueries(urlsApi.payment.get_product_price_with_tax);
}

export const invalidateQueryReceipt = (queryClient: QueryClient) => {
    queryClient.invalidateQueries(urlsApi.payment.get_user_invoices + '?paid&invoice_origin=manda_matchmaking');
}

export const invalidateQueryProduct = (queryClient: QueryClient) => {
    queryClient.invalidateQueries(urlsApi.payment.get_product_prices);
}

export const refetchReceipt = (queryClient: QueryClient) => {
    queryClient.refetchQueries(urlsApi.payment.get_user_invoices + '?paid&invoice_origin=manda_matchmaking');
}

export const refetchProductWithTax = (queryClient: QueryClient) => {
    queryClient.refetchQueries(urlsApi.payment.get_product_price_with_tax);
}

export const refetchProduct = (queryClient: QueryClient) => {
    queryClient.refetchQueries(urlsApi.payment.get_product_prices);
}

export const refetchPaymentMethod = (queryClient: QueryClient) => {
    queryClient.refetchQueries(urlsApi.payment.payment_method);
}

export const refetchFirstPageReceipt = (queryClient: QueryClient) => {
    queryClient.refetchQueries(urlsApi.payment.get_user_invoices + '?paid&invoice_origin=manda_matchmaking&page=1&page_size=5');
}

interface IProps {
    validatedSteps: Array<number>;
    currentStep: number;
    handleContinue: (step: number, isGoingNextStep: boolean) => void;
    setCurrentStep: (step: number) => void;
}

export default function SellerSteps(props: IProps) {
    const checkIfValidated = (step: number) => {
        if(props?.validatedSteps.indexOf(step) !== -1) return true;
        
        return false;
    }

    const handleChangeStep = (step: number) => {
        if(props?.currentStep < step){
            if(checkIntermediateSteps(props?.currentStep, step) || props?.validatedSteps.indexOf(step) != -1) props?.handleContinue(step, true);
        }else {
            props?.handleContinue(step, false);
            props?.setCurrentStep(step);
        }
    }

    const checkIntermediateSteps = (currentStep: number, targetStep: number) => {
        const stepsInRange = numbersBetweenTwoSteps(currentStep, targetStep);
        const allStepsExist = stepsInRange.every(step => props?.validatedSteps.includes(step));
    
        return allStepsExist;
    };

    function numbersBetweenTwoSteps(min: number, max: number) {
        let resultat = [];
    
        for (let i = min + 1; i < max; i++) {
            resultat.push(i);
        }
    
        return resultat;
    }

    return (
        <div className='filterFindIsv notSecondMenu left-menu'>
            <div className='contentFilter'>
                <div
                    className={
                        `block titleLeft pointer border-0
                        ${props?.currentStep == 2 ? " active" : "inactive"}
                        ${checkIfValidated(2) ? 'step-validated' : ''}`
                }
                    onClick={() => handleChangeStep(2)}
                >
                    <span className="txtL">1</span> <span className='txt'>Acquisition overview </span>
                </div>
                <div
                    className={
                        `block titleLeft pointer border-0
                        ${props?.currentStep == 3 ? " active" : "inactive"}
                        ${checkIfValidated(3) ? 'step-validated' : ''}`}
                    onClick={() => handleChangeStep(3)}
                >
                    <span className="txtL">2</span> <span className='txt'>What potential buyers need to know </span>
                </div>
                <div
                    className={
                        `block titleLeft pointer border-0
                        ${props?.currentStep == 4 ? " active" : "inactive"}
                        ${checkIfValidated(4) ? 'step-validated' : ''}`
                    }
                    onClick={() => handleChangeStep(4)}
                >
                    <span className="txtL">3</span> <span className='txt'>Company basics </span>
                </div>
                <div
                    className={
                        `block titleLeft pointer border-0
                        ${props?.currentStep == 5 ? " active" : "inactive"}
                        ${checkIfValidated(5) ? 'step-validated' : ''}`
                    }
                    onClick={() => handleChangeStep(5)}
                >
                    <span className="txtL">4</span> <span className='txt'>Financial details </span>
                </div>
            </div>
        </div>
        
    )
}

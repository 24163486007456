import photoAddCompany from "../../resources/images/addCompany.png";
import "./not-found.scss";

export default function NotFound(){
    return (
        <div>
            <div className="NotFoundContainer">
                <div className="container mx-auto">
                    <div className="contentBorder">
                        <h3 className="title">4 0 4</h3>
                        <div className="image">
                            <img src={photoAddCompany} alt=""/>
                        </div>
                        <div className="text-center sTitle">
                            <p>Oops! page not found</p>

                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}
import axios from "axios";

// export const getToken = () => {
//   const t =
//     sessionStorage.getItem("token") ||
//         "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE3MDU1MjcwODcsImV4cCI6MTcwNTYxMzQ4NywiaXNzIjoiZGV2aW5zaWRlcl9zZXJ2aWNlIiwicm9sZXMiOlsiUk9MRV9JTlZFU1RPUiIsIlJPTEVfVVNFUiJdLCJ1c2VybmFtZSI6Im1heGltZW1vdXN0YWZhQHlvcG1haWwuY29tIiwidXNlcl91dWlkIjoiODVhNDZmMTQtYzQ3Mi00N2E3LTgwNjYtY2MyNzc0OTU4NTY4IiwiZmlyc3RuYW1lIjoiTWF4aW1lIiwibGFzdG5hbWUiOiJNb3VzdGFmYSJ9.R-ckrcK6BB7ai5kaiy2Bl9Z9_rdoxVyAvJuratxqKZxvM_NWv1JBYejXSBx9R4tTZMLMYJ3eiY6MEZQwEHXBzbjStTaseU8BqbPO2MnG4ySzCW4LBSaJOvusfO-5pD7jqP5tUpOGR0n2S4d7YtDLNXCVD2Sm7bJLPBwJH7iGXQ4UOhYe4Qv--Apj00EaQYHvKAmVQgAMeu83ZAAne6Jh1orblggWtVliYEhTxJM7W-5l0ixLscacchvgXtvvpkFlG0KG4eWGsm8-ZoP9pFfOIkTQOL8Ks2hsq2R9NS8C8nUfr6DFa6C1-Pu3Vu5o_tHOFtY0zb_MRLuepQVAODlwkUJmyFs7CwSUDcTdzkVqZu2GmHPVnTTZVlTnyn6lf1C6JMKe2_fZ---3sz2X1vHFWfyCljZL9Fz2jkyPWKMr3lEGHw-RVxpooLQdTErcuGVvU_y4IA4P6rZVA23A6Z8tUizei-fxTW5-o_6zjSWkkRvPSHvZ2ZfsakFClXRCXh_NwdUGnrdBcllleYahpH1XUFWBoos7CHT8spmNsBv3MfiMdU3_Vx_O_EhA2NNoLuuCFeqlVVBC-ofWF-EVz3GY5v1i6l4nwuL7XkZFvBsVkS3JxIqmEL-oPB0GozxWMub5OhRZY4DvcKfLVyt5k47CQtkOBZxpPjbXl264Nn7rBpU";
//   return t;
// };

// export const getToken = () => {
//   return sessionStorage.getItem("token") ?? '';
//   if (typeof window !== 'undefined') {
//     const t = sessionStorage.getItem("token") || "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE3MDU4NDI1ODcsImV4cCI6MTcwNTkyODk4NywiaXNzIjoiZGV2aW5zaWRlcl9zZXJ2aWNlIiwicm9sZXMiOlsiUk9MRV9FWFBFUlRfQ09OVFJJQlVUT1IiLCJST0xFX1VTRVIiXSwidXNlcm5hbWUiOiJha291bmFtYXRhdGFAeW9wbWFpbC5jb20iLCJ1c2VyX3V1aWQiOiIwYWRlMGY3Yy05NzUzLTQ2ODMtYTZkYi1kNzNhMzJkYWY5OTgiLCJmaXJzdG5hbWUiOiJBa291bmEiLCJsYXN0bmFtZSI6Ik1hdGF0YSJ9.S0CHeJMdtP4waoZmgCSxZXItChU65u0Q2bj79P0keXOIhzKE59gG4Ei5QwlEIUQu_tCCQn4k2ZndQmnEwUR0_adl4OtnCD1XC-xn6llgJz1rXKN5wydxlf35-T31G6bmjDR7VSUCBDjUI944dANo785kD6TviEVTQR1zttmyKveH_TOi2Z-TqqiuwjsxwTFgWJqyG8Cz-rN6vQhMlgB8zGeARg5EadSnRWQcbIEpF2jY94d6kGlKPeomlO-8nqArHvdC7fd1ST9hSsARQ3yaggM0AJPvQ7JKyaY3C2-Wuk4nTi2iO_1mnmjLjkSLQrOR-FBpiTjmq6h3me4smqo-kLiOMNGKNY9RxaPB-XcF69VgWTK-_jPAlEMdzrPnpB0so-B71zM4RIu6HOfyOTIBEDNBUtsbUw0O-vX7p2onldQKEpzWRHbiQZQN8jKk5NiXMK0CnyzTDhRrnvXefjbZek-Rj24riUN6-oLff6byIWBES-kk4fm6fajJ23xDHWE7EDRsR7xM7uqZLrX-3KkPUMeC66_hWM4gBLmGuxXUEPWUVoTN-9eWos7hvlpkEL37HfuCdF16Sx-0qWu5x2oEffAs32hAfsio5rUtsviGiD83LAi8l2t3FMsPHlC-Mp1jlX1i8fi-ZifLXiji7XCsjP0OAtebaKET1BRJ8K77Fu0";
//     return t;
//   } else {
//     return "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE3MDU4NDI1ODcsImV4cCI6MTcwNTkyODk4NywiaXNzIjoiZGV2aW5zaWRlcl9zZXJ2aWNlIiwicm9sZXMiOlsiUk9MRV9FWFBFUlRfQ09OVFJJQlVUT1IiLCJST0xFX1VTRVIiXSwidXNlcm5hbWUiOiJha291bmFtYXRhdGFAeW9wbWFpbC5jb20iLCJ1c2VyX3V1aWQiOiIwYWRlMGY3Yy05NzUzLTQ2ODMtYTZkYi1kNzNhMzJkYWY5OTgiLCJmaXJzdG5hbWUiOiJBa291bmEiLCJsYXN0bmFtZSI6Ik1hdGF0YSJ9.S0CHeJMdtP4waoZmgCSxZXItChU65u0Q2bj79P0keXOIhzKE59gG4Ei5QwlEIUQu_tCCQn4k2ZndQmnEwUR0_adl4OtnCD1XC-xn6llgJz1rXKN5wydxlf35-T31G6bmjDR7VSUCBDjUI944dANo785kD6TviEVTQR1zttmyKveH_TOi2Z-TqqiuwjsxwTFgWJqyG8Cz-rN6vQhMlgB8zGeARg5EadSnRWQcbIEpF2jY94d6kGlKPeomlO-8nqArHvdC7fd1ST9hSsARQ3yaggM0AJPvQ7JKyaY3C2-Wuk4nTi2iO_1mnmjLjkSLQrOR-FBpiTjmq6h3me4smqo-kLiOMNGKNY9RxaPB-XcF69VgWTK-_jPAlEMdzrPnpB0so-B71zM4RIu6HOfyOTIBEDNBUtsbUw0O-vX7p2onldQKEpzWRHbiQZQN8jKk5NiXMK0CnyzTDhRrnvXefjbZek-Rj24riUN6-oLff6byIWBES-kk4fm6fajJ23xDHWE7EDRsR7xM7uqZLrX-3KkPUMeC66_hWM4gBLmGuxXUEPWUVoTN-9eWos7hvlpkEL37HfuCdF16Sx-0qWu5x2oEffAs32hAfsio5rUtsviGiD83LAi8l2t3FMsPHlC-Mp1jlX1i8fi-ZifLXiji7XCsjP0OAtebaKET1BRJ8K77Fu0";
//   }
// };

export const getToken = () => {
    const token = localStorage.getItem('accessToken');
    return token ?? '';
};

const axiosProvider = axios.create({
  // Dev #47679 V1 Agrandir la taille max de la video dans company page
  timeout: 1200000,
  baseURL: process.env.REACT_APP_API_URL,
});

const createAxiosInstance = (baseURL: any) => {
  return axios.create({
    timeout: 1200000,
    baseURL: baseURL,
  });
};

const headers = (arg = "application/json", token = "") => ({
  "Content-Type": arg,
  Authorization: `Bearer ${token}`,
  Accept: "application/json",
});

export const api = {
  createInstance: (baseURL: any) => createAxiosInstance(baseURL),
  get: <T>(url: string, params?: object, baseURL?: any) => {
    const axiosInstance = baseURL ? createAxiosInstance(baseURL) : axiosProvider;
    return axiosInstance.get<T>(url, {
      headers: headers("application/json", getToken()),
      method: "GET",
      ...params,
    });
  },
  post: <T>(url: string, data: any, baseURL?: any) => {
    const axiosInstance = baseURL ? createAxiosInstance(baseURL) : axiosProvider;
    return  axiosInstance.post<T>(url, data, {
      headers: headers("application/json", getToken()),
    });
  },
  patch: <T>(url: string, data: any, baseURL?: any) => {
    const axiosInstance = baseURL ? createAxiosInstance(baseURL) : axiosProvider;
    return axiosInstance.patch<T>(url, data, {
      headers: headers("application/json", getToken()),
    });
  },
  delete: <T>(url: string, baseURL?: any) => {
    const axiosInstance = baseURL ? createAxiosInstance(baseURL) : axiosProvider;
    return axiosInstance.delete<T>(url, {
      headers: headers("application/json", getToken()),
    });
  },
  put: <T>(url: string, data?: any, baseURL?: any) => {
    const axiosInstance = baseURL ? createAxiosInstance(baseURL) : axiosProvider;
    return axiosInstance.put<T>(url, data ? data : {}, {
      headers: headers("application/json", getToken()),
    });
  },
  getWithouttoken: <T>(url: string, params?: object, baseURL?: any) => {
    const axiosInstance = baseURL ? createAxiosInstance(baseURL) : axiosProvider;
    return axiosInstance.get<T>(url, {
      method: "GET",
      ...params,
    });
  }
};

import { useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDeletePaymentMethod } from '../../../payment';

export default function UserRemoveCard(args: any) {
  const navigate = useNavigate();
  const [removeCard, setRemoveCard] = useState<boolean>(false);

  const mutDeletePM = useMutation({
    mutationFn: useDeletePaymentMethod,
    onSuccess: (data) => {
      if (data?.status === 204) {
        args.successUpdate(false);
        
        toast('Card removed successfully');
        setRemoveCard(false);
        args.closePopRemoveCard();
        navigate('/settings');
      }
    }
  })

  const handleRemoveCard = () => {
    setRemoveCard(true);
    mutDeletePM.mutate();
  };

  return {
    removeCard,
    handleRemoveCard,
  };
}

export const getRoleDisplayName = (roles: Array<string>) => {
    if (roles.includes('ROLE_OEM_PROGRAM')) {
        return 'Partner program';
    } else if (roles.includes('ROLE_ISV')) {
        return 'ISV';
    } else if (roles.includes('ROLE_INVESTOR')) {
        return 'Investor';
    } else if (roles.includes('ROLE_GUEST')) {
        return 'Guest';
    } else if (roles.includes('ROLE_RESELLER')) {
        return 'Reseller';
    } else if (roles.includes('ROLE_MODERATOR')) {
        return 'Moderator';
    } else {
        return roles[0];
    }
};
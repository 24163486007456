import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { stripePrivateKey } from "../../../utils/outils";
import {
  CardRegistrationInput,
  CardRegistrationInputProps,
} from "./card-registration-input";

export const CardRegistrationInputWrapper: React.FC<
  CardRegistrationInputProps
> = (props) => {
  const [stripePromise, setStripePromise] = useState<any | null>(null);

  useEffect(() => {
      if (stripePrivateKey) {
          const promise = loadStripe(
              stripePrivateKey,
              { locale: 'en' }
          );
          setStripePromise(promise);
      }
  }, [stripePrivateKey]);

  return (
    <Elements stripe={stripePromise}>
      <CardRegistrationInput {...props} />
    </Elements>
  );
};

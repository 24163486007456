import { AxiosError, AxiosResponse } from 'axios';
import {
  UseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { GetInfinitePagesInterface } from '../types/response';
import { api } from './api';

type QueryKeyT = [string, object | undefined];

export const fetcher = async <T>({
  queryKey,
  pageParam,
  baseURL,
}: {
  queryKey: QueryKeyT;
  pageParam?: number;
  baseURL?: any;
}): Promise<T> => {
  const [url, params] = queryKey;

  const formattedParams = params
    ? Object.fromEntries(
        Object.entries(params).map(([key, value]) => [
          key,
          Array.isArray(value) ? JSON.stringify(value) : typeof value === 'number' ? value : `"${value}"`
        ])
      )
    : {};
  
  const res = await api.get<T>(url, { params: { ...formattedParams, pageParam } }, baseURL);
  return res.data;
};

export const fetcherWithoutToken = async <T>({
  queryKey,
  pageParam,
  baseURL,
}: {
  queryKey: QueryKeyT;
  pageParam?: number;
  baseURL?: any;
}): Promise<T> => {
  const [url, params] = queryKey;

  const formattedParams = params
    ? Object.fromEntries(
        Object.entries(params).map(([key, value]) => [
          key,
          Array.isArray(value) ? JSON.stringify(value) : typeof value === 'number' ? value : `"${value}"`
        ])
      )
    : {};
  
  const res = await api.getWithouttoken<T>(url, { params: { ...formattedParams, pageParam } }, baseURL);
  return res.data;
};

export const useLoadMore = <T>(url: string | null, token: string, params?: object, baseURL?: any) => {
  const context = useInfiniteQuery<
    GetInfinitePagesInterface<T>,
    Error,
    GetInfinitePagesInterface<T>,
    QueryKeyT
  >(
    [url!, params],
    ({ queryKey, pageParam = 1 }) => fetcher({ queryKey, pageParam, baseURL }),
    {
      getPreviousPageParam: (firstPage) => firstPage.previousId ?? false,
      getNextPageParam: (lastPage) => {
        return lastPage.nextId ?? false;
      },
    }
  );

  return context;
};

export const usePrefetch = <T>(url: string | null, token: string, params?: object, baseURL?: any) => {
  const queryClient = useQueryClient();

  return () => {
    if (!url) {
      return;
    }

    queryClient.prefetchQuery<T, Error, T, QueryKeyT>(
      [url!, params],
      ({ queryKey }) => fetcher({ queryKey, baseURL })
    );
  };
};

export const useFetch = <T>(
  url: string | null,
  params?: object,
  config?: UseQueryOptions<T, Error, T, QueryKeyT>,
  baseURL?: any
) => {
  const context = useQuery<T, Error, T, QueryKeyT>(
    [url!, params],
    ({ queryKey }) => fetcher({ queryKey, baseURL }),
    {
      enabled: !!url,
      ...config,
    }
  );

  return context;
};

export const useFetchWithoutToken = <T>(
  url: string | null,
  params?: object,
  config?: UseQueryOptions<T, Error, T, QueryKeyT>,
  baseURL?: any
) => {
  const context = useQuery<T, Error, T, QueryKeyT>(
    [url!, params],
    ({ queryKey }) => fetcherWithoutToken({ queryKey, baseURL }),
    {
      enabled: !!url,
      ...config,
    }
  );

  return context;
};

const useGenericMutation = <T, S>(
  func: (data: T | S) => Promise<AxiosResponse<S>>,
  url: string,
  params?: object,
  updater?: ((oldData: T, newData: S) => T) | undefined
) => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse, AxiosError, T | S>(func, {
    onMutate: async (data) => {
      await queryClient.cancelQueries([url!, params]);

      const previousData = queryClient.getQueryData([url!, params]);

      queryClient.setQueryData<T>([url!, params], (oldData) => {
        return updater ? updater(oldData!, data as S) : (data as T);
      });

      return previousData;
    },
    onError: (err, _, context) => {
      queryClient.setQueryData([url!, params], context);
    },
    onSettled: () => {
      queryClient.invalidateQueries([url!, params]);
    },
  });
};

export const useDelete = <T>(
  url: string,
  params?: object,
  updater?: (oldData: T, id: string | number) => T,
  baseURL?: any
) => {
  return useGenericMutation<T, string | number>(
    (id) => api.delete(`${url}/${id}`, baseURL),
    url,
    params,
    updater
  );
};

export const usePost = <T, S>(
  url: string,
  params?: object,
  updater?: (oldData: T, newData: S) => T, 
  baseURL?: any
) => {
  return useGenericMutation<T, S>(
    (data) => api.post<S>(url, data, baseURL),
    url,
    params,
    updater
  );
};

export const useUpdate = <T, S>(
  url: string,
  token: string,
  params?: object,
  updater?: (oldData: T, newData: S) => T,
  baseURL?: any
) => {
  return useGenericMutation<T, S>(
    (data) => api.patch<S>(url, data, baseURL),
    url,
    params,
    updater
  );
};

import Modal from 'react-bootstrap/Modal';
import Img from "../../../resources/images/info-seller.svg";
import "./info-creation.scss";

export type IProps = {
    isOpen: boolean;
    closeModal: () => void;
    confirmCancel : () => void;
    title: string;
};

export default function CancelCreationModal(props: IProps) {
    return (
        <Modal show={props?.isOpen} className='createCompanypopup forStep5custom modalDevinsider'>
            <Modal.Header>
                <div className='headerpopupCompany noStep'>
                    <h2 style={{fontSize:"1rem", color:"#656565"}} className='title'>{props?.title}</h2>
                    <button   onClick={() => props?.closeModal()} style={{  padding: "1rem",
                    margin:"-1rem -1rem -1rem auto"}} className="close border-0"><span aria-hidden="true">x</span>
                    </button>
                </div>
            </Modal.Header>
            <Modal.Body>
                <img
                src={Img}
                alt=""
                className="mx-auto d-block"
                width={120}

                />
                <p style={{color: "#444", fontSize:"1.05rem", fontWeight: 700}} className="text-center mt-4"> Are you sure you want to leave this page?</p>



                <p className="title text-center">You will lose your progress</p>
                <p className="title text-center">Press OK to continue, or Cancel to stay on <br/> the current page.</p>
            </Modal.Body>
            <Modal.Footer>
                <div className='contentBorder formForEmail'>
                    <button
                    className='btn btn-devinsider save text-white'
                    onClick={() => props?.confirmCancel()}
                    >
                        OK
                    </button>
                    <button
                    style={{marginLeft:"1rem"}}
                    onClick={() => props?.closeModal()}
                    className='btn btn-devinsider px-5 cancel'
                    >
                        Cancel
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

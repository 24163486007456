import { useEffect, useState } from "react";
import { ISubscription } from "../../../../../types/subscription";

type IProps = {
    handleNextStep: (step: number, isGoingNextStep?: boolean) => void;
    showCancelModal: () => void;
    setData: (data: ISubscription) => void;
    data: ISubscription;
    currentStep: number;
};

export default function Subscription(props: IProps) {
    const [formState, setFormState] = useState(props?.data);
    const [errorSubscription, setErrorSubscription] = useState(false);
    
    const subscriptions = [
        {
            planType: "opportunistic",
            title: "OPPORTUNISTIC BUYER",
            content: (
                <div>
                    - Basic annual subscription of $199 <br />
                    - Devinsider will recommend seller opportunities <br />
                    - Passive buyer profile is listed on the buyers result matchmaking (but not publicaly)
                </div>
            ),
        },
        {
            planType: "motivated",
            title: "MOTIVATED BUYER",
            content: (
                <div>
                    - Premium annual subscription of $999 <br />
                    - Access to matchmaking where sellers can reach out to you as a buyer <br />
                    - Proactively reach out to sellers through the matchmaking results: &quot;proactive seller&quot; and &quot;passive seller profile&quot; <br />
                    - Devinsider will recommend seller opportunities (first 7 days after a seller lists themselves it is only visible to motivated buyers before it is opened up to the passive buyer)
                </div>
            ),
        },
    ];



    const updateState = (property: string, value: string) => {
        setFormState((prevState: ISubscription) => ({
            ...prevState,
            [property]: value,
        }));
    }

    const goToNextStep = (step: number) => {
        if (formState?.planType !== "") {
            props?.setData(formState);
            props?.handleNextStep(step, true);
        } else {
            setErrorSubscription(true);
            return;
        }

    }

    useEffect(() => {
        setErrorSubscription(false);
    }, [errorSubscription]);

    return (
        <div className='contentUser'>
            <div className="userType chooseSubscription">
                {
                    subscriptions?.map((el: any, index: any) => (
                        <div className={`item${formState?.planType == el?.planType ? " active" : ""}`} key={index}>
                            <div className='title'>{el?.title?.toUpperCase()}</div>
                            <span className='txt'>
                                {el?.content}
                            </span>
                            <div className='select'>
                                <input
                                    id={`select-${el?.planType}`}
                                    type="radio"
                                    value={el?.planType}
                                    name="planType"
                                    checked={formState?.planType === el?.planType}
                                    onChange={(evt) => updateState(evt.target.name, evt.target.value)}
                                />
                                <label htmlFor={`select-${el?.planType}`}>
                                    Select
                                </label>
                            </div>
                        </div>
                    ))
                }
            </div>
            <div className='text-align-right stepsButtons'>
                <button className='btn btn-devinsider cancel' onClick={() => props?.showCancelModal()}>
                    Cancel
                </button>
                <button className={`btn btn-devinsider save ${formState?.planType === "" ? "disabled-ma-btn" : ""}`} disabled={errorSubscription || formState?.planType === ""} onClick={() => goToNextStep(props?.currentStep+1)}>
                    Continue
                </button>
            </div>
        </div>
    );
}
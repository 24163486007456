import { useEffect, useState } from "react";
import { Accordion, Modal } from "react-bootstrap";
import { useMutation } from "react-query";
import { UseUpdateSeller, useGetListingById } from "../../../../api/seller";
import { ReactComponent as Loader } from '../../../../resources/images/Rolling.svg';

export default function GoalEdit(props: any) {
    const [enabled, setEnabled] = useState(false);

    const { data, refetch } = useGetListingById(props?.idSeller, enabled);

    const mutUpdate = useMutation({mutationFn:UseUpdateSeller,
        retry: 0,
        onSuccess: (data) => {
            props.setIsLoading(false);
            props.handleOk();
            props.setData(formState);
        },
        onSettled: (data, error, variables, context) => {
            setIsAccordionOpen(false);
            refetch();
            setActiveId('');
        }
    });

    useEffect(() => {
        if (props?.idSeller) setEnabled(true);
        if (data) setEnabled(false);
    }, [data, props?.idSeller]);

    const [activeId, setActiveId] = useState<string>("");

    const getInitialState = () => {
        return {
            ...props?.data,
            reason: props?.value?.reason_listing === "manda_deleted_manda" ? "" : props?.value?.reason_listing,
            explication: props?.value?.desc_reason_listing === "manda_deleted_manda" ? "" : props?.value?.desc_reason_listing,
            acquisiton_type: props?.value?.desired_acquisition === "manda_deleted_manda" ? "" : props?.value?.desired_acquisition,
            transaction_duration: props?.value?.urgency === "manda_deleted_manda" ? "" : props?.value?.urgency,
        }
    }

    const [formState, setFormState] = useState(getInitialState());
    
    useEffect(() => {
        if(props?.show) setFormState(getInitialState());
    }, [props?.show]);

    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    function toggleActive(id: any) {
        if (activeId === id) {
            setActiveId("");
        } else {
            setActiveId(id);
        }
    }
    useEffect(() => {
        if (formState.reason !== "I am looking for an acquirer") {
          setFormState((prevState: any) => ({
            ...prevState,
            explication: "",
          }));
        }
    }, [formState.reason]);

    useEffect(() => {
        if (formState?.reason === "I am looking for an acquirer" && formState?.explication !== "") {
            props?.setErrorExpCompanyReason(false);
        }
    }, [formState.explication, formState?.reason, props]);

    const updateState = (property: string, value: any) => {
        setFormState((prevState: any) => ({
            ...prevState,
            [property]: value,
        }));
    }

    const save = () => {
        if (formState?.reason === "") {
            props?.setErrorCompanyReason(true);
        }
        if (formState?.reason === "I am looking for an acquirer" && formState?.explication === "") {
            props?.setErrorExpCompanyReason(true);
        }
        if (formState?.acquisiton_type === "") {
            props?.setErrorAcquisitionType(true);
        }
        if (formState?.transaction_duration === "") {
            props?.setErrorTransaction(true);
        }
        
        if(formState?.reason !== "" && ((formState?.reason === "I am looking for an acquirer" && formState?.explication !== "") || (formState?.reason !== "I am looking for an acquirer" && formState?.explication === "")) && formState?.acquisiton_type !== "" && formState?.transaction_duration !== "") {
            props.setIsLoading(true);
            let param = {
                idSeller: props?.idSeller,
                reason_listing: formState?.reason,
                desc_reason_listing: formState?.explication,
                desired_acquisition: formState?.acquisiton_type,
                urgency: formState?.transaction_duration,
            }

            mutUpdate.mutateAsync(param);
        }
    }

    // Enlever les erreurs lors d'un modif
    useEffect(() => {
        if(formState?.reason !== "")  props?.setErrorCompanyReason(false);
        if(formState?.reason === "I am looking for an acquirer" && formState?.explication !== "")  props?.setErrorExpCompanyReason(false);
        if(formState?.acquisiton_type !== "")  props?.setErrorAcquisitionType(false);
        if(formState?.transaction_duration !== "")  props?.setErrorTransaction(false);
    }, [formState?.reason, props, formState?.explication, formState?.acquisiton_type, formState?.transaction_duration]);

    const handleAccordionOpen = (eventKey: string | null) => {
        if (eventKey && eventKey !== isAccordionOpen.toString()) {
            setIsAccordionOpen(true);
        }
    };

    const setActiveSection = (eventKey: string | null) => {
        handleAccordionOpen(eventKey);
        toggleActive(eventKey);
    };

    const handleExit = () => {
        setIsAccordionOpen(false);
        setActiveId('');
        return  props.handleOk && props.handleOk();
    }

    return (
        <Modal
            show={props.show}
            className='createCompanypopup large forStep5custom buyer-modal'
        >
            <Modal.Header>
                <div className='headerpopupCompany noStep goal'>
                    <h2 className='title'>Goal</h2>
                    <a
                        className='close'
                        onClick={() => !props.isLoading && handleExit()}
                    >
                        <span className='icon-close-pop'></span>
                    </a>
                </div>
            </Modal.Header>

            <Modal.Body className='modal-item-edit'>

                <Accordion defaultActiveKey={activeId}>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header onClick={() => setActiveSection('0')}>
                            {
                                activeId !== "0" && (
                                    <div className='container'>
                                        <div className='row'>
                                            <div className='col-md-12 form-group mb-4 required p-0'>
                                                <label className="label" htmlFor='' style={{fontWeight: 600, marginRight:"1rem", display: "inline-block"}}>Why are you listing your business in the M&A marketplace?</label>{" "}<span className="hidable">{formState?.reason}</span>
                                                {
                                                    props?.errorCompanyReason && (
                                                        <div className="container-error pl-2  d-inline" style={{color: 'red'}}>
                                                            <span className='error-red'> ERROR </span>{" "}
                                                            <span className='txt-error'>This field is required</span>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        {
                                            formState?.reason === "I am looking for an acquirer" && (
                                                <div className='row'>
                                                    <div className='col-md-12 form-group required p-0 mb-3'>
                                                        <label className="label" htmlFor='' style={{fontWeight: 600, marginRight:"1rem", display: "inline-block"}}>Please elaborate why you are interested in finding an acquirer:</label>{" "}<br></br><span className="hidable">{formState?.explication}</span>
                                                        {
                                                            props?.errorExpCompanyReason && (
                                                                <div className="container-error pl-2  d-inline" style={{color: 'red'}}>
                                                                    <span className='error-red'> ERROR </span>{" "}
                                                                    <span className='txt-error'>This field is required</span>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                )
                            }
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className='form-group required p-0'>
                                <label className="label" htmlFor='' style={{fontWeight: 600, marginRight:"1rem", display: "inline-block"}}>Why are you listing your business in the M&A marketplace?</label>
                                    {
                                        props?.errorCompanyReason && (
                                            <div className="container-error pl-2  d-inline" style={{color: 'red'}}>
                                                <span className='error-red'> ERROR </span>{" "}
                                                <span className='txt-error'>This field is required</span>
                                            </div>
                                        )
                                    }
                                <select className='form-control mt-2'
                                    name='reason'
                                    onChange={(evt) => updateState(evt.target.name, evt.target.value)}
                                >
                                <option value="">Select an option ...</option>
                                    {
                                        props?.dataReasonListing?.data?.map((el : any, index : any) => (
                                            <option selected={el?.attributes?.Label == formState?.reason} value={el?.attributes?.Label} key={index}>{el?.attributes?.Label}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            {
                                formState?.reason === "I am looking for an acquirer" && (
                                    <div className='form-group required p-0 mt-3'>
                                        <label className="label" htmlFor='' style={{fontWeight: 600, marginRight:"1rem", display: "inline-block"}}>Please elaborate why you are interested in finding an acquirer:</label>
                                        {
                                            props?.errorExpCompanyReason && (
                                                <div className="container-error pl-2  d-inline" style={{color: 'red'}}>
                                                    <span className='error-red'> ERROR </span>{" "}
                                                    <span className='txt-error'>This field is required</span>
                                                </div>
                                            )
                                        }
                                        <textarea
                                            name='explication'
                                            className='form-control mt-2'
                                            maxLength={2000}
                                            value={formState?.explication}
                                            onChange={(evt) => {
                                                updateState(evt.target.name, evt.target.value)
                                            }}
                                        />
                                        <span className='caractercount'>
                                            {0 + formState?.explication.length || 0} / 2000
                                        </span>
                                    </div>
                                )
                            }
                            
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="1">
                        <Accordion.Header onClick={() => setActiveSection('1')}>
                            <div className='form-group required p-0'>
                                <label className="label" htmlFor='' style={{fontWeight: 600, marginRight:"1rem", display: "inline-block"}}>What acquisition type are you aiming for?</label>{" "}<span className="hidable">{formState?.acquisiton_type}</span>
                                    {
                                        props?.errorAcquisitionType && (
                                            <div className="container-error pl-2  d-inline" style={{color: 'red'}}>
                                                <span className='error-red'> ERROR </span>{" "}
                                                <span className='txt-error'>This field is required</span>
                                            </div>
                                        )
                                    }
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <select className='form-control mt-2'
                                name='acquisiton_type'
                                onChange={(evt) => updateState(evt.target.name, evt.target.value)}
                            >
                                <option value="">Select an option ...</option>
                                    {
                                        props?.dataAcquisitions?.data?.map((el : any, index : any) => (
                                            <option selected={el?.attributes?.Label == formState?.acquisiton_type} value={el?.attributes?.Label} key={index}>{el?.attributes?.Label}</option>
                                        ))
                                    }
                            </select>
                        </Accordion.Body>
                    </Accordion.Item> 

                    <Accordion.Item eventKey="2">
                        <Accordion.Header onClick={() => setActiveSection('2')}>
                            <div className='form-group required p-0'>
                                <label className="label" htmlFor='' style={{fontWeight: 600, marginRight:"1rem", display: "inline-block"}}>Within what timeframe are you looking to close the transaction?</label>{" "}<span className="hidable">{formState?.transaction_duration}</span>
                                {
                                    props?.errorTransaction && (
                                        <div className="container-error pl-2  d-inline" style={{color: 'red'}}>
                                            <span className='error-red'> ERROR </span>{" "}
                                            <span className='txt-error'>This field is required</span>
                                        </div>
                                    )
                                }
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <select className='form-control mt-2'
                                name='transaction_duration'
                                onChange={(evt) => updateState(evt.target.name, evt.target.value)}
                            >
                                <option value="">Select an option ...</option>
                                    {
                                        props?.dataTimeframes?.data?.map((el : any, index : any) => (
                                            <option selected={el?.attributes?.Label == formState?.transaction_duration} value={el?.attributes?.Label} key={index}>{el?.attributes?.Label}</option>
                                        ))
                                    }
                            </select>
                        </Accordion.Body>
                    </Accordion.Item> 

                </Accordion>
                
            </Modal.Body>
            <Modal.Footer>
                <div className='text-align-right'>
                    {!isAccordionOpen && (
                        <>
                            <button
                                style={{ marginLeft: "1rem" }}
                                className='btn btn-devinsider save'
                                onClick={() => handleExit()}>
                                Close
                            </button>
                        </>
                    )}
                    {isAccordionOpen && (
                        <>
                            <button
                                style={{ marginLeft: "1rem" }}
                                className='btn btn-devinsider cancel'
                                onClick={() => !props.isLoading && handleExit()}>
                                Cancel
                            </button>
                            <button
                               className='btn btn-devinsider save'
                                onClick={() => !props.isLoading && save()}>
                            
                                {props.isLoading ? <Loader/> : "Save"}
                            </button>
                        </>
                    )}
                </div>
            </Modal.Footer>

        </Modal>
    )
}
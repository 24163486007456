import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useGetUserReceipts } from "../../../api/payment";
import { invalidateQueryReceipt } from "../../../query/removeCache";
import { IReceipt } from "../../../types/receipts";

export default function useReceipts(showModal: boolean) {
  const queryClient = useQueryClient();

  const [loaderReceipt, setLoaderReceipt] = useState<boolean>(false);

  const getReceiptsMut = useMutation({mutationFn: useGetUserReceipts});

  const [receipt, setReceipt] = useState<{
    items: Array<IReceipt>;
    meta: {
      nb_item: number;
      pages: number;
      pagination: number;
      total_count: number;
    };
  }>();

  const getReceipt = async (p?: {
    page: number,
    size: number
  }) => {
      setLoaderReceipt(true);

      const receiptsResponse = await getReceiptsMut.mutateAsync(p);

      if (receiptsResponse.data.message === 'success') {
        setReceipt({
          items: receiptsResponse.data.data.invoices,
          meta: receiptsResponse.data.data.meta
        })
      } else {
        throw receiptsResponse.data.data.detail
      }
      setLoaderReceipt(false);
  };

  const getReceiptInfo = async () => {
    setLoaderReceipt(true);
    await getReceipt({
      page: 1,
      size: 5
    })
  }

  useEffect(() => {
    if(!showModal) {
      invalidateQueryReceipt(queryClient);
    } else {
      getReceiptInfo();
    }
  }, [showModal]);

  const handleClickReceipt = async (page: number) => {
    await getReceipt({
      size: 5,
      page: page
    });
  };

  return {
    handleClickReceipt,
    loaderReceipt,
    receipt
  };
}

import Modal from 'react-bootstrap/Modal';

type IProps = {
    show: boolean;
    handleOk?: () => void;
};

const FreePublishSuccess = (props: IProps) => {
    return (
        <Modal
            show={props.show}
            className='createCompanypopup medium forStep5custom'
        >
            <Modal.Header>
                <div className='headerpopupCompany noStep'>
                <h2 className='title'>Publish listing</h2>
                <a
                    className='close'
                    onClick={() => props.handleOk && props.handleOk()}
                >
                    <span className='icon-close-pop'></span>
                </a>
                </div>
            </Modal.Header>
            <Modal.Body className=''>
                <div className='contentBorder forBillingCenter pt-3 pb-0 text-center'>
                <div className='payement_success'>
                    <div className='ico'>
                        <span className='icon-check-article green'></span>
                    </div>
                    <span className='txt mb-2 title'>Success</span>
                    <p>Your listing has been published.</p>
                </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className='contentBorder text-center'>
                <button
                    className='btn btn-devinsider containerBtnBtn pl-5 pr-5'
                    onClick={() => props.handleOk && props.handleOk()}
                >
                    Continue
                </button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default FreePublishSuccess;

import { initialMaStrategyOverview } from "../../../../../defaults/MAStrategy";
import { initialCompanyBasics } from "../../../../../defaults/company-basics";
import { initialMaContactOverview} from "../../../../../defaults/MAContact";
import { initialMainterest } from "../../../../../defaults/mainterest";
import { initialSubscriptionPlan } from "../../../../../defaults/subscription";
import { IMAContactDetails } from "../../../../../types/MAContactDetails";
import { IMAInterest } from "../../../../../types/MAInterest";
import { IMAStrategy } from "../../../../../types/MAStrategy";
import { ISubscription } from "../../../../../types/subscription";
import {
    SET_MA_STRATEGY,
    SET_COMPANY_BASICS_BUYER,
    SET_CONTACT_DETAILS,
    SET_MA_INTEREST,
    SET_SUBSCRIPTION
} from "./actions";
import { ICompanyBasicsBuyer } from "../../../../../types/company-basics-buyer";
import { initialCompanyBasicsbuyer } from "../../../../../defaults/company-basics-buyer";

interface ICreationSellerState {
    subscription : ISubscription
    ma_strategy : IMAStrategy,
    ma_interest : IMAInterest,
    company_basics_buyer : ICompanyBasicsBuyer,
    ma_contact : IMAContactDetails
}

export const initialState: ICreationSellerState = {
    subscription : initialSubscriptionPlan,
    ma_strategy : initialMaStrategyOverview,
    ma_interest : initialMainterest,
    company_basics_buyer : initialCompanyBasicsbuyer,
    ma_contact : initialMaContactOverview
};

export const creationBuyerReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SET_SUBSCRIPTION:
            return {
                ...state,
                subscription: action.payload
            };
        case SET_MA_STRATEGY:
            return {
                ...state,
                ma_strategy: action.payload,
            };
        case SET_MA_INTEREST:
            return {
                ...state,
                ma_interest: action.payload,
            };
        case SET_COMPANY_BASICS_BUYER:
            return {
                ...state,
                company_basics_buyer: action.payload,
            };
            
        case SET_CONTACT_DETAILS:
            return {
                ...state,
                ma_contact: action.payload,
            };
        default:
            return state;
    }
};


